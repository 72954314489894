import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { connectChoir } from '../../lib/utils';
import ChoirPageTitle from '../partials/ChoirPageTitle';
import ChoirPreload from '../partials/ChoirPreload';
import Choir from '../partials/Choir';

import styles from '../../styles/pages/home.scss';

const propTypes = {
    choir: AppPropTypes.choir.isRequired,
    choirLoaded: PropTypes.bool.isRequired,
    choirStarted: PropTypes.bool.isRequired,
    gifs: AppPropTypes.gifs.isRequired,
    patterns: AppPropTypes.patterns.isRequired,
    debug: PropTypes.bool.isRequired,
};

const HomePage = ({
    choir, choirLoaded, choirStarted, gifs, patterns, debug,
}) => (
    <div className={styles.container}>
        <ChoirPageTitle id="meta.title" defaultMessage="Joyeuse Chorale Adviso" name={null} />
        <ChoirPreload
            choir={choir}
            loaded={choirLoaded}
            started={choirStarted}
            patterns={patterns}
            gifs={gifs}
        />
        <div className={styles.inner}>
            <Choir {...choir} bodyGifs={gifs} debug={debug} className={styles.choir} />
        </div>
    </div>
);

HomePage.propTypes = propTypes;

const WithStateContainer = connect(({ site, choir }) => ({
    gifs: site.gifs,
    patterns: site.patterns,
    choirLoaded: choir.loaded,
    choirStarted: choir.started,
}))(HomePage);
const WithChoirContainer = connectChoir('general')(WithStateContainer);
const WithIntlContainer = injectIntl(WithChoirContainer);
export default WithIntlContainer;
