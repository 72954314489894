import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import sizeable, { refPropTypes } from '../../lib/sizeable';
import styles from '../../styles/partials/choir-lyrics.scss';

const propTypes = {
    // width: PropTypes.number,
    sizeableRef: refPropTypes.isRequired,

    // time: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    animated: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    lyrics: AppPropTypes.lyrics, // eslint-disable-line react/no-unused-prop-types
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    // width: null,
    // time: 0,
    animated: true,
    lyrics: [],
    onShow: null,
    onHide: null,
    className: null,
};

const InnerComponent = React.memo(({ text, size }) => (
    <div className={styles.textOverflow} style={{ width: `${Math.min(Math.round(size), 100)}%` }}>
        <div className={classNames([styles.text, styles.follow])}>{text}</div>
    </div>
));

class ChoirLyrics extends PureComponent {
    static getDerivedStateFromProps(props, state) {
        const timeChanged = props.time !== state.time;
        if (props.lyrics !== null && timeChanged) {
            // prettier-ignore
            const lyric = props.lyrics.find(it => (
                props.time >= it.start - 0.05 && props.time <= it.end + 0.05
            )) || null;
            const progress = lyric !== null
                ? ((props.time - lyric.start) / (lyric.end - lyric.start)).toFixed(2)
                : 0;
            return {
                time: props.time,
                text: lyric !== null ? lyric.text : null,
                size: props.animated ? Math.ceil(progress * state.width) + 1 : state.width,
                // prettier-ignore
                progress: lyric !== null
                    ? (props.time - lyric.start) / (lyric.end - lyric.start) * 100
                    : 0,
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.refText = React.createRef();

        this.state = {
            time: 0, // eslint-disable-line react/no-unused-state
            text: null,
            progress: 0,
        };
    }

    componentDidUpdate(prevProps, { text: prevText }) {
        const { onShow, onHide } = this.props;
        const { text } = this.state;
        const textChanged = prevText !== text;
        if (textChanged) {
            if (text !== null) {
                onShow();
            } else {
                onHide();
            }
        }
    }

    render() {
        const { sizeableRef, className } = this.props;
        const { text, progress } = this.state;
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
                ref={sizeableRef}
            >
                <div className={styles.inner}>
                    <div className={styles.text}>
                        <span className={styles.innerText} ref={this.refText}>
                            {text}
                            {' '}
                            <InnerComponent text={text} size={progress} />
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

ChoirLyrics.propTypes = propTypes;
ChoirLyrics.defaultProps = defaultProps;

export default sizeable(ChoirLyrics);
