import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { parse } from 'subtitle';
import { injectIntl } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { getCharactersByRows } from '../../lib/utils';
import sizeable, { refPropTypes } from '../../lib/sizeable';
import {
    setLyrics as setLyricsAction,
    setDuration as setDurationAction,
    setLoaded as setLoadedAction,
    setStarted as setStartedAction,
    setEnded as setEndedAction,
    setPlaying as setPlayingAction,
    setTalking as setTalkingAction,
    setTime as setTimeAction,
    setMuted as setMutedAction,
    setPitch as setPitchAction,
    setSoloCharacter as setSoloCharacterAction,
    setEffects as setEffectsAction,
} from '../../actions/ChoirActions';
import ChoirMusic from '../../lib/ChoirMusic';
import ChoirTitle from './ChoirTitle';
import ChoirEnding from './ChoirEnding';
import ChoirScene from './ChoirScene';
import ChoirBar from './ChoirBar';

import lyricsFrSrc from '../../audio/lyricsAdvisoFr.srt';
import lyricsEnSrc from '../../audio/lyricsAdvisoEn.srt';
import styles from '../../styles/partials/choir.scss';

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    sizeableRef: refPropTypes.isRequired,
    characters: AppPropTypes.characters,
    bodyGifs: AppPropTypes.gifs,
    soloCharacter: PropTypes.string,
    soloAuto: PropTypes.bool,
    lyrics: AppPropTypes.lyrics,
    lyricsSrc: PropTypes.shape({
        fr: PropTypes.string,
        en: PropTypes.string,
    }),
    id: PropTypes.string,
    name: PropTypes.string,
    voice: PropTypes.string,
    userName: PropTypes.string,
    loaded: PropTypes.bool,
    started: PropTypes.bool,
    ended: PropTypes.bool,
    playing: PropTypes.bool,
    talking: PropTypes.bool,
    duration: PropTypes.number,
    time: PropTypes.number,
    muted: PropTypes.bool,
    pitch: PropTypes.number,
    effects: PropTypes.arrayOf(PropTypes.string),
    minSoloAutoDelay: PropTypes.number,
    maxSoloAutoDelay: PropTypes.number,
    className: PropTypes.string,
    setLyrics: PropTypes.func.isRequired,
    setDuration: PropTypes.func.isRequired,
    setLoaded: PropTypes.func.isRequired,
    setStarted: PropTypes.func.isRequired,
    setEnded: PropTypes.func.isRequired,
    setPlaying: PropTypes.func.isRequired,
    setTalking: PropTypes.func.isRequired,
    setTime: PropTypes.func.isRequired,
    setMuted: PropTypes.func.isRequired,
    setPitch: PropTypes.func.isRequired,
    setSoloCharacter: PropTypes.func.isRequired,
    setEffects: PropTypes.func.isRequired,
    mobile: PropTypes.bool,
    debug: PropTypes.bool,
};

const defaultProps = {
    characters: [],
    bodyGifs: [],
    soloCharacter: null,
    soloAuto: true,
    lyrics: [],
    lyricsSrc: {
        fr: lyricsFrSrc,
        en: lyricsEnSrc,
    },
    id: null,
    name: null,
    voice: null,
    userName: null,
    loaded: false,
    started: false,
    ended: false,
    playing: false,
    talking: false,
    duration: 0,
    time: 0,
    muted: false,
    pitch: 0,
    effects: [],
    minSoloAutoDelay: 2000,
    maxSoloAutoDelay: 5000,
    className: null,
    mobile: false,
    debug: false,
};

class Choir extends PureComponent {
    static getDerivedStateFromProps(
        { width, height, characters },
        { screenRatio: prevScreenRatio, characters: prevCharacters },
    ) {
        // eslint-disable-next-line
        const screenRatio = Math.min(
            Math.max(width !== null && height !== null ? width / height : 1, 0.6),
            1,
        );
        const screenRatioChanged = screenRatio !== prevScreenRatio;
        const charactersChanged = characters !== prevCharacters;
        if (charactersChanged || screenRatioChanged) {
            const maxByRow = Math.ceil(Math.sqrt(characters.length) * screenRatio);
            return {
                screenRatio,
                characters,
                charactersRows: getCharactersByRows(characters, maxByRow),
                voices: characters.reduce(
                    (allVoices, it) => (it.voice !== null
                        ? {
                            ...allVoices,
                            [it.id]: it.voice,
                        }
                        : allVoices),
                    null,
                ),
            };
        }

        return null;
    }

    constructor(props) {
        super(props);

        this.onLyricsLoad = this.onLyricsLoad.bind(this);
        this.onClickStart = this.onClickStart.bind(this);
        this.onMusicReady = this.onMusicReady.bind(this);
        this.onMusicTime = this.onMusicTime.bind(this);
        this.onMusicPlay = this.onMusicPlay.bind(this);
        this.onMusicPause = this.onMusicPause.bind(this);
        this.onMusicEnd = this.onMusicEnd.bind(this);
        this.onPitchChange = this.onPitchChange.bind(this);
        this.onSoloCharacterChange = this.onSoloCharacterChange.bind(this);
        this.onSolo = this.onSolo.bind(this);
        this.onSoloAutoTimeout = this.onSoloAutoTimeout.bind(this);
        this.onEffectsChange = this.onEffectsChange.bind(this);
        this.onClickVolume = this.onClickVolume.bind(this);
        this.onSeek = this.onSeek.bind(this);
        this.onRestart = this.onRestart.bind(this);
        this.onTalkChange = this.onTalkChange.bind(this);

        this.choirMusic = null;
        this.soloTimer = null;

        this.state = {
            musicReady: false,
            screenRatio: 1, // eslint-disable-line react/no-unused-state
            characters: null, // eslint-disable-line react/no-unused-state
            charactersRows: [],
            // voices: null,
        };
    }

    componentDidMount() {
        const { lyrics, voice } = this.props;
        // const { voices } = this.state;
        // You could change this back for old style individual voices
        this.choirMusic = new ChoirMusic({
            voices: [voice],
        });
        this.choirMusic.on('ready', this.onMusicReady);
        this.choirMusic.on('time', this.onMusicTime);
        this.choirMusic.on('play', this.onMusicPlay);
        this.choirMusic.on('pause', this.onMusicPause);
        this.choirMusic.on('end', this.onMusicEnd);

        if (lyrics === null) {
            this.loadLyrics();
        }
    }

    componentDidUpdate({
        soloAuto: prevSoloAuto,
        effects: prevEffects,
        muted: prevMuted,
        pitch: prevPitch,
    }) {
        const {
            soloAuto, effects, muted, pitch,
        } = this.props;
        const soloAutoChanged = prevSoloAuto !== soloAuto;
        if (soloAutoChanged) {
            if (soloAuto) {
                this.startSoloAutoTimer();
            } else {
                this.stopSoloAutoTimer();
            }
        }
        const effectsChanged = prevEffects !== effects;
        if (effectsChanged && this.choirMusic !== null) {
            this.choirMusic.setEffects(effects !== null && effects.length > 0 ? effects : null);
        }

        const mutedChanged = prevMuted !== muted;
        if (mutedChanged && this.choirMusic !== null) {
            if (muted) {
                this.choirMusic.mute();
            } else {
                this.choirMusic.unmute();
            }
        }

        const pitchChanged = prevPitch !== pitch;
        if (pitchChanged && this.choirMusic !== null) {
            this.choirMusic.setPitch(pitch);
        }
    }

    componentWillUnmount() {
        this.stopSoloAutoTimer();
        if (this.choirMusic !== null) {
            this.choirMusic.off('time', this.onMusicTime);
            this.choirMusic.off('play', this.onMusicPlay);
            this.choirMusic.off('pause', this.onMusicPause);
            this.choirMusic.off('end', this.onMusicEnd);
            this.choirMusic.destroy();
            this.choirMusic = null;
        }
    }

    onLyricsLoad(lyrics) {
        const { setLyrics, loaded, setLoaded } = this.props;
        const { musicReady } = this.state;
        const lyricsParsed = parse(lyrics).map(({ start, end, ...lyric }) => ({
            ...lyric,
            start: start / 1000,
            end: end / 1000,
        }));
        setLyrics(lyricsParsed);
        if (!loaded && musicReady) {
            setLoaded(true);
        }
    }

    onClickStart() {
        const { started, setStarted } = this.props;
        if (!started) {
            setStarted(true);
            this.choirMusic.play();
        }
    }

    onMusicReady(duration) {
        const {
            loaded, lyrics, setDuration, setLoaded,
        } = this.props;
        this.setState({
            musicReady: true,
        });
        setDuration(duration);
        if (!loaded && lyrics !== null) {
            setLoaded(true);
        }
    }

    onMusicTime(time) {
        const { setTime, setEnded, ended } = this.props;
        if (!ended && time > 245) {
            setEnded(true);
        }
        setTime(time);
    }

    onMusicPlay() {
        const { setPlaying } = this.props;
        setPlaying(true);
    }

    onMusicPause() {
        const { setPlaying } = this.props;
        setPlaying(false);
    }

    onMusicEnd() {
        const { setPlaying, setEnded } = this.props;
        setPlaying(false);
        setEnded(true);
    }

    onRestart() {
        const { ended, setPlaying, setEnded } = this.props;
        if (ended) {
            if (this.choirMusic !== null) {
                this.choirMusic.seek(0);
                this.choirMusic.play();
            }
            setEnded(false);
            setPlaying(true);
        }
    }

    onPitchChange(value) {
        const { setPitch } = this.props;
        setPitch(value);
    }

    onSoloCharacterChange(value) {
        const { setSoloCharacter } = this.props;
        setSoloCharacter(value, false);
    }

    onSolo() {
        const { characters, setSoloCharacter } = this.props;
        const randomIndex = Math.floor(Math.random() * characters.length);
        const randomCharacter = characters[randomIndex].id;
        setSoloCharacter(randomCharacter, true);
    }

    onSoloAutoTimeout() {
        const { characters, setSoloCharacter, soloCharacter } = this.props;
        const otherCharacters = characters.filter(it => it.id !== soloCharacter);
        const randomIndex = Math.floor(Math.random() * otherCharacters.length);
        const randomCharacter = otherCharacters[randomIndex].id;
        setSoloCharacter(randomCharacter, true);
        this.startSoloAutoTimer();
    }

    onEffectsChange(value) {
        const { setEffects } = this.props;
        setEffects(value);
    }

    onClickVolume() {
        const { muted, setMuted } = this.props;
        setMuted(!muted);
    }

    onSeek(time) {
        if (this.choirMusic !== null) {
            this.choirMusic.seek(time);
        }
    }

    onTalkChange(value) {
        const { setTalking } = this.props;
        setTalking(value);
    }

    loadLyrics() {
        const { intl, lyricsSrc } = this.props;
        const src = lyricsSrc[intl.locale] ? lyricsSrc[intl.locale] : lyricsSrc.fr;
        fetch(src)
            .then(response => response.text())
            .then(this.onLyricsLoad);
    }

    startSoloAutoTimer() {
        const { minSoloAutoDelay, maxSoloAutoDelay } = this.props;
        this.stopSoloAutoTimer();
        // prettier-ignore
        const soloDelay = minSoloAutoDelay + Math.round(
            Math.random() * (maxSoloAutoDelay - minSoloAutoDelay),
        );
        this.soloTimer = setTimeout(this.onSoloAutoTimeout, soloDelay);
    }

    stopSoloAutoTimer() {
        if (this.soloTimer !== null) {
            clearTimeout(this.soloTimer);
            this.soloTimer = null;
        }
    }

    render() {
        const {
            sizeableRef,
            debug,
            characters,
            bodyGifs,
            soloCharacter,
            lyrics,
            id,
            name,
            userName,
            loaded,
            started,
            ended,
            playing,
            talking,
            duration,
            time,
            muted,
            pitch,
            effects,
            className,
            mobile,
        } = this.props;
        const { charactersRows } = this.state;
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.isStarted]: started,
                        [className]: className !== null,
                    },
                ])}
                ref={sizeableRef}
            >
                <div className={styles.inner}>
                    <div className={styles.scene}>
                        <ChoirScene
                            lyrics={lyrics}
                            started={started}
                            playing={playing}
                            talking={talking}
                            animating={id !== 'general'}
                            time={time}
                            characters={charactersRows}
                            bodyGifs={bodyGifs}
                            soloCharacter={soloCharacter}
                            onTalkChange={this.onTalkChange}
                            mobile={mobile}
                            debug={debug}
                        />
                        <ChoirTitle
                            name={name}
                            general={id === 'general'}
                            loaded={loaded}
                            className={classNames([
                                styles.title,
                                {
                                    [styles.visible]: !started,
                                },
                            ])}
                            onClickStart={this.onClickStart}
                        />
                        <ChoirEnding
                            name={userName || name}
                            general={id === 'general'}
                            loaded={loaded}
                            className={classNames([
                                styles.title,
                                {
                                    [styles.visible]: ended,
                                },
                            ])}
                            onClickRestart={this.onRestart}
                        />
                    </div>
                    <div className={styles.footer}>
                        <ChoirBar
                            id={id}
                            characters={characters}
                            soloCharacter={soloCharacter}
                            started={started}
                            duration={duration}
                            time={time}
                            muted={muted}
                            pitch={pitch}
                            effects={effects}
                            className={styles.bar}
                            onPitchChange={this.onPitchChange}
                            onSoloCharacterChange={this.onSoloCharacterChange}
                            onSolo={this.onSolo}
                            onEffectsChange={this.onEffectsChange}
                            onClickVolume={this.onClickVolume}
                            onSeek={this.onSeek}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

Choir.propTypes = propTypes;
Choir.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ choir, site }) => ({
        ...choir,
        mobile: site.mobile,
    }),
    dispatch => ({
        setLyrics: value => dispatch(setLyricsAction(value)),
        setDuration: value => dispatch(setDurationAction(value)),
        setLoaded: value => dispatch(setLoadedAction(value)),
        setStarted: value => dispatch(setStartedAction(value)),
        setEnded: value => dispatch(setEndedAction(value)),
        setPlaying: value => dispatch(setPlayingAction(value)),
        setTalking: value => dispatch(setTalkingAction(value)),
        setTime: value => dispatch(setTimeAction(value)),
        setMuted: value => dispatch(setMutedAction(value)),
        setPitch: value => dispatch(setPitchAction(value)),
        setSoloCharacter: (...args) => dispatch(setSoloCharacterAction(...args)),
        setEffects: value => dispatch(setEffectsAction(value)),
    }),
)(Choir);

const WithIntlContainer = injectIntl(WithStateContainer);

export default sizeable(WithIntlContainer);
