import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Container from '@folklore/react-container';

import reducers from '../reducers/index';
import * as AppPropTypes from '../lib/PropTypes';

import App from './App';

const propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        PropTypes.objectOf(PropTypes.string),
    ]),
    routes: PropTypes.objectOf(PropTypes.string),
    employees: AppPropTypes.employees,
    voices: AppPropTypes.voices,
    choirs: AppPropTypes.choirs,
    gifs: AppPropTypes.gifs,
    patterns: AppPropTypes.patterns,
    companies: AppPropTypes.companies,
    debug: PropTypes.bool,
    mobile: PropTypes.bool,
};

const defaultProps = {
    locale: 'fr',
    messages: {},
    routes: {},
    employees: [],
    voices: [],
    choirs: [],
    gifs: [],
    patterns: [],
    companies: {},
    debug: false,
    mobile: false,
};

class Root extends PureComponent {
    // eslint-disable-next-line class-methods-use-this
    getStoreProps() {
        const {
            debug, employees, voices, choirs, gifs, patterns, companies, mobile,
        } = this.props;
        return {
            reducers,
            initialState: {
                site: {
                    debug,
                    employees,
                    voices,
                    choirs,
                    gifs,
                    patterns,
                    companies,
                    mobile,
                },
            },
        };
    }

    getIntlProps() {
        const { locale, messages } = this.props;
        return {
            locale,
            messages,
        };
    }

    getUrlGenerator() {
        const { routes } = this.props;
        return {
            routes,
        };
    }

    render() {
        return (
            <Container
                store={this.getStoreProps()}
                intl={this.getIntlProps()}
                urlGenerator={this.getUrlGenerator()}
            >
                <App />
            </Container>
        );
    }
}

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;

export default Root;
