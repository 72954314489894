/* eslint-disable global-require */
export default [
    {
        path: require('../img/gifs/980x.gif'),
        width: 480,
        height: 270,
    },
    {
        path: require('../img/gifs/bebe.gif'),
        width: 499,
        height: 281,
    },
    {
        path: require('../img/gifs/danse.gif'),
        width: 500,
        height: 280,
    },
    {
        path: require('../img/gifs/deco.gif'),
        width: 400,
        height: 306,
    },
    {
        path: require('../img/gifs/feu.gif'),
        width: 500,
        height: 330,
    },
    {
        path: require('../img/gifs/frappe.gif'),
        width: 500,
        height: 383,
    },
    {
        path: require('../img/gifs/glissade.gif'),
        width: 400,
        height: 300,
    },
    {
        path: require('../img/gifs/lum.gif'),
        width: 467,
        height: 262,
    },
    {
        path: require('../img/gifs/lutin.gif'),
        width: 245,
        height: 190,
    },
    {
        path: require('../img/gifs/manger.gif'),
        width: 250,
        height: 150,
    },
    {
        path: require('../img/gifs/NSync.gif'),
        width: 500,
        height: 289,
    },
    {
        path: require('../img/gifs/PereNoel.gif'),
        width: 320,
        height: 240,
    },
    {
        path: require('../img/gifs/reine.gif'),
        width: 480,
        height: 276,
    },
    {
        path: require('../img/gifs/tenor-1.gif'),
        width: 220,
        height: 391,
    },
    {
        path: require('../img/gifs/tenor.gif'),
        width: 244,
        height: 274,
    },
];
