import React from 'react';
import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';
import ImagePattern from './ImagePattern';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    originalWidth: PropTypes.number,
    originalHeight: PropTypes.number,
    id: PropTypes.string,
    playing: PropTypes.bool,
    animating: PropTypes.bool,
    bodyColor: PropTypes.string,
    legsColor: PropTypes.string,
    skinColor: PropTypes.string,
    gif: AppPropTypes.gif,
    gifRatio: PropTypes.number,
    pattern: AppPropTypes.pattern,
    isMask: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    width: null,
    height: null,
    originalWidth: 236.79,
    originalHeight: 667.75,
    id: null,
    playing: false,
    animating: false,
    bodyColor: '#00A400',
    legsColor: '#000',
    skinColor: '#FCB8A3',
    gif: null,
    gifRatio: 0.25,
    pattern: null,
    isMask: false,
    className: null,
};

const Male2 = ({
    width,
    height,
    originalWidth,
    originalHeight,
    id,
    playing,
    animating,
    bodyColor,
    legsColor,
    skinColor,
    gif,
    gifRatio,
    pattern,
    isMask,
    className,
}) => {
    const hasPattern = pattern !== null && !isMask;
    const hasGif = gif !== null && !isMask;
    const midWidth = Math.floor(originalWidth / 2);
    const midHeight = Math.floor(originalHeight / 2);

    let bodyFill = isMask ? '#000' : bodyColor;
    if (hasGif) {
        bodyFill = `url(#gif-${id})`;
    } else if (hasPattern) {
        bodyFill = `url(#pattern-${id})`;
    }
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={`${
                width === null && height !== null
                    ? height * (originalWidth / originalHeight)
                    : width || originalWidth
            }px`}
            height={`${
                height === null && width !== null
                    ? width / (originalWidth / originalHeight)
                    : height || originalHeight
            }px`}
            viewBox="0 0 236.79 667.75"
            xmlSpace="preserve"
            className={className}
        >
            <defs>
                {pattern !== null ? <ImagePattern id={`pattern-${id}`} {...pattern} /> : null}
                {hasGif ? <ImagePattern id={`gif-${id}`} {...gif} scale={gifRatio} /> : null}
            </defs>
            <path
                fill={!isMask ? legsColor : '#000'}
                d="M39.78,240.54c0,0-4.11,18.3-2.94,26.01c0,0-9.99,47.11-10.59,65.78c-0.59,18.68,1.4,64.16,6,91.37c0,0-1.63,17.33-1.52,22.34c0.11,5.01-7.31,9.26-4.92,26.97c0,0-7.12,17.91-6.14,40.19c0.99,22.28-2.31,55.13-2.31,55.13s-5.15,16.5,3.97,21.31c0,0-5.28,10.59-1.58,19.61c3.7,9.02-0.22,12.75,5.82,14.44l-13.18,15.76c0,0-11.36,7.08-11.72,13.91s-4.71,13.76,13.82,13.81c18.53,0.05,29-2,31.4-7.06c2.39-5.06,2.81-9.17,2.81-9.17s10.98-1.6,14.35-8.05s2.71-13.72,2.71-13.72l-0.11-6.37c0,0,0.77-9.58,4.14-15.57c3.37-5.99,5.28-10.59-0.4-18.2c0,0,6.95-2.88,6.84-30.65c-0.1-27.77,4.3-55.63,4.71-59.74s2.85-6.89-0.2-9.1c0,0,5.8-9.69,5.08-19.69s5.14-39.72,9.71-59.85s18.19-61.4,18.19-61.4s13.57,48.41,14.19,53.86c0.62,5.45,9.3,36.28,8.58,49.01c-0.72,12.73,3.82,14.45,3.82,14.45l2.32,14.52c0,0-4.41,4.65-2.22,13.25c2.19,8.6,12.6,72.56,12.6,72.56s-2.03,21.9-1.94,25.99c0.09,4.1-2.21,13.71,0.4,18.2c2.6,4.5,6.79,13.05,6.79,13.05s1.75,11.34,0.35,15.93c-1.4,4.58,4.68,8.09,8.21,8.93c3.52,0.83,7.57,2.57,7.57,2.57s-2.28,10.2,7.79,12.64s16.64,5.03,29.13,3.85c12.49-1.18,16.31-9.92,9.59-19.33c-6.72-9.41-21.53-22.75-21.53-22.75s-2.07-3.14,1.31-8.68c3.38-5.54,5.14-16.96-2.51-23.16c0,0,4.42-4.19,4.78-10.57c0.36-6.38,0.66-15.49-0.49-22.3s-1.15-6.8-1.15-6.8s2.7-14.17,2.3-32.37c-0.4-18.2-3.05-48.19-7.63-51.28c0,0,1.39-5.04-0.72-10c0,0,1.8-9.14-0.32-14.56c0,0,6.57-89.37,6.31-101.2c-0.26-11.83-9.26-80.37-9.3-82.19s0.4-4.56-0.7-9.09s-3.22-9.95-3.22-9.95L39.78,240.54z"
            />
            <g>
                <path
                    fill={!isMask ? skinColor : '#000'}
                    d="M34.46,314.01c2.23,0.56,5.29,0,6.12-2.5c1.39,16.14,0.56,23.37,9.74,24.77c0,0,6.68-0.56,3.62-10.57c-3.06-10.02-3.06-14.19-3.06-14.19s0.83-4.45-0.28-8.63s0.56-5.57-2.23-9.74s-6.96-12.24-6.96-12.24s-19.48-1.11-24.21,7.79c0,0-1.11,14.19-2.5,16.42c-1.39,2.23-1.95,10.02-1.39,13.63c0.56,3.62,0.56,16.97,4.45,24.77s6.4,12.8,6.4,12.8s-0.83,6.96,3.62,4.17c0,0,0,6.4,3.62,4.45c3.62-1.95,3.93-3.9,2.52-11.69c0,0,2.49,7.23,5.55,4.45c3.06-2.78,0.83-8.35-1.11-13.08c-1.95-4.73-3.86-10.85-4.44-16.42S36.4,318.47,34.46,314.01z"
                />
                <path
                    fill={!isMask ? skinColor : '#000'}
                    d="M171.64,262.26c-3.9,5.01-7.23,7.79-5.29,10.85c1.95,3.06,7.79,5.29,10.3,0c2.5-5.29,4.73-9.46,6.68-10.85c0,0-5.58,17.53-8.77,20.59s-0.13,8.9,2.37,8.07c0,0,3.34,5.01,6.4,4.17c0,0,3.06,2.69,5.57,0.09c0,0,1.67-3.15,4.45-7.05c2.78-3.9,4.45-6.96,8.07-14.19c3.62-7.23,6.12-11.41,7.23-17.81c1.11-6.4-4.17-10.85-7.23-12.52s-15.86-3.34-18.92,1.39S171.64,262.26,171.64,262.26z"
                />
                <path
                    fill={bodyFill}
                    d="M232.69,119.99c-0.6-13.08-1.04-66.78-13.59-76.76c0,0,2.22-2.23-3.08-6.39c-5.3-4.16-43.77-26.3-43.77-26.3s-3.93-11.4-14.5-8.86c-14.47,0.88-59.83-0.17-59.83-0.17s-5.3-3.4-7.79,0.02c-2.49,3.43-4.99,4.83-4.99,4.83S51.79,21.76,47.34,22.61c-4.45,0.85-13.35,1.71-15.29,5.89c-1.93,4.18-17.72,29.55-23.72,69.08c-6,39.53-7.33,58.74-7.84,74.32c-0.51,15.58,1.59,63.72,3.83,68.16c2.24,4.45,5.88,11.39,5.88,11.39s-0.81,8.07,0.31,9.74c0,0-1.66,3.7,0.3,8.25c1.96,4.55,3.68,20.12,3.68,20.12s0.9,0.38,2.41,0.62c0.06-0.74,0.1-1.19,0.1-1.19c4.7-8.92,24.18-7.87,24.18-7.87s4.2,8.06,6.99,12.22c0.27,0.4,0.49,0.78,0.68,1.13c3.29,0.49,7.26,0.79,12.12,0.77c13.36,2.46,71.54,7.01,84.61,4.19c13.07-2.82,16.97-2,25.58-6.48c1.08-0.56,2.38-1.46,3.84-2.61c-1.86-1.32-3.14-5.33-0.68-7.7c3.18-3.07,8.71-20.62,8.71-20.62c-1.94,1.4-4.16,5.58-6.64,10.87c-2.49,5.29-8.34,3.09-10.3,0.03s1.37-5.85,5.25-10.87c0,0,7.75-12.55,10.8-17.29c3.05-4.74,15.85-3.11,18.92-1.45c3.07,1.66,8.37,6.1,7.27,12.5c-0.26,1.53-0.6,2.93-1.02,4.28c2.07-1.38,4.49-3.54,6.3-6.8c3.87-6.97,4.7-8.64,4.7-8.64s7.22-3.92,6.08-13.93c0,0,5.53-10.03,5.5-20.61c5.24-16.71,6.31-28.96,6.83-40.09C237.27,158.93,233.29,133.06,232.69,119.99z M194.74,175.76c-1.38,3.9-1.07,12.25-1.35,14.75c-0.27,2.51-0.5,18.09,0.06,19.76c0.56,1.67-5.25,10.59-5.25,13.37c0.01,2.78-1.25,1.95-1.3,6.4c0,0-3.01-4.44-0.03-9.74c2.98-5.3,4.08-7.53,3.51-12.25c-0.57-4.73,1.34-16.7,1.31-24.77c-0.03-8.07-3.27-20.58-3.23-28.93c0.05-8.35-0.07-21.15-0.07-21.15s3.15,4.16,3.17,10.29c0.02,6.12-2.17,17.82,0.06,19.76C193.88,165.19,196.12,171.86,194.74,175.76z"
                />
                {playing && animating ? (
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from={`0 ${midWidth} ${midHeight}`}
                        to={`3 ${midWidth} ${midHeight}`}
                        dur="0.66s"
                        repeatCount="indefinite"
                    />
                ) : null}
            </g>
        </svg>
    );
};

Male2.propTypes = propTypes;
Male2.defaultProps = defaultProps;

export default React.memo(Male2);
