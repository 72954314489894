import React from 'react';
import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';
import ImagePattern from './ImagePattern';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    originalWidth: PropTypes.number,
    originalHeight: PropTypes.number,
    id: PropTypes.string,
    playing: PropTypes.bool,
    animating: PropTypes.bool,
    bodyColor: PropTypes.string,
    legsColor: PropTypes.string,
    skinColor: PropTypes.string,
    gif: AppPropTypes.gif,
    gifRatio: PropTypes.number,
    pattern: AppPropTypes.pattern,
    isMask: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    width: null,
    height: null,
    originalWidth: 310.2,
    originalHeight: 704.42,
    id: null,
    playing: false,
    animating: false,
    bodyColor: '#009FE3',
    legsColor: '#000',
    skinColor: '#CCB196',
    gif: null,
    gifRatio: 0.25,
    pattern: null,
    isMask: false,
    className: null,
};

const Male3 = ({
    width,
    height,
    originalWidth,
    originalHeight,
    id,
    playing,
    animating,
    bodyColor,
    legsColor,
    skinColor,
    gif,
    gifRatio,
    pattern,
    isMask,
    className,
}) => {
    const hasPattern = pattern !== null && !isMask;
    const hasGif = gif !== null && !isMask;
    const midWidth = Math.floor(originalWidth / 2);
    const midHeight = Math.floor(originalHeight / 2);

    let bodyFill = isMask ? '#000' : bodyColor;
    if (hasGif) {
        bodyFill = `url(#gif-${id})`;
    } else if (hasPattern) {
        bodyFill = `url(#pattern-${id})`;
    }
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={`${
                width === null && height !== null
                    ? height * (originalWidth / originalHeight)
                    : width || originalWidth
            }px`}
            height={`${
                height === null && width !== null
                    ? width / (originalWidth / originalHeight)
                    : height || originalHeight
            }px`}
            viewBox="0 0 310.2 704.42"
            xmlSpace="preserve"
            className={className}
        >
            <defs>
                {pattern !== null ? <ImagePattern id={`pattern-${id}`} {...pattern} /> : null}
                {hasGif ? <ImagePattern id={`gif-${id}`} {...gif} scale={gifRatio} /> : null}
            </defs>
            <path
                fill={!isMask ? legsColor : '#000'}
                d="M42.65,280.85l150.22-15.13c0,0,10.38,11.87,9.05,22.64s11.61,9.9,8.83,29.56c-2.78,19.66-6.59,34.37-4.72,41.78c1.87,7.41-6.15,20.52-6.45,26.19c-0.3,5.67,2.41,15.54,0.61,29.49c-1.8,13.94-4.2,29.18-4.2,29.18s6.02,28.49-1.53,48.48c0,0-2.46,34.72-3.48,39.81c-1.03,5.09-1.67,15.82-1.98,21.49s2.35,35.02-10.39,49.07c0,0,8.21,10.13-4.74,21.05c0,0,2.97,13.62,2.79,21.17s-1.37,10.14,1.82,16.84c3.19,6.7,19.61,16.9,20.72,23.11s-0.1,18.85-18.58,18.84c-18.48-0.01-31.59-1.65-34.09-18.44l-10.56-4.32c0,0-3.19-6.7-2.2-12.42c0.98-5.72,4.9-8.5,4.9-8.5l-1.27-8.71c0,0-3.31-8.57-5.14-15.36c-1.83-6.79,0.82-8.22-0.54-18.18s-2.72-9.87,2.23-17.74c4.95-7.87,4.95-7.87,4.95-7.87s-6.76-8.97-6.79-29.7c-0.03-20.73,3.74-46.11,5.49-50.63c1.75-4.52-6.21-10.89-2.58-18.05c0,0-7.15-4.55-0.29-14.43c0,0-10.89-9.32-4.11-20.46c0,0-7.06-13.35-0.03-20.73l-1.08-26.31c0,0-10.04-6.86-9.73-22.59l-19.65,74.2c0,0,7.52,20.23,5.69,23.49c-1.83,3.26-10.26,0.15-7.3,3.36c2.95,3.21-3.35,11.18-7.32,13.33c0,0,5.23,6.56,0.63,9.38c0,0-2.4,15.24-0.7,20.15c1.7,4.91-8.13,42.01-8.13,42.01s-7.33,3.01-5.37,11.67s-1.55,17.69-1.42,19.57c0.13,1.88,1.99,9.29,1.99,9.29s4.21,1.6,3.27,7.95c-0.94,6.35-7.81,16.23-14.28,21.69l-6.13,0.41c0,0-6.39,6.71-6.96,18.69s-19.69,22.68-29.02,26.45c-9.33,3.77-27.21,2.46-24.9-14.03s8.54-25.7,14.25-32.37s18.24-23.84,18.24-23.84s-1.01-15.01,4.31-17.25c0,0-6.88-20.9-4.22-42.44c2.66-21.54,1.47-29,1.56-37.8c0.09-8.8,0.48-13.23,0.48-13.23s3.2-3.36,3.54-8.41c0.34-5.05-9.4-7.53-3.78-15.45c5.63-7.92,5.63-7.92,5.63-7.92s-12.63-14.86-8.66-27.06s3.68-16.58,3.68-16.58s-3.08-86.49-0.3-96.1c2.78-9.61,3.98-22.26,4.49-24.8s2.35-5.81,2.82-8.98C42.72,302.2,42.65,280.85,42.65,280.85z"
            />
            <g>
                <path
                    fill={!isMask ? skinColor : '#000'}
                    d="M17,290.47c0,0,12.33-1.54,15.93,0s11.05,5.65,11.82,7.45c0,0-9.51,17.22-10.02,26.73s2.06,25.95-0.51,32.89c-2.57,6.94-4.11,12.33-3.34,16.19c0.77,3.85,3.34,8.99,2.57,13.36c-0.77,4.37-0.77,4.37-0.77,4.37s6.94-0.26,7.97,1.54c1.03,1.8-1.54,4.11-3.34,4.63c0,0-1.03,6.94-7.71,6.68c-6.68-0.26-15.42-4.11-17.22-5.14s-4.37-1.28-6.42-8.74s-2.31-11.05-4.11-12.59s-1.28-7.2,1.28-14.13c2.57-6.94,6.94-28.01,8.48-30.32c1.54-2.31,1.54-2.31,1.54-2.31s-0.26-11.56,2.06-14.39c0,0-1.28-3.6,0.77-6.68L17,290.47z"
                />
                <path
                    fill={!isMask ? skinColor : '#000'}
                    d="M203.3,357.8c0,0-0.26-12.08-3.08-18.5s-7.97-23.9-8.22-26.47s-0.77-8.22,2.06-10.28s10.54-2.57,16.7-6.42c6.17-3.85,17.73-13.6,24.16-33.9c0,0,5.57,4.45,8.63,7.12c3.45,3.01,8.78,6.6,15.79,8.94c0,0-8.49,11.92-9.52,16.03c-1.03,4.11-6.68,14.39-10.54,15.29c0,0-5.14,9.89-7.97,11.18c0,0-6.68,10.02-8.99,12.33c-2.31,2.31-11.31,15.16-13.36,16.45C206.9,350.86,205.87,355.74,203.3,357.8z"
                />
                <path
                    fill={bodyFill}
                    d="M51.69,288.16c0,0,0.51,9.51-6.94,9.76c-7.45,0.26-28.78-1.03-31.61-3.6c0,0-1.8-16.19,0-19.27s2.06-6.42,2.06-6.42s-3.34-6.17,3.34-7.2c0,0-4.11-7.45,1.54-8.22c0,0,1.03-4.11-1.54-6.68s1.8-5.65,1.8-5.65l0.51-6.68c0,0-3.63-4.88,1.91-8.48c0,0-0.88-5.14,1.17-5.65c0,0-3.12-4.37-1.17-8.22c1.95-3.85,5.03-5.65,5.03-5.65s-2.83-6.68,1.03-7.97c0,0-3.34-8.74,0.26-10.79c0,0-6.68-7.2,0.51-18.5c0,0-2.57-44.71-4.11-59.1c-1.54-14.39-4.88-22.36-0.77-34.69s7.31-17.22,7.31-17.22s38.69-25.44,41.26-27.24c0,0,2.83-10.79,5.91-15.68c3.08-4.88,12.33-12.33,27.75-13.62s37.52-3.6,41.12,3.85c0,0,6.42,2.31,8.22,9.51c0,0,7.71,2.06,8.48,5.14c0,0,12.59,1.8,21.59,8.48c0,0,31.61,11.05,35.98,13.62c0,0,12.33,7.71,16.19,13.88c3.85,6.17,13.62,20.56,17.47,26.98c3.85,6.42,35.46,57,41.37,65.89c5.91,8.89,18.16,26.37,10.28,50C306.09,203.36,293.5,227,293.5,227s5.65,7.2-2.57,7.71c0,0,5.91,10.28-5.91,9.25l-4.63,9.76c0,0,2.06,1.54,2.06,3.85c0,2.31-11.31,21.84-13.88,22.61s-11.56-2.31-17.47-5.65c-5.91-3.34-18.76-13.88-19.79-16.19c-1.03-2.31-1.28-3.6,0.77-8.74s4.63-7.71,5.4-14.13c0.77-6.42,2.06-9.51,4.88-8.48c2.83,1.03,0.77-5.14,4.11-5.4s-6.17-6.94,0.51-9.25c6.68-2.31-3.85-4.63,4.37-7.2c8.22-2.57-3.6-5.65,3.6-8.74c7.2-3.08-5.91,0,0.26-9.76c0,0-2.57-3.6,2.83-6.68c0,0-5.91,1.28-3.85-5.14c0,0-6.94,6.68-6.94-7.97c0,0-8.48-1.8-13.88-10.79c0,0-5.35-2.83-7.17-8.48c0,0-3.62,24.41-7.73,29.55c0,0,5.65,24.16-1.28,42.66c0,0-0.51,25.7-3.34,32.12c-2.83,6.42-6.17,23.38-5.14,30.84s3.08,17.22,3.08,17.22s-20.34,12.85-50.51,18.5s-66.52,11.74-88-4.37c-5.14-3.85-18.76-13.11-19.53-22.1C52.97,283.02,51.69,288.16,51.69,288.16z"
                />
                {playing && animating ? (
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from={`0 ${midWidth} ${midHeight}`}
                        to={`1 ${midWidth} ${midHeight}`}
                        dur="0.66s"
                        repeatCount="indefinite"
                    />
                ) : null}
            </g>
        </svg>
    );
};

Male3.propTypes = propTypes;
Male3.defaultProps = defaultProps;

export default React.memo(Male3);
