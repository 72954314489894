/* eslint-disable global-require */
export default [
    {
        path: require('../img/patterns/small/Pattern-01.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-02.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-03.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-04.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-05.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-06.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-07.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-08.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-09.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-10.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-11.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-12.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-13.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-14.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-15.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-16.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-17.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-18.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-19.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-20.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-21.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-22.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-23.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-24.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-25.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-26.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-27.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-28.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-29.png'),
        width: 400,
        height: 400,
    },
    {
        path: require('../img/patterns/small/Pattern-30.png'),
        width: 400,
        height: 400,
    },
];
