import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';

import styles from '../../styles/partials/choir-ending.scss';

const messages = defineMessages({
    title: {
        id: 'choir.ending.title',
        defaultMessage: 'Joyeuses fêtes',
    },
    titleGeneral: {
        id: 'choir.ending.title_general',
        defaultMessage: 'de la part d’Adviso',
    },
    button: {
        id: 'choir.ending.start_button',
        defaultMessage: 'Recommencer',
    },
    loading: {
        id: 'choir.loading',
        defaultMessage: 'Chargement...',
    },
});

const propTypes = {
    name: PropTypes.string,
    general: PropTypes.bool,
    loaded: PropTypes.bool,
    onClickRestart: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    name: null,
    general: false,
    loaded: false,
    onClickRestart: null,
    className: null,
};

const ChoirEnding = ({
    name, general, loaded, onClickRestart, className,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <div className={styles.middle}>
            <h2 className={styles.title}>
                <FormattedMessage {...(general ? messages.titleGeneral : messages.title)} />
            </h2>
            <h1 className={styles.name}>
                {general ? <FormattedMessage {...messages.title} /> : name}
            </h1>
        </div>
        <div className={styles.buttons}>
            <button
                type="button"
                className={styles.button}
                disabled={!loaded}
                onClick={onClickRestart}
            >
                <FormattedMessage {...(!loaded ? messages.loading : messages.button)} />
            </button>
        </div>
    </div>
);

ChoirEnding.propTypes = propTypes;
ChoirEnding.defaultProps = defaultProps;

export default React.memo(ChoirEnding);
