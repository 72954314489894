import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Headphones = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="40px"
        height="35px"
        viewBox="0 0 40 35"
        className={className}
        xmlSpace="preserve"
    >
        <path
            fill={color}
            d="M39.47,22.22c0.13-2.7-0.05-7.48-2.4-11.23c-0.46-0.73-0.92-1.41-1.39-2.05c0.08-0.21,0.12-0.44,0.12-0.68c0-0.43-0.13-0.82-0.36-1.15c0,0-5.02-7.11-15.44-7.11S4.72,6.8,4.52,7.09C4.11,7.67,4.05,8.39,4.28,9c-0.45,0.62-0.9,1.28-1.35,1.99c-2.36,3.75-2.54,8.53-2.4,11.23C0.2,22.56,0,23.02,0,23.53v4.65c0,1.06,0.9,1.93,2,1.93c0.07,0,0.14,0,0.22-0.01C2.67,32.39,4.08,35,8.34,35c1.66,0,2.34-1.36,2.34-2.3V18.46c0-0.94-0.68-2.3-2.34-2.3c-2.86,0-4.44,1.18-5.3,2.65c0.19-2.14,0.72-4.58,1.98-6.59c0.41-0.65,0.82-1.26,1.24-1.83c0.03,0,0.05,0,0.08,0c0.71,0,1.4-0.33,1.82-0.94c0.01-0.01,0.93-1.27,2.77-2.53c2.6-1.78,5.65-2.68,9.07-2.68c3.35,0,6.35,0.87,8.92,2.58c1.95,1.3,2.96,2.61,2.96,2.61c0.39,0.55,1.04,0.91,1.78,0.91c0.02,0,0.04,0,0.06,0c0.43,0.58,0.85,1.2,1.27,1.88c1.27,2.01,1.79,4.46,1.98,6.59c-0.86-1.47-2.44-2.65-5.3-2.65c-1.66,0-2.34,1.36-2.34,2.3V32.7c0,0.94,0.68,2.3,2.34,2.3c4.26,0,5.67-2.61,6.12-4.91c0.07,0.01,0.14,0.01,0.22,0.01c1.1,0,2-0.86,2-1.93v-4.65C40,23.02,39.8,22.56,39.47,22.22z"
        />
    </svg>
);

Headphones.propTypes = propTypes;
Headphones.defaultProps = defaultProps;

export default Headphones;
