import React from 'react';
import PropTypes from 'prop-types';

import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import image from '../../img/facebook.png';

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
    name: PropTypes.string,
    meta: PropTypes.shape({
        description: PropTypes.string,
        image: PropTypes.string,
    }),
};

const defaultProps = {
    id: 'meta.title_choir',
    defaultMessage: 'Chorale {choir}',
    name: null,
    meta: {
        description: 'La joyeuse chorale Adviso',
        image,
    },
};

const ChoirPageTitle = ({
    id, defaultMessage, name, meta, intl,
}) => (
    <Helmet
        meta={[
            { name: 'description', content: meta.description },
            {
                property: 'og:title',
                content: intl.formatMessage(
                    {
                        id,
                        defaultMessage,
                    },
                    {
                        choir: name,
                    },
                ),
            },
            { property: 'og:type', content: 'website' },
            { property: 'og:description', content: meta.description },
            { property: 'og:url', content: window.location.href },
            { property: 'og:image', content: image },
        ]}
        link={[{ rel: 'canonical', href: window.location.href }]}
    >
        <title>
            {intl.formatMessage(
                {
                    id,
                    defaultMessage,
                },
                {
                    choir: name,
                },
            )}
        </title>
    </Helmet>
);

ChoirPageTitle.propTypes = propTypes;
ChoirPageTitle.defaultProps = defaultProps;

const WithIntlContainer = injectIntl(ChoirPageTitle);

export default React.memo(WithIntlContainer);
