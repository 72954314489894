import PropTypes from 'prop-types';

/**
 * Core
 */
export const urlGenerator = PropTypes.shape({
    route: PropTypes.func.isRequired,
});

export const intl = PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
});

export const message = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
});

export const label = PropTypes.oneOfType([message, PropTypes.node]);

export const option = PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
});
export const options = PropTypes.arrayOf(option);

/**
 * Site
 */
export const gender = PropTypes.oneOf(['male', 'female']);

export const size = PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    small: PropTypes.bool,
});

export const position = PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
});

export const gif = PropTypes.shape({
    path: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
});
export const gifs = PropTypes.arrayOf(gif);

export const pattern = PropTypes.shape({
    path: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    scale: PropTypes.number,
});
export const patterns = PropTypes.arrayOf(pattern);

export const companies = PropTypes.object.isRequired;

export const head = PropTypes.shape({
    original: PropTypes.string.isRequired,
    small: PropTypes.string.isRequired,
    medium: PropTypes.string.isRequired,
});

export const characterHead = PropTypes.shape({
    large: PropTypes.string.isRequired,
    small: PropTypes.string.isRequired,
    mask: PropTypes.string.isRequired,
});

export const employee = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    gender: gender.isRequired,
    head: head.isRequired,
    neckCenter: position,
});
export const employees = PropTypes.arrayOf(employee);

export const voice = PropTypes.shape({
    gender: gender.isRequired,
    voice: PropTypes.string.isRequired,
});
export const voices = PropTypes.arrayOf(voice);

export const character = PropTypes.shape({
    id: PropTypes.string.isRequired,
    head: characterHead.isRequired,
    gender: gender.isRequired,
    neckCenter: position.isRequired,
});
export const characterSpacer = PropTypes.shape({
    id: PropTypes.oneOf(['spacer']).isRequired,
});
export const characters = PropTypes.arrayOf(character);
export const charactersRows = PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([character, characterSpacer])),
);

export const choir = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    employees: PropTypes.arrayOf(PropTypes.string),
    characters,
});
export const choirs = PropTypes.arrayOf(choir);

export const lyric = PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
});
export const lyrics = PropTypes.arrayOf(lyric);
