import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addResizeListener, removeResizeListener } from '@folklore/size';

export const refPropTypes = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
]);

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    forwardedRef: refPropTypes,
    onSizeChange: PropTypes.func,
};

const defaultProps = {
    width: null,
    height: null,
    forwardedRef: null,
    onSizeChange: null,
};

const defaultOptions = {
    defaultWidth: null,
    defaultHeight: null,
    alwaysWatchSize: false,
};

const sizeable = (WrappedComponent, options = defaultOptions) => {
    const { defaultWidth, defaultHeight, alwaysWatchSize } = options;
    class Sizeable extends Component {
        static getDerivedStateFromProps(
            { width, height },
            { watchSize: prevWatchSize, computedWidth, computedHeight },
        ) {
            const watchSize = alwaysWatchSize || width === null || height === null;
            const sizeChanged = width !== computedWidth || height !== computedHeight;
            const watchSizeChanged = watchSize !== prevWatchSize;
            if (sizeChanged || watchSizeChanged) {
                return {
                    watchSize,
                    computedWidth: !watchSize && width !== null ? width : computedWidth,
                    computedHeight: !watchSize && height !== null ? height : computedHeight,
                };
            }
            return null;
        }

        constructor(props) {
            super(props);

            this.onResize = this.onResize.bind(this);
            this.updateSize = this.updateSize.bind(this);
            this.refSizeable = React.createRef();

            this.state = {
                watchSize: false,
                computedWidth: defaultWidth,
                computedHeight: defaultHeight,
                error: null, // eslint-disable-line react/no-unused-state
            };
        }

        componentDidMount() {
            const { watchSize } = this.state;
            if (watchSize) {
                this.watchSize();
                this.updateSize();
            }
        }

        componentDidUpdate(
            prevProps,
            {
                watchSize: prevWatchSize,
                computedWidth: prevComputedWidth,
                computedHeight: prevComputedHeight,
            },
        ) {
            const { onSizeChange } = this.props;
            const { watchSize, computedWidth, computedHeight } = this.state;
            const watchSizeChanged = prevWatchSize !== watchSize;
            if (watchSizeChanged) {
                if (watchSize) {
                    this.watchSize();
                } else {
                    this.unwatchSize();
                }
            }

            // prettier-ignore
            const sizeChanged = prevComputedWidth !== computedWidth
                || prevComputedHeight !== computedHeight;
            if (sizeChanged && onSizeChange !== null) {
                onSizeChange({
                    width: computedWidth,
                    height: computedHeight,
                });
            }
        }

        componentWillUnmount() {
            const { watchSize } = this.state;
            if (watchSize) {
                this.unwatchSize();
            }
        }

        onResize() {
            this.updateSize();
        }

        componentDidCatch(error) {
            this.setState({ error }); // eslint-disable-line react/no-unused-state
        }

        watchSize() {
            addResizeListener(this.refSizeable.current, this.onResize);
        }

        unwatchSize() {
            removeResizeListener(this.refSizeable.current, this.onResize);
        }

        updateSize() {
            const { width, height } = this.props;
            const sizeableElement = this.refSizeable.current;
            this.setState(({ computedWidth, computedHeight }) => ({
                computedWidth:
                    width === null && sizeableElement !== null
                        ? sizeableElement.offsetWidth
                        : computedWidth,
                computedHeight:
                    height === null && sizeableElement !== null
                        ? sizeableElement.offsetHeight
                        : computedHeight,
            }));
        }

        render() {
            const {
                width, height, forwardedRef, ...props
            } = this.props;
            const { computedWidth, computedHeight } = this.state;
            return (
                <WrappedComponent
                    {...props}
                    width={computedWidth}
                    height={computedHeight}
                    updateSizeable={this.updateSize}
                    sizeableRef={this.refSizeable}
                    ref={forwardedRef}
                />
            );
        }
    }

    Sizeable.propTypes = propTypes;
    Sizeable.defaultProps = defaultProps;

    const name = WrappedComponent.displayName || WrappedComponent.name;
    Sizeable.displayName = `sizeable(${name})`;

    return React.forwardRef((props, ref) => <Sizeable {...props} forwardedRef={ref} />);
};

export default sizeable;
