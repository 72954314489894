/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { isMessage } from '../../lib/utils';
import styles from '../../styles/buttons/bar.scss';

const propTypes = {
    type: PropTypes.string,
    active: PropTypes.bool,
    large: PropTypes.bool,
    href: PropTypes.string,
    children: AppPropTypes.label,
    className: PropTypes.string,
};

const defaultProps = {
    type: 'button',
    active: false,
    large: false,
    href: null,
    children: null,
    className: null,
};

const BarButton = ({
    type, active, href, large, children, className, ...props
}) => (href !== null ? (
    <a
        href={href}
        type={type}
        className={classNames([
            styles.container,
            {
                [styles.active]: active,
                [styles.large]: large,
                [className]: className !== null,
            },
        ])}
    >
        {isMessage(children) ? <FormattedMessage {...children} /> : children}
    </a>
) : (
    <button
        {...props}
        type={type}
        className={classNames([
            styles.container,
            {
                [styles.active]: active,
                [styles.large]: large,
                [className]: className !== null,
            },
        ])}
    >
        {isMessage(children) ? <FormattedMessage {...children} /> : children}
    </button>
));

BarButton.propTypes = propTypes;
BarButton.defaultProps = defaultProps;

export default React.memo(BarButton);
