import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    id: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    path: PropTypes.string.isRequired,
    scale: PropTypes.number,
};

const defaultProps = {
    scale: 1,
};

const ImagePattern = ({
    id,
    width,
    height,
    path,
    scale,
}) => (
    <pattern
        id={id}
        patternUnits="userSpaceOnUse"
        width={width * scale}
        height={height * scale}
    >
        <image
            xlinkHref={path}
            x="0"
            y="0"
            width={width * scale}
            height={height * scale}
        />
    </pattern>
);

ImagePattern.propTypes = propTypes;
ImagePattern.defaultProps = defaultProps;

export default ImagePattern;
