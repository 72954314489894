import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as AppPropTypes from '../../lib/PropTypes';
import { connectChoir } from '../../lib/utils';
import ChoirPageTitle from '../partials/ChoirPageTitle';
import ChoirPreload from '../partials/ChoirPreload';
import Choir from '../partials/Choir';

import styles from '../../styles/pages/choir.scss';

const propTypes = {
    userId: PropTypes.string,
    companies: AppPropTypes.companies,
    choirId: PropTypes.string.isRequired,
    choir: AppPropTypes.choir.isRequired,
    choirLoaded: PropTypes.bool.isRequired,
    choirStarted: PropTypes.bool.isRequired,
    gifs: AppPropTypes.gifs.isRequired,
    patterns: AppPropTypes.patterns.isRequired,
    debug: PropTypes.bool.isRequired,
};

const defaultProps = {
    userId: null,
    companies: {},
};

const ChoirPage = ({
    choirId,
    userId,
    choir,
    choirLoaded,
    choirStarted,
    gifs,
    patterns,
    companies,
    debug,
}) => (
    <div className={styles.container}>
        <ChoirPageTitle
            id="meta.title_choir"
            defaultMessage="Chorale {choir}"
            name={choir && choir.name ? choir.name : null}
        />
        <ChoirPreload
            choir={choir}
            loaded={choirLoaded}
            started={choirStarted}
            patterns={patterns}
            gifs={gifs}
        />
        <div className={styles.inner}>
            <Choir
                {...choir}
                userName={companies[choirId] && userId ? companies[choirId].people[userId] : null}
                companies={companies}
                bodyGifs={gifs}
                debug={debug}
                className={styles.choir}
            />
        </div>
    </div>
);

ChoirPage.propTypes = propTypes;
ChoirPage.defaultProps = defaultProps;

const WithStateContainer = connect(({ site, choir }) => ({
    gifs: site.gifs,
    patterns: site.patterns,
    companies: site.companies,
    choirLoaded: choir.loaded,
    choirStarted: choir.started,
}))(ChoirPage);

const WithChoirContainer = connectChoir(({ choirId }) => choirId)(WithStateContainer);
export default WithChoirContainer;
