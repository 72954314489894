import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import ChoirLyrics from './ChoirLyrics';
import ChoirCharacters from './ChoirCharacters';

import styles from '../../styles/partials/choir-scene.scss';

const propTypes = {
    lyrics: AppPropTypes.lyrics,
    started: PropTypes.bool,
    playing: PropTypes.bool,
    talking: PropTypes.bool,
    animating: PropTypes.bool,
    time: PropTypes.number,
    characters: AppPropTypes.charactersRows,
    bodyGifs: AppPropTypes.gifs,
    soloCharacter: PropTypes.string,
    onTalkChange: PropTypes.func,
    className: PropTypes.string,
    mobile: PropTypes.bool,
    debug: PropTypes.bool,
};

const defaultProps = {
    lyrics: [],
    started: false,
    playing: false,
    talking: false,
    animating: false,
    time: 0,
    characters: [],
    bodyGifs: [],
    soloCharacter: null,
    onTalkChange: null,
    className: null,
    mobile: false,
    debug: false,
};

class ChoirScene extends PureComponent {
    static getDerivedStateFromProps(props, state) {
        const soloChanged = props.soloCharacter !== state.soloCharacter;
        if (soloChanged && props.soloCharacter !== null) {
            return {
                soloCharacter: props.soloCharacter,
                soloCenter: state.charactersCenter[props.soloCharacter],
            };
        }

        return null;
    }

    constructor(props) {
        super(props);

        this.onCharactersSizeChange = this.onCharactersSizeChange.bind(this);
        this.getCharactersRef = this.getCharactersRef.bind(this);

        this.refContainer = React.createRef();
        this.charactersRef = {};

        this.onShowLyrics = this.onShowLyrics.bind(this);
        this.onHideLyrics = this.onHideLyrics.bind(this);

        this.state = {
            charactersCenter: null, // eslint-disable-line react/no-unused-state
            soloCharacter: null, // eslint-disable-line react/no-unused-state
            soloCenter: null,
        };
    }

    componentDidMount() {
        this.updateCharactersCenter();
    }

    componentDidUpdate() {}

    onCharactersSizeChange() {
        this.updateCharactersCenter();
    }

    onShowLyrics() {
        const { onTalkChange } = this.props;
        onTalkChange(true);
    }

    onHideLyrics() {
        const { onTalkChange } = this.props;
        onTalkChange(false);
    }

    getCharactersRef(ref, character) {
        this.charactersRef = {
            ...this.charactersRef,
            [character.id]: ref,
        };
    }

    updateCharactersCenter() {
        const sceneWidth = this.refContainer.current.offsetWidth;
        const sceneHeight = this.refContainer.current.offsetHeight;
        const charactersCenter = Object.keys(this.charactersRef).reduce((centers, id) => {
            const {
                x = 0, y = 0, width = 0, height = 0,
            } = (this.charactersRef[id] || null) !== null
                ? this.charactersRef[id].getBoundingClientRect()
                : {};
            const headWidth = width;
            const headHeight = height * 0.3;
            return {
                ...centers,
                [id]: {
                    // prettier-ignore
                    x: (x + headWidth / 2) / sceneWidth,
                    // prettier-ignore
                    y: (y + headHeight / 2) / sceneHeight,
                },
            };
        }, {});
        this.setState({
            charactersCenter, // eslint-disable-line react/no-unused-state
        });
    }

    render() {
        const {
            lyrics,
            characters,
            bodyGifs,
            soloCharacter,
            started,
            playing,
            talking,
            animating,
            time,
            className,
            mobile,
            debug,
        } = this.props;
        const { soloCenter } = this.state;
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.debug]: debug,
                        [className]: className !== null,
                    },
                ])}
                ref={this.refContainer}
            >
                <div
                    className={classNames([
                        styles.view,
                        styles.group,
                        {
                            [styles.visible]: soloCharacter === null,
                        },
                    ])}
                >
                    <div className={styles.background}>
                        <div className={styles.cityscape} />
                    </div>
                    <div className={styles.characters}>
                        <ChoirCharacters
                            charactersRef={this.getCharactersRef}
                            started={started}
                            playing={playing}
                            talking={talking}
                            animating={animating}
                            characters={characters}
                            debug={debug}
                        />
                    </div>
                    <div className={styles.floor} />
                </div>
                <div
                    className={classNames([
                        styles.view,
                        styles.solo,
                        {
                            [styles.visible]: soloCharacter !== null,
                        },
                    ])}
                    style={{
                        transform:
                            soloCenter !== null
                                ? `translate(${(0.5 - soloCenter.x) * 100}%, ${(0.5
                                      - soloCenter.y)
                                      * 100}%)`
                                : null,
                        transformOrigin: soloCenter !== null ? '50% 50%' : null,
                    }}
                >
                    <div
                        className={classNames([styles.transform])}
                        style={{
                            transform: soloCenter !== null ? 'scale(5)' : null,
                            transformOrigin:
                                soloCenter !== null
                                    ? `${soloCenter.x * 100}% ${soloCenter.y * 100}%`
                                    : null,
                        }}
                    >
                        <div className={styles.background}>
                            <div className={styles.cityscape} />
                        </div>
                        <div className={styles.characters}>
                            <ChoirCharacters
                                started={started}
                                playing={soloCharacter !== null && playing}
                                talking={talking}
                                animating={animating}
                                characters={characters}
                                bodyGifs={bodyGifs}
                                soloCharacter={soloCharacter}
                                debug={debug}
                                onSizeChange={this.onCharactersSizeChange}
                            />
                        </div>
                        <div className={styles.floor} />
                        {debug && soloCenter !== null ? (
                            <div
                                className={styles.soloCenter}
                                style={{
                                    top: `${soloCenter.y * 100}%`,
                                    left: `${soloCenter.x * 100}%`,
                                }}
                            />
                        ) : null}
                    </div>
                </div>
                <div className={styles.text}>
                    <ChoirLyrics
                        animated={!mobile}
                        lyrics={lyrics}
                        time={time.toFixed(1) || 0}
                        onShow={this.onShowLyrics}
                        onHide={this.onHideLyrics}
                    />
                </div>
            </div>
        );
    }
}

ChoirScene.propTypes = propTypes;
ChoirScene.defaultProps = defaultProps;

export default ChoirScene;
