import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'rc-slider';
import { defineMessages, injectIntl } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/pitch-slider.scss';

const messages = defineMessages({
    soprano: {
        id: 'choir_bar.pitch_soprano',
        defaultMessage: 'Soprano',
    },
    baryton: {
        id: 'choir_bar.pitch_baryton',
        defaultMessage: 'Baryton',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    value: PropTypes.number,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    value: 0,
    className: null,
    onChange: null,
};

const PitchSlider = ({
    intl, value, className, onChange,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <Slider
            className={styles.slider}
            prefixCls={styles.slider}
            value={value}
            min={-1}
            max={1}
            step={0.01}
            marks={{
                [-1]: {
                    style: { marginLeft: null, width: null, left: 0 },
                    label: intl.formatMessage(messages.baryton),
                },
                1: {
                    style: {
                        marginLeft: null,
                        width: null,
                        left: null,
                        right: 0,
                    },
                    label: intl.formatMessage(messages.soprano),
                },
            }}
            onChange={onChange}
        />
    </div>
);

PitchSlider.propTypes = propTypes;
PitchSlider.defaultProps = defaultProps;

export default React.memo(injectIntl(PitchSlider));
