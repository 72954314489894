/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import VolumeIcon from '../icons/Volume';
import styles from '../../styles/buttons/volume.scss';

const propTypes = {
    muted: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    muted: false,
    className: null,
};

const VolumeButton = ({
    muted, className, ...props
}) => (
    <button
        {...props}
        type="button"
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <VolumeIcon muted={muted} className={styles.icon} />
    </button>
);

VolumeButton.propTypes = propTypes;
VolumeButton.defaultProps = defaultProps;

export default React.memo(VolumeButton);
