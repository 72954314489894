import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import Color from 'color';
import { connect } from 'react-redux';

export const getLocaleFromLocation = (
    locales = ['fr', 'en'],
    currentLocation = window.location,
) => {
    const { pathname = '/' } = currentLocation;
    const localeRegEx = new RegExp(`^/(${locales.join('|')})(/.*)?$`, 'i');
    const matches = pathname.match(localeRegEx);
    return matches !== null ? matches[1] : locales[0];
};

export const createEffectOnChange = (effect, effects, onChange) => () => {
    if (onChange === null) {
        return;
    }
    if (effects.indexOf(effect) !== -1) {
        onChange(effects.filter(it => it !== effect));
    } else {
        onChange([...effects, effect]);
    }
};

export const isMessage = message => isObject(message) && typeof message.id !== 'undefined';

export const getCharactersByRows = (items, maxByRow = 5, minRows = 2) => {
    const itemsCount = items.length;
    // prettier-ignore
    const finalMaxByRow = itemsCount <= maxByRow * minRows && Math.ceil(itemsCount / minRows) > 1
        ? Math.ceil(itemsCount / minRows)
        : maxByRow;
    const allRows = items.reduce((rows, it) => {
        const rowsCount = rows.length;
        let row = rowsCount > 0 ? rows[rowsCount - 1] : null;
        if (row === null || row.length === finalMaxByRow - ((rowsCount + 1) % 2)) {
            row = [];
            rows.push(row);
        }
        row.push(it);
        return rows;
    }, []);
    const allRowsCount = allRows.length;
    if (allRowsCount >= 2) {
        const lastRow = allRows[allRowsCount - 1];
        const rowBefore = allRows[allRowsCount - 2];
        const lastRowCount = lastRow.length;
        const rowBeforeCount = rowBefore.length;
        if (rowBeforeCount === lastRowCount || (rowBeforeCount - lastRowCount) % 2 === 0) {
            allRows[allRowsCount - 1].push({
                id: 'spacer',
            });
        }
    }
    return allRows;
};

export const getRandomColor = () => Color.rgb(
    Math.round(Math.random() * 255),
    Math.round(Math.random() * 255),
    Math.round(Math.random() * 255),
);

export const getCharactersFromEmployees = (employees, patterns = [], isSmallScreen = false) => {
    const employeesCount = employees.length;
    const patternsCount = patterns.length;
    const patternIndexStart = Math.round(Math.random() * employeesCount);
    return employees.map(
        (
            {
                id,
                name,
                gender,
                head,
                voice = null,
                neckCenter = { x: 0.5, y: 0.5 },
                mouthCenter = { x: 0.5, y: 0.5 },
                bodyColor = null,
            },
            index,
        ) => ({
            id,
            name,
            gender,
            head: {
                small: head.small,
                large: isSmallScreen ? head.medium : head.original,
                mask: head.mask,
            },
            voice,
            neckCenter,
            mouthCenter,
            bodyIndex: Math.ceil(Math.random() * employeesCount),
            bodyColor:
                bodyColor
                || getRandomColor()
                    .rgb()
                    .string(),
            bodyPattern:
                patternsCount > 0
                    ? {
                        ...patterns[(index + patternIndexStart) % patternsCount],
                        scale: 0.1 + Math.random() * 0.5,
                    }
                    : null,
        }),
    );
};

export const getVoiceForGender = (voices, gender) => {
    const items = voices.filter(v => v.gender === gender);
    return items[Math.floor(Math.random() * items.length)].voice;
};

export const connectChoir = (getChoirId = () => null) => connect(
    ({ site, layout }, ownProps) => {
        const choirId = isFunction(getChoirId) ? getChoirId(ownProps) : getChoirId;
        return {
            debug: site.debug,
            choir: site.choirs.find(({ id }) => id === choirId),
            employees: site.employees,
            patterns: site.patterns,
            screen: layout.screen,
        };
    },
    null,
    ({
        screen, employees, patterns, choir, ...stateProps
    }, dispatchProps, ownProps) => {
        const { employees: choirEmployees, ...choirRest } = choir;
        return {
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
            choir: {
                ...choirRest,
                characters: getCharactersFromEmployees(
                    choirEmployees.map(id => employees.find(it => it.id === id)),
                    patterns,
                    screen.small,
                ),
            },
        };
    },
);

export const getRandomArray = (arr, n) => {
    const result = new Array(n);
    let len = arr.length;
    const taken = new Array(len);
    if (n > len) return arr;
    // eslint-disable-next-line
    while (n--) {
        const x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len; // eslint-disable-line
    }
    return result;
};

export const isWebAudioCompatible = () => {
    try {
        // Fix up for prefixing
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        const context = new AudioContext(); // eslint-disable-line
        return true;
    } catch (e) {
        return false;
    }
};
