import EventEmitter from 'wolfy87-eventemitter';
import { Howl, Howler } from 'howler';
import Tuna from 'tunajs';
// import PitchShifter from './PitchShifter';
import Jungle from './Jungle';
import { getRandomArray, isWebAudioCompatible } from './utils';
import musicSrc from '../audio/music_low.mp3';

class ChoirMusic extends EventEmitter {
    constructor(opts) {
        super();

        this.options = {
            src: musicSrc,
            voices: null,
            effects: null,
            pitch: 0,
            ...opts,
        };

        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.timeLoop = this.timeLoop.bind(this);
        this.onMusicLoad = this.onMusicLoad.bind(this);
        this.onMusicPlay = this.onMusicPlay.bind(this);
        this.onMusicPause = this.onMusicPause.bind(this);
        this.onMusicEnd = this.onMusicEnd.bind(this);

        this.ready = false;
        this.musicLoaded = false;
        this.playing = false;
        this.currentTime = 0;
        this.context = null;
        this.tuna = null;
        this.music = null;
        this.voices = null;
        this.timeLoopId = null;
        this.effects = null;
        this.pitchShifter = null;
        this.masterGain = null;
        this.musicGain = null;
        this.voicesGain = null;
        this.effectsGain = null;
        this.pitchGain = null;
        this.compatible = isWebAudioCompatible();
        this.maxChannels = 7;

        this.init();
    }

    init() {
        const { voices, effects, pitch } = this.options;
        this.initMusic();
        this.initVoices(voices);
        if (this.compatible) {
            this.initContext();
            this.initChannels();
            this.initTuna();
            this.initEffects(effects);
            this.initPitch(pitch);
        }
    }

    destroy() {
        this.ready = false;
        this.musicLoaded = false;
        if (this.compatible) {
            this.stopTimeLoop();
            this.destroyEffects();
            this.destroyPitch();
            this.destroyTuna();
            this.destroyContext();
        }
        this.destroyMusic();
        this.destroyVoices();
    }

    play() {
        if (!this.ready) {
            this.off('ready', this.play);
            this.once('ready', this.play);
            return;
        }

        if (this.playing) {
            return;
        }
        if (this.music !== null) {
            this.music.play();
        }
        if (this.voices !== null) {
            Object.keys(this.voices).forEach((id) => {
                if (this.voices[id].state() === 'unloaded') {
                    this.voices[id].load();
                }
                this.voices[id].play();
            });
        }
    }

    pause() {
        if (!this.ready) {
            this.off('ready', this.play);
            return;
        }
        if (!this.playing) {
            return;
        }
        if (this.music !== null) {
            this.music.pause();
        }
        if (this.voices !== null) {
            Object.keys(this.voices).forEach((id) => {
                this.voices[id].pause();
            });
        }
    }

    seek(time) {
        if (!this.ready) {
            return;
        }
        if (!this.playing) {
            return;
        }
        if (this.music !== null) {
            this.music.seek(time);
        }
        if (this.voices !== null) {
            Object.keys(this.voices).forEach((id) => {
                this.voices[id].seek(time);
            });
        }
    }

    // eslint-disable-next-line class-methods-use-this
    mute() {
        Howler.mute(true);
    }

    // eslint-disable-next-line class-methods-use-this
    unmute() {
        Howler.mute(false);
    }

    setVoices(voices) {
        if (this.voices !== null) {
            this.destroyVoices();
        }

        if (voices !== null) {
            this.initVoices(voices);
        }
    }

    setEffects(effects) {
        if (this.effects !== null) {
            this.destroyEffects();
        }

        if (effects !== null) {
            this.initEffects(effects);
        }
    }

    setPitch(pitch) {
        if (pitch === 0) {
            this.destroyEffects();
            return;
        }

        if (this.pitchShifter === null) {
            this.initPitch(pitch);
        } else {
            this.pitchShifter.setPitch(pitch);
        }
    }

    onMusicLoad() {
        this.musicLoaded = true;
        this.emit('music_loaded');
        this.checkReady();
    }

    onMusicPlay() {
        this.playing = true;
        this.startTimeLoop();
        this.emit('play');
    }

    onMusicPause() {
        this.playing = false;
        this.stopTimeLoop();
        this.emit('pause');
    }

    onMusicEnd() {
        this.playing = false;
        this.stopTimeLoop();
        this.emit('end');
    }

    onVoiceLoad(voice) {
        if (this.playing) {
            voice.mute(true);
            if (this.compatible) {
                /* eslint-disable no-underscore-dangle */
                voice._sounds.forEach((sound) => {
                    sound._node.disconnect();
                    sound._node.connect(this.voicesGain);
                });
                /* eslint-enable no-underscore-dangle */
            }
            const currentTime = this.music.seek();
            voice.seek(currentTime);
            voice.mute(false);
        }
    }

    checkReady() {
        if (this.ready || !this.musicLoaded) {
            return;
        }
        this.ready = true;
        this.emit('ready', this.music.duration());
    }

    startTimeLoop() {
        this.timeLoop();
    }

    stopTimeLoop() {
        if (this.timeLoopId === null) {
            return;
        }
        cancelAnimationFrame(this.timeLoopId);
        this.timeLoopId = null;
    }

    timeLoop() {
        const lastCurrentTime = this.currentTime;
        if (this.music !== null) {
            this.currentTime = this.music.seek();
        } else {
            this.currentTime = 0;
        }
        if (lastCurrentTime !== this.currentTime) {
            this.emit('time', this.currentTime);
        }
        this.timeLoopId = requestAnimationFrame(this.timeLoop);
    }

    initMusic() {
        const { src } = this.options;
        this.music = new Howl({
            src: [src],
            autoplay: false,
            volume: 0.5,
        });
        this.music.on('load', this.onMusicLoad);
        this.music.on('play', this.onMusicPlay);
        this.music.on('pause', this.onMusicPause);
        this.music.on('end', this.onMusicEnd);
    }

    initContext() {
        Howler.mute(true);
        Howler.mute(false);
        this.context = Howler.ctx;
        this.masterGain = Howler.masterGain;

        this.musicGain = typeof this.context.createGain === 'undefined'
            ? this.context.createGainNode()
            : this.context.createGain();
        this.voicesGain = typeof this.context.createGain === 'undefined'
            ? this.context.createGainNode()
            : this.context.createGain();
        this.effectsGain = typeof this.context.createGain === 'undefined'
            ? this.context.createGainNode()
            : this.context.createGain();
        this.pitchGain = typeof this.context.createGain === 'undefined'
            ? this.context.createGainNode()
            : this.context.createGain();

        this.musicGain.connect(this.effectsGain);
        this.effectsGain.connect(this.masterGain);

        this.voicesGain.connect(this.pitchGain);
        this.pitchGain.connect(this.masterGain);
    }

    initChannels() {
        /* eslint-disable no-underscore-dangle */
        if (this.music !== null) {
            this.music._sounds.forEach((sound) => {
                sound._node.disconnect(this.masterGain);
                sound._node.connect(this.musicGain);
            });
        }
        // if (this.voices !== null) {
        // Object.keys(this.voices).forEach((id) => {
        //     this.voices[id]._sounds.forEach((sound) => {
        //         sound._node.disconnect();
        //         sound._node.connect(this.voicesGain);
        //     });
        // });
        // }
        /* eslint-enable no-underscore-dangle */
    }

    // eslint-disable-next-line
    initTuna() {
        this.tuna = new Tuna(this.context);
    }

    initVoices(voices) {
        if (voices === null) {
            this.voices = null;
            return;
        }
        // prettier-ignore
        const voicesWithChannelLimit = Object.keys(voices).length >= this.maxChannels
            ? getRandomArray(Object.keys(voices), this.maxChannels - 1) : Object.keys(voices);

        this.voices = voicesWithChannelLimit.reduce(
            (sounds, voiceId) => ({
                ...sounds,
                [voiceId]: this.initVoice(voices[voiceId]),
            }),
            {},
        );
    }

    // eslint-disable-next-line class-methods-use-this
    initVoice(voice, id) {
        const howl = new Howl({
            src: [voice],
            autoplay: false,
            preload: false,
        });
        howl.on('load', () => this.onVoiceLoad(howl, id));
        return howl;
    }

    initPitch(pitch) {
        if (!this.compatible) return;
        if (pitch === 0) {
            this.destroyPitch();
            return;
        }
        this.pitchShifter = new Jungle(this.context);
        this.pitchShifter.setPitch(pitch);
        this.pitchGain.disconnect();

        this.pitchGain.connect(this.pitchShifter.input);
        this.pitchShifter.connect(this.masterGain);
    }

    initEffects(effects) {
        let currentFx = effects;
        if (effects === null || effects.length === 0) {
            currentFx = [];
        }
        this.effects = currentFx.reduce(
            (allEffects, effect) => [
                ...allEffects,
                {
                    name: effect,
                    node: this.initEffect(effect),
                },
            ],
            [],
        );

        this.effectsGain.disconnect();
        const effectsNode = this.effects.reduce((lastNode, { node: effectNode }) => {
            lastNode.connect(effectNode);
            return effectNode;
        }, this.effectsGain);
        effectsNode.connect(this.masterGain);
    }

    // eslint-disable-next-line class-methods-use-this
    initEffect(name) {
        if (!this.compatible) return null;

        switch (name) {
        case 'echo':
            return new this.tuna.Delay({
                feedback: 0.77,
                delayTime: 600,
                wetLevel: 0.6,
                dryLevel: 0.9,
                cutoff: 2000,
                bypass: 0,
            });
        case 'chorus':
            return new this.tuna.Chorus({
                rate: 1.5,
                feedback: 0.2,
                delay: 0.0045,
                bypass: 0,
            });
        case 'tremolo':
            return new this.tuna.Tremolo({
                intensity: 0.3,
                rate: 7,
                stereoPhase: 0,
                bypass: 0,
            });
        default:
            break;
        }
        return null;
    }

    destroyMusic() {
        if (this.music === null) {
            return;
        }
        this.music.off('play', this.onMusicPlay);
        this.music.off('pause', this.onMusicPause);
        this.music.off('end', this.onMusicEnd);
        this.music.unload();
        this.music = null;
    }

    destroyTuna() {
        if (this.tuna === null) {
            return;
        }
        this.tuna = null;
    }

    destroyContext() {
        if (this.musicGain !== null) {
            this.musicGain.disconnect();
            this.musicGain = null;
        }
        if (this.voicesGain !== null) {
            this.voicesGain.disconnect();
            this.voicesGain = null;
        }
        if (this.effectsGain !== null) {
            this.effectsGain.disconnect();
            this.effectsGain = null;
        }
        if (this.pitchGain !== null) {
            this.pitchGain.disconnect();
            this.pitchGain = null;
        }
        if (this.context !== null) {
            this.context = null;
        }
    }

    destroyVoices() {
        if (this.voices === null) {
            return;
        }
        Object.keys(this.voices).forEach((id) => {
            this.voices[id].off('load');
            this.voices[id].unload();
        });

        this.voices = null;
    }

    destroyEffects() {
        if (this.effects === null) {
            return;
        }
        this.effects.forEach(({ node: effectNode }) => {
            effectNode.disconnect();
        });
        this.effectsGain.disconnect();
        this.effectsGain.connect(this.pitchGain);
        this.effects = null;
    }

    destroyPitch() {
        if (this.pitchShifter === null) {
            return;
        }
        this.pitchShifter.disconnect();
        this.pitchGain.disconnect();
        this.pitchGain.connect(this.masterGain);
        this.pitchShifter = null;
    }
}

export default ChoirMusic;
