import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';

// import * as AppPropTypes from '../../lib/PropTypes';
import HeadphonesIcon from '../icons/Headphones';

import styles from '../../styles/partials/choir-title.scss';

const messages = defineMessages({
    title: {
        id: 'choir.title',
        defaultMessage: 'La joyeuse chorale d’Adviso pour',
    },
    titleGeneral: {
        id: 'choir.title_general',
        defaultMessage: 'La joyeuse chorale',
    },
    button: {
        id: 'choir.start_button',
        defaultMessage: 'Démarrer',
    },
    loading: {
        id: 'choir.loading',
        defaultMessage: 'Chargement...',
    },
    volume: {
        id: 'choir.volume_notice',
        defaultMessage: 'Montez votre son pour apprécier le concert',
    },
});

const propTypes = {
    name: PropTypes.string,
    general: PropTypes.bool,
    loaded: PropTypes.bool,
    className: PropTypes.string,
    onClickStart: PropTypes.func,
};

const defaultProps = {
    name: null,
    general: false,
    loaded: false,
    className: null,
    onClickStart: null,
};

const ChoirTitle = ({
    name, general, loaded, className, onClickStart,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <div className={styles.middle}>
            <h2 className={styles.title}>
                <FormattedMessage {...(general ? messages.titleGeneral : messages.title)} />
            </h2>
            <h1 className={styles.name}>{name}</h1>
            <div className={styles.buttons}>
                <button type="button" className={styles.button} disabled={!loaded} onClick={onClickStart}>
                    <FormattedMessage {...!loaded ? messages.loading : messages.button} />
                </button>
            </div>
            <div className={styles.volume}>
                <HeadphonesIcon className={styles.icon} />
                <div className={styles.label}>
                    <FormattedMessage {...messages.volume} />
                </div>
            </div>
        </div>
    </div>
);

ChoirTitle.propTypes = propTypes;
ChoirTitle.defaultProps = defaultProps;

export default React.memo(ChoirTitle);
