import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    muted: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    muted: false,
    color: 'currentColor',
    className: null,
};

const Volume = ({ muted, color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="20.5px"
        height="20px"
        viewBox="0 0 20.5 20"
        xmlSpace="preserve"
        className={className}
    >
        <g>
            <polygon fill={color} points="5.71,7.14 0,7.14 0,12.86 5.71,12.86 12.86,20 12.86,0" />
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                opacity={muted ? 0 : 1}
                d="M15,7.86c1.18,0,2.14,0.96,2.14,2.14s-0.96,2.14-2.14,2.14"
            />
            <path
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                opacity={muted ? 0 : 1}
                d="M15,5c2.76,0,5,2.24,5,5s-2.24,5-5,5"
            />
        </g>
    </svg>
);

Volume.propTypes = propTypes;
Volume.defaultProps = defaultProps;

export default Volume;
