import Female1 from './Female1';
// import Female2 from './Female2';
// import Female3 from './Female3';
import Male1 from './Male1';
import Male2 from './Male2';
import Male3 from './Male3';

export const female = [
    {
        neckCenter: { x: 0.55, y: 0.01 },
        component: Female1,
    },
    // {
    //     neckCenter: { x: 0.5, y: 0.02 },
    //     component: Female2,
    // },
    // {
    //     neckCenter: { x: 0.5, y: 0 },
    //     component: Female3,
    // },
];

export const male = [
    {
        neckCenter: { x: 0.55, y: 0.02 },
        component: Male1,
    },
    {
        neckCenter: { x: 0.5, y: 0.02 },
        component: Male2,
    },
    {
        neckCenter: { x: 0.35, y: 0.02 },
        component: Male3,
    },
];
