/* eslint-disable global-require */
import MobileDetect from 'mobile-detect';
import { getLocaleFromLocation, getVoiceForGender } from '../lib/utils';
import * as messages from './messages';
import employees from './employees';
import companies from './companies';
import voices from './voices';
import gifs from './gifs';
import patterns from './patterns';
import clientsChoirs from './choirs';

const employeesWithVoices = employees.map((employee) => {
    const voice = !employee.voice ? { voice: getVoiceForGender(voices, employee.gender) } : {};
    return { ...employee, ...voice };
});

const choirs = [
    ...clientsChoirs,
    {
        id: 'general',
        name: 'Adviso',
        employees: employeesWithVoices.map(it => it.id),
        voice: require('../audio/full/general.mp3'),
    },
];
const locales = ['fr', 'en'];
const localeParam = `:locale(${locales.join('|')})`;
const choirParam = `:choir(${choirs.map(({ id }) => id).join('|')})`;

const md = new MobileDetect(window.navigator.userAgent);
const mobile = !!md.mobile() || !!md.phone() || !!md.tablet();

export default {
    debug: false && process.env.NODE_ENV === 'development',
    locale: getLocaleFromLocation(locales),
    mobile,
    messages,
    routes: {
        'home.redirect': '/',
        home: `/${localeParam}`,
        choir: `/${localeParam}/${choirParam}`,
    },
    employees: employeesWithVoices,
    companies,
    choirs,
    gifs,
    patterns,
    voices,
};
