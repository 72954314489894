import React from 'react';

import ChoirPageTitle from '../partials/ChoirPageTitle';

import styles from '../../styles/pages/not-found.scss';

const NodeFoundPage = () => (
    <div className={styles.container}>
        <ChoirPageTitle id="meta.title" defaultMessage="404 - Joyeuse Chorale Adviso" name={null} />
        <div className={styles.inner}>
            <div className={styles.scene}>
                <h1>
                    <a href="/">Joyeux 404</a>
                </h1>
                <a className={styles.under} href="/">
                    Retourner à la chorale
                </a>
            </div>
        </div>
    </div>
);

export default NodeFoundPage;
