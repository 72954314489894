import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { createEffectOnChange } from '../../lib/utils';
import Logo from './Logo';
import CharactersSelect from './CharactersSelect';
import PitchSlider from './PitchSlider';
import BarButton from '../buttons/Bar';
import VolumeButton from '../buttons/Volume';

import styles from '../../styles/partials/choir-bar.scss';

const messages = defineMessages({
    effectEcho: {
        id: 'choir_bar.effect_echo_button',
        defaultMessage: 'Écho',
    },
    effectTremolo: {
        id: 'choir_bar.effect_tremolo_button',
        defaultMessage: 'Tremolo',
    },
    effectChorus: {
        id: 'choir_bar.effect_chorus_button',
        defaultMessage: 'Chorus',
    },
    solo: {
        id: 'choir_bar.solo_button',
        defaultMessage: 'Solo',
    },
    language: {
        id: 'choir_bar.language_button',
        defaultMessage: 'Français',
    },
});

const propTypes = {
    id: PropTypes.string,
    intl: AppPropTypes.intl.isRequired,
    characters: AppPropTypes.characters,
    soloCharacter: PropTypes.string,
    started: PropTypes.bool,
    duration: PropTypes.number,
    time: PropTypes.number,
    muted: PropTypes.bool,
    pitch: PropTypes.number,
    effects: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    onSoloCharacterChange: PropTypes.func,
    onPitchChange: PropTypes.func,
    onEffectsChange: PropTypes.func,
    onSolo: PropTypes.func,
    onClickVolume: PropTypes.func,
    onSeek: PropTypes.func,
    withoutEcho: PropTypes.bool,
    withoutChorus: PropTypes.bool,
};

const defaultProps = {
    id: null,
    characters: [],
    soloCharacter: null,
    started: false,
    duration: 0,
    time: 0,
    muted: false,
    pitch: 0,
    effects: [],
    className: null,
    onSoloCharacterChange: null,
    onPitchChange: null,
    onEffectsChange: null,
    onSolo: null,
    onClickVolume: null,
    onSeek: null,
    withoutEcho: true,
    withoutChorus: true,
};

const ChoirBar = ({
    id,
    intl,
    characters,
    soloCharacter,
    started,
    duration,
    time,
    pitch,
    effects,
    muted,
    className,
    onSoloCharacterChange,
    onPitchChange,
    onEffectsChange,
    onSolo,
    onClickVolume,
    onSeek,
    withoutEcho,
    withoutChorus,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <button
            type="button"
            className={classNames([
                styles.timeline,
                {
                    [styles.visible]: started,
                },
            ])}
            onClick={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const progress = x / rect.width;
                if (onSeek !== null) {
                    onSeek(progress * duration);
                }
            }}
        >
            <span className={styles.progress} style={{ width: `${(time / duration) * 100}%` }} />
        </button>
        <div className={styles.bar}>
            <div className={styles.inner}>
                <div className={styles.logo}>
                    <Logo className={styles.icon} />
                </div>
                <div className={styles.characters}>
                    <CharactersSelect
                        options={characters.map(it => ({
                            label: it.name,
                            value: it.id,
                        }))}
                        value={soloCharacter}
                        className={styles.select}
                        onChange={onSoloCharacterChange}
                    />
                    <BarButton
                        active={soloCharacter !== null}
                        large
                        className={styles.button}
                        onClick={() => {
                            if (soloCharacter !== null && onSoloCharacterChange !== null) {
                                onSoloCharacterChange(null);
                            } else if (soloCharacter === null && onSolo !== null) {
                                onSolo();
                            }
                        }}
                    >
                        {messages.solo}
                    </BarButton>
                </div>
                <div className={styles.pitch}>
                    <PitchSlider value={pitch} className={styles.slider} onChange={onPitchChange} />
                </div>
                <div className={styles.effects}>
                    {!withoutEcho ? (
                        <BarButton
                            active={effects.indexOf('echo') !== -1}
                            className={styles.button}
                            onClick={createEffectOnChange('echo', effects, onEffectsChange)}
                        >
                            {messages.effectEcho}
                        </BarButton>
                    ) : null}
                    {!withoutChorus ? (
                        <BarButton
                            active={effects.indexOf('chorus') !== -1}
                            className={styles.button}
                            onClick={createEffectOnChange('chorus', effects, onEffectsChange)}
                        >
                            {messages.effectChorus}
                        </BarButton>
                    ) : null}
                    <BarButton
                        className={styles.button}
                        href={`/${intl.locale === 'fr' ? 'en' : 'fr'}/${id || ''}`}
                    >
                        {messages.language}
                    </BarButton>
                </div>
                <div className={styles.volume}>
                    <VolumeButton muted={muted} className={styles.button} onClick={onClickVolume} />
                </div>
            </div>
        </div>
    </div>
);

ChoirBar.propTypes = propTypes;
ChoirBar.defaultProps = defaultProps;

const WithIntlContainer = injectIntl(ChoirBar);

export default WithIntlContainer;
