import {
    SET_LYRICS,
    SET_LOADED,
    SET_STARTED,
    SET_ENDED,
    SET_PLAYING,
    SET_TALKING,
    SET_DURATION,
    SET_TIME,
    SET_MUTED,
    SET_PITCH,
    SET_SOLO_CHARACTER,
    SET_EFFECTS,
} from '../actions/ChoirActions';

const initialState = {
    lyrics: null,
    loaded: false,
    started: false,
    ended: false,
    playing: false,
    talking: false,
    duration: 0,
    time: 0,
    muted: false,
    pitch: 0,
    soloCharacter: null,
    soloAuto: false,
    effects: [],
};

const ChoirReducer = (previousState, action) => {
    let state = previousState || initialState;
    if (typeof state.hydrated === 'undefined' || !state.hydrated) {
        state = {
            ...initialState,
            ...previousState,
            hydrated: true,
        };
    }
    switch (action.type) {
    case SET_LYRICS:
        return {
            ...state,
            lyrics: action.payload,
        };
    case SET_LOADED:
        return {
            ...state,
            loaded: action.payload,
        };
    case SET_STARTED:
        return {
            ...state,
            started: action.payload,
        };
    case SET_ENDED:
        return {
            ...state,
            ended: action.payload,
        };
    case SET_PLAYING:
        return {
            ...state,
            playing: action.payload,
        };
    case SET_TALKING:
        return {
            ...state,
            talking: action.payload,
        };
    case SET_DURATION:
        return {
            ...state,
            duration: action.payload,
        };
    case SET_TIME:
        return {
            ...state,
            time: action.payload,
        };
    case SET_MUTED:
        return {
            ...state,
            muted: action.payload,
        };
    case SET_PITCH:
        return {
            ...state,
            pitch: action.payload,
        };
    case SET_SOLO_CHARACTER: {
        const { id, auto } = action.payload;
        return {
            ...state,
            soloCharacter: id,
            soloAuto: id !== null ? auto : false,
        };
    }
    case SET_EFFECTS:
        return {
            ...state,
            effects: action.payload,
        };
    default:
        return state;
    }
};

export default ChoirReducer;
