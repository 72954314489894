/* eslint-disable global-require */
export default [
    {
        id: '24h-tremblant',
        name: '24h Tremblant',
        voice: require('../audio/full/24h-tremblant.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'sebastien-tremblay',
            'amelie-morin-godard',
            'jeremie-levy',
            'julie-riviere',
            'francis-devoy',
            'marie-pierre-paradis-claes',
            'laurence-pressault',
        ],
    },
    {
        id: '4th-whale',
        name: '4th Whale',
        voice: require('../audio/full/4th-whale.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'nicolas-villeneuve',
            'francois-mouchet',
        ],
    },
    {
        id: 'lassonde',
        name: 'Lassonde',
        voice: require('../audio/full/lassonde.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'yannick-proulx',
            'annabel-dufour-brown',
            'sebastien-tremblay',
            'meryem-abou-el-bal',
            'jeremie-levy',
            'louis-philippe-mathieu',
            'eva-morin',
            'thibault-foyart',
        ],
    },
    {
        id: 'aaa',
        name: 'AAA',
        voice: require('../audio/full/aaa.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'annabel-dufour-brown',
        ],
    },
    {
        id: 'aeroports-de-montreal',
        name: 'Aéroports de Montréal',
        voice: require('../audio/full/aeroports-de-montreal.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'aurelie-bailliache',
            'guillaume-boudreault-monty',
            'jeremie-levy',
            'pascale-anne-giguere',
            'vinicius-araujo',
        ],
    },
    {
        id: 'agence-spatiale-canadienne',
        name: 'Agence spatiale canadienne',
        voice: require('../audio/full/agence-spatiale-canadienne.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'catherine-larose',
            'eva-morin',
            'jeremie-levy',
            'marion-peres',
            'meryem-abou-el-bal',
            'pierre-olivier-boucher',
        ],
    },
    {
        id: 'agropur',
        name: 'Agropur',
        voice: require('../audio/full/agropur.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-ethier',
            'bruno-barbuio',
            'roger-kamena',
            'jonathan-lemay',
        ],
    },
    {
        id: 'air-canada',
        name: 'Air Canada',
        voice: require('../audio/full/air-canada.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'moulaye-traore',
            'julian-naranjo',
            'vinicius-araujo',
        ],
    },
    {
        id: 'air-canada-vacations',
        name: 'Air Canada Vacations',
        voice: require('../audio/full/air-canada-vacations.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'moulaye-traore',
            'julian-naranjo',
            'vinicius-araujo',
        ],
    },
    {
        id: 'alce',
        name: 'ALCE',
        voice: require('../audio/full/alce.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-pierre-scantland',
            'alexane-andreis',
            'simon-ethier',
        ],
    },
    {
        id: 'alithya',
        name: 'Alithya',
        voice: require('../audio/full/alithya.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'sebastien-neveu', 'kristel-salesse'],
    },
    {
        id: 'allo-prof',
        name: 'Allô prof',
        voice: require('../audio/full/allo-prof.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'aurelie-bailliache',
            'amelie-morin-godard',
            'yannick-proulx',
        ],
    },
    {
        id: 'altitude-sport-plein-air',
        name: 'Altitude Sport Plein Air',
        voice: require('../audio/full/altitude-sport-plein-air.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marie-pierre-paradis-claes',
            'amelie-morin-godard',
        ],
    },
    {
        id: 'ardene',
        name: 'Ardène',
        voice: require('../audio/full/ardene.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'aurelie-bailliache',
            'moulaye-traore',
            'meryem-abou-el-bal',
        ],
    },
    {
        id: 'aseq',
        name: 'ASEQ',
        voice: require('../audio/full/aseq.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'aurelie-bailliache',
            'emilie-barthe',
        ],
    },
    {
        id: 'banque-nationale-du-canada-1',
        name: 'Banque Nationale du Canada ',
        voice: require('../audio/full/banque-nationale-du-canada-1.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'charbel-farah',
            'clement-hochedez',
            'emile-chehab',
            'edouard-bourque-trudeau',
            'amelie-morin-godard',
            'marion-peres',
            'yann-kerveant',
        ],
    },
    {
        id: 'banque-nationale-du-canada-2',
        name: 'Banque Nationale du Canada ',
        voice: require('../audio/full/banque-nationale-du-canada-2.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'yann-kerveant',
            'charbel-farah',
            'marie-camille-yao',
            'pierrick-viet',
            'sami-degachi',
            'matthieu-morelle',
            'caroline-leprince',
            'jean-michel-dube',
            'cherine-benyakhou',
            'james-forbes',
            'jessica-verville',
            'mylene-paquette',
        ],
    },
    {
        id: 'banque-nationale-du-canada-3',
        name: 'Banque Nationale du Canada ',
        voice: require('../audio/full/banque-nationale-du-canada-3.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'louis-philippe-mathieu',
            'marie-nicollet',
            'simon-lamarche',
            'charbel-farah',
            'amelie-morin-godard',
            'roger-kamena',
        ],
    },
    {
        id: 'bfrca',
        name: 'BFRCA',
        voice: require('../audio/full/bfrca.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'maxime-louison', 'alexane-andreis'],
    },
    {
        id: 'boiron',
        name: 'Boiron ',
        voice: require('../audio/full/boiron.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'jean-christophe-bouchot'],
    },
    {
        id: 'brp',
        name: 'BRP ',
        voice: require('../audio/full/brp.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'nicolas-duval',
            'vinicius-araujo',
            'philippe-boutin-lizotte',
            'simon-ethier',
        ],
    },
    {
        id: 'bonduelle',
        name: 'Bonduelle',
        voice: require('../audio/full/bonduelle.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'catherine-larose',
            'guillaume-boudreault-monty',
            'marie-pierre-paradis-claes',
            'catherine-gratton-gagne',
            'martin-champagne',
            'nicolas-duval',
            'marion-peres',
        ],
    },
    {
        id: 'boutique-marie-claire',
        name: 'Boutique Marie-Claire ',
        voice: require('../audio/full/boutique-marie-claire.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'pascale-anne-giguere',
            'emilie-barthe',
            'andreanne-achim',
        ],
    },
    {
        id: 'brother-canada',
        name: 'Brother Canada',
        voice: require('../audio/full/brother-canada.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'annabel-dufour-brown',
            'julian-naranjo',
            'yann-kerveant',
            'louis-theoret',
            'emilie-barthe',
            'guillaume-boudreault-monty',
        ],
    },
    {
        id: 'c2-montreal',
        name: 'C2 Montréal',
        voice: require('../audio/full/c2-montreal.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'bruno-barbuio'],
    },
    {
        id: 'caij',
        name: 'CAIJ',
        voice: require('../audio/full/caij.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-ethier',
            'aurelie-bailliache',
            'marion-peres',
            'sebastien-neveu',
            'guillaume-boudreault-monty',
            'emilie-barthe',
            'jonathan-lemay',
        ],
    },
    {
        id: 'caisse-deconomie-solidaire-desjardins',
        name: 'Caisse d’économie solidaire Desjardins',
        voice: require('../audio/full/caisse-deconomie-solidaire-desjardins.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-peres',
            'kristel-salesse',
            'simon-ethier',
        ],
    },
    {
        id: 'campus-infopresse',
        name: 'Campus Infopresse',
        voice: require('../audio/full/campus-infopresse.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'laurence-pressault',
            'sandra-ben-yedder',
            'simon-ethier',
        ],
    },
    {
        id: 'camso',
        name: 'CAMSO',
        voice: require('../audio/full/camso.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'moulaye-traore',
            'julie-riviere',
            'emilie-barthe',
            'eva-morin',
            'meryem-abou-el-bal',
            'simon-ethier',
        ],
    },
    {
        id: 'carre-150',
        name: 'Carré 150',
        voice: require('../audio/full/carre-150.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'marion-dewaele', 'julie-riviere'],
    },
    {
        id: 'cascades-canada-ulc',
        name: 'Cascades Canada ULC',
        voice: require('../audio/full/cascades-canada-ulc.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'simon-ethier', 'marie-nicollet'],
    },
    {
        id: 'cc-internet-immigration-services',
        name: 'CC Internet Immigration Services ',
        voice: require('../audio/full/cc-internet-immigration-services.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'kristel-salesse',
            'pascal-philippe-bergeron',
            'nicolas-villeneuve',
            'yannick-proulx',
            'jonathan-lemay',
            'alexane-andreis',
        ],
    },
    {
        id: 'cecce',
        name: 'CECCE',
        voice: require('../audio/full/cecce.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'maxime-louison', 'vinicius-araujo'],
    },
    {
        id: 'cepsum',
        name: 'CEPSUM',
        voice: require('../audio/full/cepsum.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'eva-morin', 'alexane-andreis'],
    },
    {
        id: 'cfmoto-canada',
        name: 'CFMOTO Canada',
        voice: require('../audio/full/cfmoto-canada.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jean-christophe-bouchot',
            'simon-pierre-scantland',
        ],
    },
    {
        id: 'chambre-des-notaires-du-quebec',
        name: 'Chambre des Notaires du Québec ',
        voice: require('../audio/full/chambre-des-notaires-du-quebec.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-pierre-scantland',
            'jean-philippe-provost',
            'alexane-andreis',
        ],
    },
    {
        id: 'ciot',
        name: 'CIOT',
        voice: require('../audio/full/ciot.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'kristel-salesse',
            'julian-naranjo',
            'aurelie-bailliache',
            'sebastien-neveu',
            'jeremie-levy',
            'simon-ethier',
        ],
    },
    {
        id: 'cirque-du-soleil',
        name: 'Cirque du Soleil ',
        voice: require('../audio/full/cirque-du-soleil.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'aurelie-bailliache',
            'julian-naranjo',
            'martin-champagne',
            'vinicius-araujo',
        ],
    },
    {
        id: 'college-st-jean-vianney',
        name: 'Collège St-Jean-Vianney',
        voice: require('../audio/full/college-st-jean-vianney.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'andreanne-achim',
            'julie-riviere',
            'mathieu-zancan',
        ],
    },
    {
        id: 'colo-d',
        name: 'Colo-D',
        voice: require('../audio/full/colo-d.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'bruno-barbuio',
            'sebastien-tremblay',
        ],
    },
    {
        id: 'cominar',
        name: 'Cominar',
        voice: require('../audio/full/cominar.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'moulaye-traore',
            'guillaume-boudreault-monty',
            'simon-ethier',
            'marie-nicollet',
            'julian-naranjo',
            'sebastien-tremblay',
            'louis-philippe-mathieu',
            'kristel-salesse',
        ],
    },
    {
        id: 'concept-jp',
        name: 'Concept JP',
        voice: require('../audio/full/concept-jp.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'catherine-larose',
            'sebastien-tremblay',
            'mathieu-zancan',
            'aurelie-bailliache',
            'guillaume-boudreault-monty',
        ],
    },
    {
        id: 'conservatoire-de-musique-et-d-art-dramatique-du-quebec',
        name: 'Conservatoire de musique et d art dramatique du Québec',
        voice: require('../audio/full/conservatoire-de-musique-et-d-art-dramatique-du-quebec.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'catherine-larose',
            'eva-morin',
            'sebastien-tremblay',
        ],
    },
    {
        id: 'deserres',
        name: 'DeSerres',
        voice: require('../audio/full/deserres.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-ethier',
            'kristel-salesse',
            'guillaume-boudreault-monty',
        ],
    },
    {
        id: 'deux-huit-huit',
        name: 'Deux Huit Huit',
        voice: require('../audio/full/deux-huit-huit.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'nicolas-duval',
            'aurelie-bailliache',
            'eva-morin',
            'jeremie-levy',
            'francois-mouchet',
            'simon-ethier',
        ],
    },
    {
        id: 'developpement-economique-de-la-ville-de-montreal',
        name: 'Développement économique de la Ville de Montréal',
        voice: require('../audio/full/developpement-economique-de-la-ville-de-montreal.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jonathan-lemay',
            'marie-pierre-paradis-claes',
            'vinicius-araujo',
        ],
    },
    {
        id: 'drakkar-et-associes',
        name: 'Drakkar et associés ',
        voice: require('../audio/full/drakkar-et-associes.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'aurelie-bailliache',
            'moulaye-traore',
            'sebastien-neveu',
        ],
    },
    {
        id: 'duproprio',
        name: 'DuProprio',
        voice: require('../audio/full/duproprio.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'fares-aldik'],
    },
    {
        id: 'ecole-dentrepreneurship-de-beauce',
        name: 'École d’Entrepreneurship de Beauce',
        voice: require('../audio/full/ecole-dentrepreneurship-de-beauce.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'marie-nicollet',
            'simon-ethier',
        ],
    },
    {
        id: 'ecole-des-technologies-superieures',
        name: 'École des Technologies Supérieures ',
        voice: require('../audio/full/ecole-des-technologies-superieures.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'pascal-philippe-bergeron',
            'simon-pierre-scantland',
            'annabel-dufour-brown',
            'alexane-andreis',
        ],
    },
    {
        id: 'equilibre',
        name: 'Équilibre',
        voice: require('../audio/full/equilibre.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'bruno-barbuio', 'mathieu-zancan'],
    },
    {
        id: 'federation-des-cegeps',
        name: 'Fédération des Cégeps',
        voice: require('../audio/full/federation-des-cegeps.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'meryem-abou-el-bal',
            'sebastien-tremblay',
            'thibault-foyart',
            'simon-ethier',
        ],
    },
    {
        id: 'federation-des-producteurs-acericoles-du-quebec',
        name: 'Fédération des producteurs acéricoles du Québec',
        voice: require('../audio/full/federation-des-producteurs-acericoles-du-quebec.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'maxime-louison',
            'louis-theoret',
            'alexane-andreis',
            'simon-ethier',
        ],
    },
    {
        id: 'ficodis',
        name: 'Ficodis',
        voice: require('../audio/full/ficodis.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'simon-ethier'],
    },
    {
        id: 'fillion-electronique',
        name: 'Fillion Électronique',
        voice: require('../audio/full/fillion-electronique.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-pierre-scantland',
            'kristel-salesse',
            'alexane-andreis',
            'simon-ethier',
        ],
    },
    {
        id: 'fluid-distribution',
        name: 'Fluid Distribution',
        voice: require('../audio/full/fluid-distribution.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche'],
    },
    {
        id: 'fondaction',
        name: 'Fondaction',
        voice: require('../audio/full/fondaction.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'eva-morin', 'alexane-andreis'],
    },
    {
        id: 'fondation-du-cancer-du-sein-du-quebec',
        name: 'Fondation du Cancer du Sein du Québec',
        voice: require('../audio/full/fondation-du-cancer-du-sein-du-quebec.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'catherine-gratton-gagne',
            'amelie-morin-godard',
            'andreanne-achim',
        ],
    },
    {
        id: 'fondation-jean-lapointe',
        name: 'Fondation Jean Lapointe',
        voice: require('../audio/full/fondation-jean-lapointe.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'sebastien-tremblay',
            'catherine-gratton-gagne',
            'elizabeth-henry',
            'alexane-andreis',
        ],
    },
    {
        id: 'fruit-dor',
        name: 'Fruit d’Or',
        voice: require('../audio/full/fruit-dor.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'meryem-abou-el-bal',
            'eva-morin',
            'alexane-andreis',
        ],
    },
    {
        id: 'future-electronics',
        name: 'FUTURE ELECTRONICS',
        voice: require('../audio/full/future-electronics.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'moulaye-traore',
            'aurelie-bailliache',
            'fares-aldik',
            'emilie-barthe',
        ],
    },
    {
        id: 'goodnesstvorg',
        name: 'Goodnesstv.org',
        voice: require('../audio/full/goodnesstvorg.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-pierre-scantland',
            'alexane-andreis',
        ],
    },
    {
        id: 'groupe-antonopoulos',
        name: 'Groupe Antonopoulos',
        voice: require('../audio/full/groupe-antonopoulos.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-pierre-scantland',
            'annabel-dufour-brown',
            'alexane-andreis',
        ],
    },
    {
        id: 'groupe-deschenes',
        name: 'Groupe Deschênes',
        voice: require('../audio/full/groupe-deschenes.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'alexane-andreis',
            'catherine-gratton-gagne',
            'jonathan-lemay',
            'marie-pierre-paradis-claes',
            'simon-ethier',
            'amelie-morin-godard',
            'aurelie-bailliache',
        ],
    },
    {
        id: 'gsoft',
        name: 'GSoft ',
        voice: require('../audio/full/gsoft.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'aurelie-bailliache',
            'jonathan-lemay',
            'martin-champagne',
            'pierre-olivier-boucher',
            'vinicius-araujo',
        ],
    },
    {
        id: 'groupe-park-avenue',
        name: 'Groupe Park Avenue',
        voice: require('../audio/full/groupe-park-avenue.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'philippe-boutin-lizotte',
            'mathieu-zancan',
            'jean-philippe-provost',
            'maxime-louison',
            'alexane-andreis',
        ],
    },
    {
        id: 'sharegate',
        name: 'Sharegate ',
        voice: require('../audio/full/sharegate.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'julian-naranjo',
            'nicolas-duval',
            'sebastien-neveu',
            'vinicius-araujo',
            'yann-kerveant',
        ],
    },
    {
        id: 'v-media',
        name: 'V MÉDIA',
        voice: require('../audio/full/v-media.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'marion-dewaele', 'fares-aldik'],
    },
    {
        id: 'muhc',
        name: 'MUHC',
        voice: require('../audio/full/muhc.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jean-philippe-provost',
            'julian-naranjo',
            'simon-pierre-scantland',
            'vinicius-araujo',
        ],
    },
    {
        id: 'hydro-quebec',
        name: 'Hydro-Québec ',
        voice: require('../audio/full/hydro-quebec.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'aurelie-bailliache',
            'vinicius-araujo',
            'yann-kerveant',
        ],
    },
    {
        id: 'hydrosolution',
        name: 'HydroSolution',
        voice: require('../audio/full/hydrosolution.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'philippe-boutin-lizotte',
            'julie-riviere',
            'jonathan-lemay',
            'meryem-abou-el-bal',
            'simon-ethier',
        ],
    },
    {
        id: 'iata',
        name: 'IATA',
        voice: require('../audio/full/iata.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'aurelie-bailliache',
        ],
    },
    {
        id: 'intact',
        name: 'Intact',
        voice: require('../audio/full/intact.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'vinicius-araujo'],
    },
    {
        id: 'funio',
        name: 'Funio',
        voice: require('../audio/full/funio.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jeremie-levy',
            'julie-riviere',
            'vinicius-araujo',
            'simon-ethier',
        ],
    },
    {
        id: 'juste-pour-rire',
        name: 'Juste pour Rire',
        voice: require('../audio/full/juste-pour-rire.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'philippe-boutin-lizotte',
            'yannick-proulx',
            'olivier-houle',
        ],
    },
    {
        id: 'limpact-de-montreal',
        name: 'L’Impact de Montréal',
        voice: require('../audio/full/limpact-de-montreal.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-pierre-scantland',
            'francois-mouchet',
        ],
    },
    {
        id: 'la-cordee',
        name: 'La Cordée',
        voice: require('../audio/full/la-cordee.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'vinicius-araujo',
            'aurelie-bailliache',
            'sebastien-neveu',
            'simon-pierre-scantland',
            'mathieu-zancan',
            'simon-ethier',
            'maxime-louison',
            'kristel-salesse',
        ],
    },
    {
        id: 'la-mine-canadian-malartic',
        name: 'La mine Canadian Malartic',
        voice: require('../audio/full/la-mine-canadian-malartic.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'eva-morin',
            'marion-peres',
            'meryem-abou-el-bal',
            'gwendolyn-ung',
            'francois-mouchet',
        ],
    },
    {
        id: 'la-presse',
        name: 'La Presse',
        voice: require('../audio/full/la-presse.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'fares-aldik'],
    },
    {
        id: 'createch',
        name: 'Créatech',
        voice: require('../audio/full/createch.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'marion-peres',
            'sebastien-tremblay',
            'catherine-gratton-gagne',
            'sebastien-neveu',
            'julian-naranjo',
            'louis-philippe-mathieu',
            'yann-kerveant',
            'kristel-salesse',
            'simon-ethier',
        ],
    },
    {
        id: 'nespresso-canada',
        name: 'Nespresso Canada',
        voice: require('../audio/full/nespresso-canada.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche'],
    },
    {
        id: 'les-brasseurs-du-nord',
        name: 'Les Brasseurs du Nord',
        voice: require('../audio/full/les-brasseurs-du-nord.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'philippe-boutin-lizotte',
            'catherine-larose',
            'sebastien-tremblay',
            'francis-devoy',
            'meryem-abou-el-bal',
            'louis-philippe-mathieu',
        ],
    },
    {
        id: 'les-vins-philippe-dandurand',
        name: 'Les Vins Philippe Dandurand',
        voice: require('../audio/full/les-vins-philippe-dandurand.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'yannick-proulx',
            'amelie-morin-godard',
        ],
    },
    {
        id: 'loto-quebec',
        name: 'Loto-Quebec',
        voice: require('../audio/full/loto-quebec.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'emilie-barthe',
            'jean-christophe-bouchot',
            'simon-ethier',
        ],
    },
    {
        id: 'louis-garneau',
        name: 'Louis Garneau ',
        voice: require('../audio/full/louis-garneau.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jean-philippe-provost',
            'jean-christophe-mauss',
            'francis-devoy',
            'emilie-barthe',
            'andreanne-achim',
            'simon-ethier',
        ],
    },
    {
        id: 'lowes',
        name: "Lowe's",
        voice: require('../audio/full/lowes.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'bruno-barbuio',
            'francois-mouchet',
            'jonathan-lemay',
            'marie-pierre-paradis-claes',
            'nicolas-scott',
            'roger-kamena',
            'catherine-gratton-gagne',
            'aurelie-bailliache',
        ],
    },
    {
        id: 'lumen',
        name: 'Lumen',
        voice: require('../audio/full/lumen.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'simon-ethier'],
    },
    {
        id: 'luminaires-et-cie',
        name: 'Luminaires et Cie ',
        voice: require('../audio/full/luminaires-et-cie.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-ethier',
            'francis-devoy',
            'amelie-morin-godard',
            'thibault-foyart',
            'clement-hochedez',
            'james-forbes',
            'jean-philippe-provost',
            'bruno-barbuio',
        ],
    },
    {
        id: 'mac',
        name: 'MAC',
        voice: require('../audio/full/mac.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'julie-riviere', 'alexane-andreis'],
    },
    {
        id: 'marcus-thomas-llc',
        name: 'Marcus Thomas LLC',
        voice: require('../audio/full/marcus-thomas-llc.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'louis-theoret',
            'pierre-olivier-boucher',
            'thibault-foyart',
        ],
    },
    {
        id: 'mckesson',
        name: 'McKesson',
        voice: require('../audio/full/mckesson.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'andreanne-achim',
            'pierre-olivier-boucher',
            'nicolas-duval',
        ],
    },
    {
        id: 'lasik-md',
        name: 'Lasik MD',
        voice: require('../audio/full/lasik-md.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'guillaume-boudreault-monty',
            'nicolas-villeneuve',
            'francis-devoy',
            'maxime-louison',
            'jean-philippe-provost',
            'pierre-olivier-boucher',
            'jonathan-lemay',
            'marion-peres',
            'jeremie-levy',
            'kristel-salesse',
        ],
    },
    {
        id: 'metro',
        name: 'Métro ',
        voice: require('../audio/full/metro.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'catherine-gratton-gagne',
            'catherine-larose',
            'eva-morin',
            'jonathan-lemay',
        ],
    },
    {
        id: 'mode-choc',
        name: 'Mode Choc',
        voice: require('../audio/full/mode-choc.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'louis-philippe-mathieu',
            'eva-morin',
        ],
    },
    {
        id: 'musee-des-beaux-arts-de-montreal',
        name: 'Musée des beaux-arts de Montréal',
        voice: require('../audio/full/musee-des-beaux-arts-de-montreal.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'nicolas-duval',
            'aurelie-bailliache',
            'eva-morin',
            'jeremie-levy',
            'francois-mouchet',
        ],
    },
    {
        id: 'natures-touch',
        name: 'Natures Touch',
        voice: require('../audio/full/natures-touch.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'pascale-anne-giguere',
            'mathieu-zancan',
            'emilie-barthe',
        ],
    },
    {
        id: 'nautilus-plus',
        name: 'Nautilus Plus',
        voice: require('../audio/full/nautilus-plus.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'moulaye-traore',
            'aurelie-bailliache',
            'pascale-anne-giguere',
            'francis-devoy',
            'gwendolyn-ung',
            'sebastien-tremblay',
            'jeremie-levy',
            'marie-pierre-paradis-claes',
            'catherine-gratton-gagne',
            'jonathan-lemay',
            'andreanne-achim',
            'emilie-barthe',
        ],
    },
    {
        id: 'nespresso',
        name: 'Nespresso ',
        voice: require('../audio/full/nespresso.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'roger-kamena',
            'pascal-philippe-bergeron',
            'mathieu-zancan',
            'yannick-proulx',
            'philippe-boutin-lizotte',
            'thibault-foyart',
        ],
    },
    {
        id: 'newad',
        name: 'Newad',
        voice: require('../audio/full/newad.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'roger-kamena',
            'nicolas-scott',
            'thibault-foyart',
        ],
    },
    {
        id: 'normandin-beaudry',
        name: 'Normandin Beaudry',
        voice: require('../audio/full/normandin-beaudry.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-ethier',
            'alexane-andreis',
            'marie-nicollet',
            'jeremie-levy',
            'sebastien-tremblay',
        ],
    },
    {
        id: 'office-du-tourisme-de-quebec',
        name: 'Office du tourisme de Quebec',
        voice: require('../audio/full/office-du-tourisme-de-quebec.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'sebastien-neveu',
            'nicolas-duval',
            'catherine-gratton-gagne',
            'jean-christophe-bouchot',
            'alexane-andreis',
        ],
    },
    {
        id: 'office-national-du-film',
        name: 'Office National du Film ',
        voice: require('../audio/full/office-national-du-film.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'francois-mouchet',
            'aurelie-bailliache',
            'julian-naranjo',
        ],
    },
    {
        id: 'olymel',
        name: 'Olymel ',
        voice: require('../audio/full/olymel.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-peres',
            'simon-ethier',
            'jeremie-levy',
            'vinicius-araujo',
            'jonathan-lemay',
            'louis-theoret',
            'aurelie-bailliache',
            'julian-naranjo',
            'kristel-salesse',
        ],
    },
    {
        id: 'ordre-des-architectes-du-quebec',
        name: 'Ordre des architectes du Québec',
        voice: require('../audio/full/ordre-des-architectes-du-quebec.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'eva-morin',
            'marion-peres',
            'meryem-abou-el-bal',
        ],
    },
    {
        id: 'ordre-des-pharmaciens-du-quebec',
        name: 'Ordre des pharmaciens du Québec',
        voice: require('../audio/full/ordre-des-pharmaciens-du-quebec.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'eva-morin',
            'marion-peres',
        ],
    },
    {
        id: 'osm',
        name: 'OSM',
        voice: require('../audio/full/osm.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'julie-riviere',
            'aurelie-bailliache',
            'alexane-andreis',
        ],
    },
    {
        id: 'pneus-belisle',
        name: 'Pneus Bélisle',
        voice: require('../audio/full/pneus-belisle.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'maxime-louison',
            'jean-philippe-provost',
            'simon-ethier',
        ],
    },
    {
        id: 'prevel',
        name: 'Prével',
        voice: require('../audio/full/prevel.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'annabel-dufour-brown',
            'mathieu-zancan',
            'pierre-olivier-boucher',
            'emilie-barthe',
            'guillaume-boudreault-monty',
        ],
    },
    {
        id: 'promutuel',
        name: 'Promutuel ',
        voice: require('../audio/full/promutuel.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'stephanie-bouchard',
            'pascal-philippe-bergeron',
            'francois-mouchet',
        ],
    },
    {
        id: 'r3-marketing',
        name: 'R3 Marketing ',
        voice: require('../audio/full/r3-marketing.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jonathan-lemay',
            'mathieu-zancan',
            'vinicius-araujo',
        ],
    },
    {
        id: 'regie-des-installations-olympiques',
        name: 'Régie des installations olympiques',
        voice: require('../audio/full/regie-des-installations-olympiques.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'nicolas-duval',
            'nicolas-villeneuve',
            'vinicius-araujo',
        ],
    },
    {
        id: 'reitmans',
        name: 'Reitmans ',
        voice: require('../audio/full/reitmans.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'olivier-houle',
            'jean-christophe-mauss',
            'mohammed-mekouar',
            'caroline-boulard',
            'philippe-boutin-lizotte',
            'simon-lahaie',
            'thibault-foyart',
        ],
    },
    {
        id: 'reseau-reussite-montreal',
        name: 'Réseau Réussite Montréal',
        voice: require('../audio/full/reseau-reussite-montreal.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'alexane-andreis'],
    },
    {
        id: 'rideau-vineyard',
        name: 'Rideau Vineyard',
        voice: require('../audio/full/rideau-vineyard.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'catherine-larose',
            'eva-morin',
            'maxime-louison',
        ],
    },
    {
        id: 'rogers',
        name: 'Rogers',
        voice: require('../audio/full/rogers.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'marion-dewaele', 'fares-aldik'],
    },
    {
        id: 'rona',
        name: 'RONA ',
        voice: require('../audio/full/rona.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'roger-kamena',
            'nicolas-scott',
            'catherine-gratton-gagne',
            'aurelie-bailliache',
            'moulaye-traore',
            'francois-mouchet',
        ],
    },
    {
        id: 'saputo',
        name: 'Saputo',
        voice: require('../audio/full/saputo.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'bruno-barbuio', 'jonathan-lemay'],
    },
    {
        id: 'saq',
        name: 'SAQ',
        voice: require('../audio/full/saq.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'fares-aldik'],
    },
    {
        id: 'sarah-pacini',
        name: 'Sarah Pacini',
        voice: require('../audio/full/sarah-pacini.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'yannick-proulx',
            'emilie-barthe',
            'andreanne-achim',
        ],
    },
    {
        id: 'snowplow-analytics',
        name: 'Snowplow Analytics',
        voice: require('../audio/full/snowplow-analytics.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'fares-aldik'],
    },
    {
        id: 'snowwater',
        name: 'SnowWater',
        voice: require('../audio/full/snowwater.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'simon-pierre-scantland',
            'alexane-andreis',
        ],
    },
    {
        id: 'sobeys',
        name: 'SOBEYS',
        voice: require('../audio/full/sobeys.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'roger-kamena',
            'yannick-proulx',
            'guillaume-boudreault-monty',
            'simon-ethier',
            'marie-nicollet',
            'thibault-foyart',
        ],
    },
    {
        id: 'shdm',
        name: 'SHDM',
        voice: require('../audio/full/shdm.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'jean-philippe-provost',
        ],
    },
    {
        id: 'radio-canada',
        name: 'Radio-Canada',
        voice: require('../audio/full/radio-canada.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'mathieu-zancan',
            'nicolas-scott',
            'pascal-philippe-bergeron',
            'roger-kamena',
            'vinicius-araujo',
        ],
    },
    {
        id: 'spec-du-haut-richelieu',
        name: 'SPEC du Haut-Richelieu',
        voice: require('../audio/full/spec-du-haut-richelieu.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'annabel-dufour-brown',
        ],
    },
    {
        id: 'tc-transcontinental',
        name: 'TC Transcontinental',
        voice: require('../audio/full/tc-transcontinental.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'emilie-barthe',
            'maxime-louison',
            'louis-philippe-mathieu',
            'aurelie-bailliache',
            'kristel-salesse',
        ],
    },
    {
        id: 'technologia',
        name: 'Technologia',
        voice: require('../audio/full/technologia.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'andreanne-achim',
            'amelie-morin-godard',
            'pascale-anne-giguere',
            'jonathan-lemay',
            'sebastien-tremblay',
            'simon-ethier',
            'marie-nicollet',
        ],
    },
    {
        id: 'telus-sante',
        name: 'Telus Santé',
        voice: require('../audio/full/telus-sante.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'philippe-boutin-lizotte',
            'jonathan-lemay',
            'emilie-barthe',
        ],
    },
    {
        id: 'the-aldo-group-inc',
        name: 'The Aldo Group Inc.',
        voice: require('../audio/full/the-aldo-group-inc.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'marion-dewaele', 'moulaye-traore'],
    },
    {
        id: 'theatre-hector-charland',
        name: 'Théâtre Hector-Charland',
        voice: require('../audio/full/theatre-hector-charland.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'marion-dewaele', 'julie-riviere'],
    },
    {
        id: 'tim-ferriss-enterprises',
        name: 'Tim Ferriss Enterprises',
        voice: require('../audio/full/tim-ferriss-enterprises.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'bruno-barbuio',
            'jean-michel-dube',
            'sonia-allard',
        ],
    },
    {
        id: 'pneus-touchette',
        name: 'Pneus Touchette ',
        voice: require('../audio/full/pneus-touchette.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'catherine-gratton-gagne',
            'pascale-anne-giguere',
            'francois-mouchet',
        ],
    },
    {
        id: 'tourisme-cantons-de-lest',
        name: "Tourisme Cantons-de-l'Est",
        voice: require('../audio/full/tourisme-cantons-de-lest.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'eva-morin',
            'jean-philippe-provost',
            'aurelie-bailliache',
        ],
    },
    {
        id: 'transport-social-netlift',
        name: 'Transport Social Netlift',
        voice: require('../audio/full/transport-social-netlift.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'stephanie-bouchard',
            'alexane-andreis',
        ],
    },
    {
        id: 'ugroup-media',
        name: 'Ugroup Média ',
        voice: require('../audio/full/ugroup-media.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'fares-aldik',
            'moulaye-traore',
            'aurelie-bailliache',
            'julian-naranjo',
            'alexane-andreis',
        ],
    },
    {
        id: 'uniban',
        name: 'Uniban',
        voice: require('../audio/full/uniban.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'bruno-barbuio'],
    },
    {
        id: 'uniprix',
        name: 'Uniprix',
        voice: require('../audio/full/uniprix.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'marion-dewaele',
            'marie-pierre-paradis-claes',
            'andreanne-achim',
            'pierre-olivier-boucher',
            'aurelie-bailliache',
        ],
    },
    {
        id: 'velo-quebec',
        name: 'Vélo Québec',
        voice: require('../audio/full/velo-quebec.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'annabel-dufour-brown',
            'pascale-anne-giguere',
            'vinicius-araujo',
        ],
    },
    {
        id: 'verdant',
        name: 'Verdant ',
        voice: require('../audio/full/verdant.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'emilie-barthe',
            'guillaume-boudreault-monty',
            'pascale-anne-giguere',
            'gwendolyn-ung',
        ],
    },
    {
        id: 'videotron',
        name: 'Vidéotron ',
        voice: require('../audio/full/videotron.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'moulaye-traore', 'vinicius-araujo'],
    },
    {
        id: 'ville-de-boucherville',
        name: 'Ville de Boucherville',
        voice: require('../audio/full/ville-de-boucherville.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'eva-morin', 'alexane-andreis'],
    },
    {
        id: 'ville-de-montreal',
        name: 'Ville de Montréal',
        voice: require('../audio/full/ville-de-montreal.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jeremie-levy',
            'jonathan-lemay',
            'meryem-abou-el-bal',
            'vinicius-araujo',
            'yannick-proulx',
        ],
    },
    {
        id: 'ville-de-st-bruno-de-montarville',
        name: 'Ville de St-Bruno de Montarville',
        voice: require('../audio/full/ville-de-st-bruno-de-montarville.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'pierre-olivier-boucher',
            'kristel-salesse',
        ],
    },
    {
        id: 'yokohama',
        name: 'Yokohama',
        voice: require('../audio/full/yokohama.mp3'),
        employees: ['jean-francois-renaud', 'simon-lamarche', 'gwendolyn-ung', 'emilie-barthe'],
    },
    {
        id: 'cfmoto',
        name: 'CFMOTO',
        voice: require('../audio/full/cfmoto.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jean-christophe-bouchot',
            'simon-pierre-scantland',
        ],
    },
    {
        id: 'fondation-de-linstitut-de-cardiologie-de-montreal',
        name: "Fondation de l'Institut de Cardiologie de Montréal",
        voice: require('../audio/full/fondation-de-linstitut-de-cardiologie-de-montreal.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jean-christophe-bouchot',
            'sebastien-tremblay',
            'catherine-gratton-gagne',
        ],
    },
    {
        id: 'sailsportium',
        name: 'SAIL/SPORTIUM',
        voice: require('../audio/full/sailsportium.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jean-christophe-bouchot',
            'roger-kamena',
        ],
    },
    {
        id: 'energir',
        name: 'Énergir',
        voice: require('../audio/full/energir.mp3'),
        employees: [
            'jean-francois-renaud',
            'simon-lamarche',
            'jean-christophe-bouchot',
            'roger-kamena',
            'jonathan-lemay',
        ],
    },
];
