/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import sizeable, { refPropTypes } from '../../lib/sizeable';
import Character from './Character';

import styles from '../../styles/partials/choir-characters.scss';

const propTypes = {
    // width: PropTypes.number,
    height: PropTypes.number,
    sizeableRef: refPropTypes.isRequired,
    charactersRef: PropTypes.func,
    characters: AppPropTypes.charactersRows,
    bodyGifs: AppPropTypes.gifs,
    soloCharacter: PropTypes.string,
    started: PropTypes.bool,
    playing: PropTypes.bool,
    talking: PropTypes.bool,
    animating: PropTypes.bool,
    sizes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            minRows: PropTypes.number,
        }),
    ),
    className: PropTypes.string,
    debug: PropTypes.bool,
};

const defaultProps = {
    // width: null,
    height: null,
    charactersRef: null,
    characters: [],
    bodyGifs: [],
    soloCharacter: null,
    started: false,
    playing: false,
    talking: false,
    animating: false,
    sizes: [
        {
            id: 'small',
            minRows: 0,
            heightResize: 1,
        },
        {
            id: 'medium',
            minRows: 4,
            heightResize: 0.7,
        },
        {
            id: 'large',
            minRows: 8,
            heightResize: 0.5,
        },
    ],
    className: null,
    debug: false,
};

const ChoirCharacters = ({
    height,
    sizeableRef,
    charactersRef,
    characters,
    bodyGifs,
    soloCharacter,
    started,
    playing,
    talking,
    animating,
    sizes,
    className,
    debug,
}) => {
    const rowsCount = characters.length;
    const size = sizes.reduce(
        (currentSize, nextSize) => (rowsCount >= nextSize.minRows ? nextSize : currentSize),
        null,
    );
    const soloIds = soloCharacter !== null
        ? characters.reduce((ids, rowCharacters, rowIndex) => {
            const colIndex = rowCharacters.findIndex(
                character => character.id === soloCharacter,
            );
            return colIndex !== -1
                ? [
                    `${rowIndex - 1}-${colIndex - 2}`,
                    `${rowIndex - 1}-${colIndex - 1}`,
                    `${rowIndex - 1}-${colIndex}`,
                    `${rowIndex - 1}-${colIndex + 1}`,
                    `${rowIndex - 1}-${colIndex + 2}`,
                    `${rowIndex}-${colIndex - 1}`,
                    `${rowIndex}-${colIndex}`,
                    `${rowIndex}-${colIndex + 1}`,
                    `${rowIndex + 1}-${colIndex - 2}`,
                    `${rowIndex + 1}-${colIndex - 1}`,
                    `${rowIndex + 1}-${colIndex}`,
                    `${rowIndex + 1}-${colIndex + 1}`,
                    `${rowIndex + 1}-${colIndex + 2}`,
                ]
                : ids;
        }, [])
        : null;
    return (
        <div
            className={classNames([
                styles.container,
                [styles[size.id]],
                {
                    [styles.debug]: debug,
                    [className]: className !== null,
                },
            ])}
            ref={sizeableRef}
        >
            <div className={styles.inner}>
                {characters.map((rowCharacters, rowIndex) => (
                    <div key={`row-${rowIndex}`} className={styles.row}>
                        {rowCharacters.map((character, characterIndex) => {
                            const positionId = `${rowIndex}-${characterIndex}`;
                            return (
                                <div key={`character-${character.id}`} className={styles.col}>
                                    <div
                                        className={styles.character}
                                        ref={ref => (charactersRef !== null
                                            ? charactersRef(ref, character, characterIndex)
                                            : null)
                                        }
                                    >
                                        {character.id !== 'spacer' ? (
                                            <Character
                                                {...character}
                                                bodyGifs={bodyGifs}
                                                isSolo={soloCharacter === character.id}
                                                isLarge={
                                                    soloIds !== null
                                                    && soloIds.indexOf(positionId) !== -1
                                                }
                                                isMask={!started}
                                                debug={debug}
                                                playing={playing}
                                                talking={talking}
                                                animating={animating}
                                                maxHeight={height * size.heightResize}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

ChoirCharacters.propTypes = propTypes;
ChoirCharacters.defaultProps = defaultProps;

export default sizeable(React.memo(ChoirCharacters));
