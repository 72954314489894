/* eslint-disable global-require */
export default [
    {
        gender: 'female',
        voice: require('../audio/vocals/AlexaneAndreis.mp3'),
    },
    {
        gender: 'female',
        voice: require('../audio/vocals/AurelieBailliache.mp3'),
    },
    {
        gender: 'female',
        voice: require('../audio/vocals/CatherineGrattonGagne.mp3'),
    },
    {
        gender: 'male',
        voice: require('../audio/vocals/CharbelFarah.mp3'),
    },
    {
        gender: 'male',
        voice: require('../audio/vocals/EmileChehab.mp3'),
    },
    {
        gender: 'male',
        voice: require('../audio/vocals/FrancisDevoyKevinParent.mp3'),
    },
    {
        gender: 'male',
        voice: require('../audio/vocals/Francois_Mouchet.mp3'),
    },
    {
        gender: 'male',
        voice: require('../audio/vocals/GuillaumeBoudreauMonty.mp3'),
    },
    {
        gender: 'male',
        voice: require('../audio/vocals/Jean-ChristopheBouchot.mp3'),
    },
    {
        gender: 'male',
        voice: require('../audio/vocals/Jean-FrancoisRenaud.mp3'),
    },
    {
        gender: 'male',
        voice: require('../audio/vocals/Jean-MichelDube.mp3'),
    },
    {
        gender: 'female',
        voice: require('../audio/vocals/JulieRiviere.mp3'),
    },
    {
        gender: 'female',
        voice: require('../audio/vocals/LaurencePressault_Aigue.mp3'),
    },
    {
        gender: 'female',
        voice: require('../audio/vocals/LaurencePressault.mp3'),
    },
    {
        gender: 'male',
        voice: require('../audio/vocals/MarcAndreLeBail.mp3'),
    },
    {
        gender: 'female',
        voice: require('../audio/vocals/MarieCamilleYao.mp3'),
    },
    {
        gender: 'female',
        voice: require('../audio/vocals/MariePierreParadisClaes.mp3'),
    },
    {
        gender: 'female',
        voice: require('../audio/vocals/MeryemAbouElBal.mp3'),
    },
    {
        gender: 'female',
        voice: require('../audio/vocals/MylenePaquette.mp3'),
    },
    {
        gender: 'female',
        voice: require('../audio/vocals/RachelCote.mp3'),
    },
    {
        gender: 'male',
        voice: require('../audio/vocals/SimonEthier.mp3'),
    },
];
