import React from 'react';
import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';
import ImagePattern from './ImagePattern';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    originalWidth: PropTypes.number,
    originalHeight: PropTypes.number,
    id: PropTypes.string,
    playing: PropTypes.bool,
    animating: PropTypes.bool,
    bodyColor: PropTypes.string,
    legsColor: PropTypes.string,
    skinColor: PropTypes.string,
    gif: AppPropTypes.gif,
    gifRatio: PropTypes.number,
    pattern: AppPropTypes.pattern,
    isMask: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    width: null,
    height: null,
    originalWidth: 305.44,
    originalHeight: 643.69,
    id: null,
    playing: false,
    animating: false,
    bodyColor: '#FF4733',
    legsColor: '#000',
    skinColor: '#E0C0AC',
    gif: null,
    gifRatio: 0.25,
    pattern: null,
    isMask: false,
    className: null,
};

const Female1 = ({
    width,
    height,
    originalWidth,
    originalHeight,
    id,
    playing,
    animating,
    bodyColor,
    legsColor,
    skinColor,
    gif,
    gifRatio,
    pattern,
    isMask,
    className,
}) => {
    const hasPattern = pattern !== null && !isMask;
    const hasGif = gif !== null && !isMask;
    const midWidth = Math.floor(width / 2);
    const midHeight = Math.floor(height / 2);

    let bodyFill = isMask ? '#000' : bodyColor;
    if (hasGif) {
        bodyFill = `url(#gif-${id})`;
    } else if (hasPattern) {
        bodyFill = `url(#pattern-${id})`;
    }
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={`${
                width === null && height !== null
                    ? height * (originalWidth / originalHeight)
                    : width || originalWidth
            }px`}
            height={`${
                height === null && width !== null
                    ? width / (originalWidth / originalHeight)
                    : height || originalHeight
            }px`}
            viewBox="0 0 305.44 643.69"
            xmlSpace="preserve"
            className={className}
        >
            <defs>
                {pattern !== null ? <ImagePattern id={`pattern-${id}`} {...pattern} /> : null}
                {hasGif ? <ImagePattern id={`gif-${id}`} {...gif} scale={gifRatio} /> : null}
            </defs>
            <path
                fill={!isMask ? legsColor : '#000'}
                d="M194.39,589.55c1.03-9.24-1.02-18.39-3.6-19.8c0,0-0.87-6.99-3.6-11.7c-2.73-4.7-6.42-26.97-7.2-51.29c-0.78-24.32,1.05-77.14-2.7-93.58s-3.78-31.05-1.8-40.49c1.98-9.44,2.7-28.79,2.7-28.79h-52.19c0,0,3.28,11.15,2.7,24.29c-0.58,13.15-4.35,52.8-1.8,65.69c2.55,12.89,32.59,117.62,33.29,125.07c0.7,7.45-2.7,12.6-2.7,12.6s-6.18,5.43-7.2,15.3s-1.8,14.4-1.8,14.4s-5.87,6.57-7.2,9.9c-1.33,3.33-6.98,11.75-4.5,17.1c0,0-4.24,9.82,2.7,11.7c6.94,1.88,17.62,3.36,25.19,3.6c7.57,0.24,18.66,0.67,21.6-2.7c2.94-3.37-1.27-13.59,2.7-17.1s6-9.84,5.4-15.3C193.79,602.98,193.36,598.78,194.39,589.55z"
            />
            <path
                fill={!isMask ? legsColor : '#000'}
                d="M250.51,620.76c1.97-5.05-4.43-13-6.06-16.14s-8.07-9.34-8.07-9.34s-1.19-4.28-3.13-13.6s-8.58-14.44-8.58-14.44s-3.86-4.85-3.85-11.9c0.01-7.04,20.13-105.98,21.46-118.15c1.33-12.17-6.1-49.62-7.89-62.04c-1.79-12.41,0.42-22.95,0.42-22.95l-51.86,0.05c0,0,2.51,18.27,5.36,27.19c2.85,8.92,4.18,22.71,1.97,38.24s4.53,65.42,6.01,88.38c1.49,22.97-0.11,44-2.39,48.45c-2.28,4.44-2.49,11.05-2.49,11.05c-2.43,1.33-3.62,9.98-1.74,18.7s1.85,12.69,1.76,17.85s2.51,11.13,6.79,14.44s1.04,12.97,4.27,16.14c3.23,3.18,14.21,2.76,21.71,2.53c7.5-0.23,17.98-1.64,24.7-3.42C255.64,630.03,250.51,620.76,250.51,620.76z"
            />
            <g>
                <path
                    fill={!isMask ? skinColor : '#000'}
                    d="M304.87,314.72c0,0-17,2.5-22,3.5s-9.5,2.5-9.5,2.5s4,12,3.5,17s-6.41,10,0.04,11c0,0-5.46,6,0,8c5.46,2,13.46-6.5,18.96-11s4.5-9.5,6-17S306.78,321.48,304.87,314.72z M285.37,336.72c0,0-3-9,0-12.5S291.87,329.22,285.37,336.72z"
                />
                <path
                    fill={!isMask ? skinColor : '#000'}
                    d="M103.94,221.72c7,0.5,4-18,4-22s4.01-11,4.01-11l-7.51,1C104.44,189.72,92.94,216.22,103.94,221.72z"
                />
                <path
                    fill={bodyFill}
                    d="M302.94,273.72c0-9-8.5-86.5-8.5-86.5c-1.5-45.5-9.5-103-9.5-103c2.5-18-1.5-41.5-8.5-55s-36.5-12.5-36.5-12.5c-2.5-7.5-14.5-12.5-14.5-12.5c-20.5-6-69-4-71.5-3.5s-11,8.5-11,8.5s-7-1.5-20.5-2s-28.5,5-47.5,21.5s-45,43-59,58.29s-18,19.71-15,29.21s12,14,33.5,27.5s35.5,14.5,35.5,14.5c4,7,24.5,17.5,24.5,17.5c-6,4-3.5,9,2.5,9.83s-1,12.67,2.5,10.67c2.48-1.42,3.94-4.07,4.59-5.51c0.25-0.63,0.41-0.99,0.41-0.99l7.51-1c0,0-4.01,7-4.01,11s3,22.5-4,22c-3.57-1.79-4.76-5.79-4.75-10.37c-0.67,2.61-1.29,4.89-1.75,6.37c-2,6.5-7,30.5-8.5,51s1,53,6,61s7,30.5,7,30.5c5,3.5,10,6,24,6s76.5,2.5,105,4.5s39.5-4.5,39.5-4.5c0-1.5-5-30-5-30c0.5-13.5-3.5-22-3.5-22c1.5-11.5-8-23.5-8-23.5c2.5-11.5-6-19-6-19s-0.5-10,0-26s-3-25-5.5-51s5.5-47,5.5-47c-3,16,4,51.5,4,51.5c0,7,4.5,24.5,4.5,24.5c-1,10,4.5,30,4.5,30c1,16,12,41.5,12,41.5c-3,9.5,1,25.5,1,25.5s4.5-1.5,9.5-2.5s22-3.5,22-3.5C300.94,304.72,302.94,282.72,302.94,273.72z M111.94,158.22c-2-10.5-28-20-28-20c-1.5-6.5-11-7-13-10.5c-2-3.5-3.5-1-9-5.1s-13.5-15.9-13.5-15.9c18.5-2,12-10,20.5-12s12-9,14-7.5s13,17.5,13,17.5c0,8.5,4.5,20,10.5,30.5S111.94,158.22,111.94,158.22z"
                />
                {playing && animating ? (
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from={`0 ${midWidth} ${midHeight}`}
                        to={`-1 ${midWidth} ${midHeight}`}
                        dur="0.66s"
                        repeatCount="indefinite"
                    />
                ) : null}
            </g>
        </svg>
    );
};

Female1.propTypes = propTypes;
Female1.defaultProps = defaultProps;

export default React.memo(Female1);
