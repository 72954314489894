/* eslint-disable global-require */
export default [
    {
        id: 'alexane-andreis',
        name: 'Alexane Andreis',
        gender: 'female',
        head: {
            original: require('../img/employees/alexane-andreis.png'),
            small: require('../img/employees/small/alexane-andreis.png'),
            medium: require('../img/employees/medium/alexane-andreis.png'),
            mask: require('../img/employees/masks/alexane-andreis.png'),
        },
        neckCenter: {
            x: 1266 / 3000,
            y: 2200 / 3000,
        },
        mouthCenter: {
            x: 434 / 1000,
            y: 467 / 1000,
            deg: 5,
        },
        voice: require('../audio/vocals/AlexaneAndreis.mp3'),
    },
    {
        id: 'amelie-morin-godard',
        name: 'Amélie Morin-Godard',
        gender: 'female',
        head: {
            original: require('../img/employees/amelie-morin-godard.png'),
            small: require('../img/employees/small/amelie-morin-godard.png'),
            medium: require('../img/employees/medium/amelie-morin-godard.png'),
            mask: require('../img/employees/masks/amelie-morin-godard.png'),
        },
        neckCenter: {
            x: 1383 / 3000,
            y: 1989 / 3000,
        },
        mouthCenter: {
            x: 440 / 1000,
            y: 523 / 1000,
        },
    },
    {
        id: 'andreanne-achim',
        name: 'Andréanne Achim',
        gender: 'female',
        head: {
            original: require('../img/employees/andreanne-achim.png'),
            small: require('../img/employees/small/andreanne-achim.png'),
            medium: require('../img/employees/medium/andreanne-achim.png'),
            mask: require('../img/employees/masks/andreanne-achim.png'),
        },
        neckCenter: {
            x: 3768 / 8192,
            y: 5600 / 8192,
        },
        mouthCenter: {
            x: 464 / 1000,
            y: 510 / 1000,
            deg: 6,
        },
    },
    {
        id: 'angela-castelblanco',
        name: 'Angela Castelblanco',
        gender: 'female',
        head: {
            original: require('../img/employees/angela-castelblanco.png'),
            small: require('../img/employees/small/angela-castelblanco.png'),
            medium: require('../img/employees/medium/angela-castelblanco.png'),
            mask: require('../img/employees/masks/angela-castelblanco.png'),
        },
        neckCenter: {
            x: 1201 / 2556,
            y: 1600 / 2556,
        },
        mouthCenter: {
            x: 484 / 1000,
            y: 510 / 1000,
        },
    },
    {
        id: 'annabel-dufour-brown',
        name: 'Annabel Dufour-Brown',
        gender: 'female',
        head: {
            original: require('../img/employees/annabel-dufour-brown.png'),
            small: require('../img/employees/small/annabel-dufour-brown.png'),
            medium: require('../img/employees/medium/annabel-dufour-brown.png'),
            mask: require('../img/employees/masks/annabel-dufour-brown.png'),
        },
        neckCenter: {
            x: 1565 / 3000,
            y: 1800 / 3000,
        },
        mouthCenter: {
            x: 498 / 1000,
            y: 460 / 1000,
            deg: -2,
        },
    },
    {
        id: 'anne-lyse-nivoix',
        name: 'Anne-Lyse Nivoix',
        gender: 'female',
        head: {
            original: require('../img/employees/anne-lyse-nivoix.png'),
            small: require('../img/employees/small/anne-lyse-nivoix.png'),
            medium: require('../img/employees/medium/anne-lyse-nivoix.png'),
            mask: require('../img/employees/masks/anne-lyse-nivoix.png'),
        },
        neckCenter: {
            x: 1300 / 3000,
            y: 1850 / 3000,
        },
        mouthCenter: {
            x: 444 / 1000,
            y: 423 / 1000,
        },
    },
    {
        id: 'antoine-gravet',
        name: 'Antoine Gravet',
        gender: 'male',
        head: {
            original: require('../img/employees/antoine-gravet.png'),
            small: require('../img/employees/small/antoine-gravet.png'),
            medium: require('../img/employees/medium/antoine-gravet.png'),
            mask: require('../img/employees/masks/antoine-gravet.png'),
        },
        neckCenter: {
            x: 1400 / 3000,
            y: 2050 / 3000,
        },
        mouthCenter: {
            x: 455 / 1000,
            y: 544 / 1000,
            deg: 4,
        },
    },
    {
        id: 'aurelie-bailliache',
        name: 'Aurélie Bailliache',
        gender: 'female',
        head: {
            original: require('../img/employees/aurelie-bailliache.png'),
            small: require('../img/employees/small/aurelie-bailliache.png'),
            medium: require('../img/employees/medium/aurelie-bailliache.png'),
            mask: require('../img/employees/masks/aurelie-bailliache.png'),
        },
        neckCenter: {
            x: 1451 / 3000,
            y: 2100 / 3000,
        },
        mouthCenter: {
            x: 508 / 1000,
            y: 520 / 1000,
        },
        voice: require('../audio/vocals/AurelieBailliache.mp3'),
    },
    {
        id: 'bruno-barbuio',
        name: 'Bruno Barbuio',
        gender: 'male',
        head: {
            original: require('../img/employees/bruno-barbuio.png'),
            small: require('../img/employees/small/bruno-barbuio.png'),
            medium: require('../img/employees/medium/bruno-barbuio.png'),
            mask: require('../img/employees/masks/bruno-barbuio.png'),
        },
        neckCenter: {
            x: 1727 / 3456,
            y: 2500 / 3456,
        },
        mouthCenter: {
            x: 532 / 1000,
            y: 533 / 1000,
        },
    },
    {
        id: 'camille-detouillon',
        name: 'Camille Detouillon',
        gender: 'female',
        head: {
            original: require('../img/employees/camille-detouillon.png'),
            small: require('../img/employees/small/camille-detouillon.png'),
            medium: require('../img/employees/medium/camille-detouillon.png'),
            mask: require('../img/employees/masks/camille-detouillon.png'),
        },
        neckCenter: {
            x: 1550 / 3164,
            y: 2300 / 3164,
        },
        mouthCenter: {
            x: 464 / 1000,
            y: 586 / 1000,
            deg: 4,
        },
    },
    {
        id: 'carla-valdes',
        name: 'Carla Valdes',
        gender: 'female',
        head: {
            original: require('../img/employees/carla-valdes.png'),
            small: require('../img/employees/small/carla-valdes.png'),
            medium: require('../img/employees/medium/carla-valdes.png'),
            mask: require('../img/employees/masks/carla-valdes.png'),
        },
        neckCenter: {
            x: 1364 / 3000,
            y: 1800 / 3000,
        },
        mouthCenter: {
            x: 460 / 1000,
            y: 470 / 1000,
            deg: -4,
        },
    },
    {
        id: 'caroline-boulard',
        name: 'Caroline Boulard',
        gender: 'female',
        head: {
            original: require('../img/employees/caroline-boulard.png'),
            small: require('../img/employees/small/caroline-boulard.png'),
            medium: require('../img/employees/medium/caroline-boulard.png'),
            mask: require('../img/employees/masks/caroline-boulard.png'),
        },
        neckCenter: {
            x: 1406 / 3000,
            y: 2100 / 3000,
        },
        mouthCenter: {
            x: 485 / 1000,
            y: 533 / 1000,
            deg: 2,
        },
    },
    {
        id: 'caroline-leprince',
        name: 'Caroline Leprince',
        gender: 'female',
        head: {
            original: require('../img/employees/caroline-leprince.png'),
            small: require('../img/employees/small/caroline-leprince.png'),
            medium: require('../img/employees/medium/caroline-leprince.png'),
            mask: require('../img/employees/masks/caroline-leprince.png'),
        },
        neckCenter: {
            x: 464 / 1000,
            y: 685 / 1000,
        },
        mouthCenter: {
            x: 457 / 1000,
            y: 523 / 1000,
        },
    },
    {
        id: 'catherine-gratton-gagne',
        name: 'Catherine Gratton-Gagné',
        gender: 'female',
        head: {
            original: require('../img/employees/catherine-gratton-gagne.png'),
            small: require('../img/employees/small/catherine-gratton-gagne.png'),
            medium: require('../img/employees/medium/catherine-gratton-gagne.png'),
            mask: require('../img/employees/masks/catherine-gratton-gagne.png'),
        },
        neckCenter: {
            x: 1092 / 2392,
            y: 1588 / 2392,
        },
        mouthCenter: {
            x: 448 / 1000,
            y: 504 / 1000,
        },
        voice: require('../audio/vocals/CatherineGrattonGagne.mp3'),
    },
    {
        id: 'catherine-larose',
        name: 'Catherine Larose',
        gender: 'female',
        head: {
            original: require('../img/employees/catherine-larose.png'),
            small: require('../img/employees/small/catherine-larose.png'),
            medium: require('../img/employees/medium/catherine-larose.png'),
            mask: require('../img/employees/masks/catherine-larose.png'),
        },
        neckCenter: {
            x: 1655 / 3456,
            y: 2527 / 3456,
        },
        mouthCenter: {
            x: 473 / 1000,
            y: 524 / 1000,
            deg: 3,
        },
        // voice: require('../audio/vocals/CatherineLarose.mp3'),
    },
    {
        id: 'charbel-farah',
        name: 'Charbel Farah',
        gender: 'male',
        head: {
            original: require('../img/employees/charbel-farah.png'),
            small: require('../img/employees/small/charbel-farah.png'),
            medium: require('../img/employees/medium/charbel-farah.png'),
            mask: require('../img/employees/masks/charbel-farah.png'),
        },
        neckCenter: {
            x: 1444 / 3000,
            y: 1900 / 3000,
        },
        mouthCenter: {
            x: 482 / 1000,
            y: 528 / 1000,
            deg: -2,
        },
        voice: require('../audio/vocals/CharbelFarah.mp3'),
    },
    {
        id: 'cherine-benyakhou',
        name: 'Cherine Benyakhou',
        gender: 'female',
        head: {
            original: require('../img/employees/cherine-benyakhou.png'),
            small: require('../img/employees/small/cherine-benyakhou.png'),
            medium: require('../img/employees/medium/cherine-benyakhou.png'),
            mask: require('../img/employees/masks/cherine-benyakhou.png'),
        },
        neckCenter: {
            x: 516 / 1000,
            y: 728 / 1000,
        },
        mouthCenter: {
            x: 472 / 1000,
            y: 585 / 1000,
            deg: -7,
        },
    },
    {
        id: 'claire-renaut',
        name: 'Claire Renaut',
        gender: 'female',
        head: {
            original: require('../img/employees/claire-renaut.png'),
            small: require('../img/employees/small/claire-renaut.png'),
            medium: require('../img/employees/medium/claire-renaut.png'),
            mask: require('../img/employees/masks/claire-renaut.png'),
        },
        neckCenter: {
            x: 1430 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 504 / 1000,
            y: 510 / 1000,
        },
    },
    {
        id: 'clement-hochedez',
        name: 'Clément Hochedez',
        gender: 'male',
        head: {
            original: require('../img/employees/clement-hochedez.png'),
            small: require('../img/employees/small/clement-hochedez.png'),
            medium: require('../img/employees/medium/clement-hochedez.png'),
            mask: require('../img/employees/masks/clement-hochedez.png'),
        },
        neckCenter: {
            x: 1500 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 485 / 1000,
            y: 536 / 1000,
        },
    },
    {
        id: 'delphine-chapard',
        name: 'Delphine Chapard',
        gender: 'female',
        head: {
            original: require('../img/employees/delphine-chapard.png'),
            small: require('../img/employees/small/delphine-chapard.png'),
            medium: require('../img/employees/medium/delphine-chapard.png'),
            mask: require('../img/employees/masks/delphine-chapard.png'),
        },
        neckCenter: {
            x: 1381 / 3340,
            y: 2200 / 3340,
        },
        mouthCenter: {
            x: 386 / 1000,
            y: 476 / 1000,
        },
    },
    {
        id: 'edouard-bourque-trudeau',
        name: 'Édouard Bourque-Trudeau',
        gender: 'male',
        head: {
            original: require('../img/employees/edouard-bourque-trudeau.png'),
            small: require('../img/employees/small/edouard-bourque-trudeau.png'),
            medium: require('../img/employees/medium/edouard-bourque-trudeau.png'),
            mask: require('../img/employees/masks/edouard-bourque-trudeau.png'),
        },
        neckCenter: {
            x: 1600 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 454 / 1000,
            y: 555 / 1000,
        },
    },
    {
        id: 'elizabeth-henry',
        name: 'Elizabeth Henry',
        gender: 'female',
        head: {
            original: require('../img/employees/elizabeth-henry.png'),
            small: require('../img/employees/small/elizabeth-henry.png'),
            medium: require('../img/employees/medium/elizabeth-henry.png'),
            mask: require('../img/employees/masks/elizabeth-henry.png'),
        },
        neckCenter: {
            x: 458 / 1000,
            y: 700 / 1000,
        },
        mouthCenter: {
            x: 457 / 1000,
            y: 520 / 1000,
        },
    },
    {
        id: 'emile-chehab',
        name: 'Émile Chehab',
        gender: 'male',
        head: {
            original: require('../img/employees/emile-chehab.png'),
            small: require('../img/employees/small/emile-chehab.png'),
            medium: require('../img/employees/medium/emile-chehab.png'),
            mask: require('../img/employees/masks/emile-chehab.png'),
        },
        neckCenter: {
            x: 1430 / 3000,
            y: 2150 / 3000,
        },
        mouthCenter: {
            x: 470 / 1000,
            y: 572 / 1000,
        },
        voice: require('../audio/vocals/EmileChehab.mp3'),
    },
    {
        id: 'emilie-barthe',
        name: 'Emilie Barthe',
        gender: 'female',
        head: {
            original: require('../img/employees/emilie-barthe.png'),
            small: require('../img/employees/small/emilie-barthe.png'),
            medium: require('../img/employees/medium/emilie-barthe.png'),
            mask: require('../img/employees/masks/emilie-barthe.png'),
        },
        neckCenter: {
            x: 1362 / 3004,
            y: 1990 / 3004,
        },
        mouthCenter: {
            x: 445 / 1000,
            y: 530 / 1000,
        },
    },
    {
        id: 'eva-morin',
        name: 'Eva Morin',
        gender: 'female',
        head: {
            original: require('../img/employees/eva-morin.png'),
            small: require('../img/employees/small/eva-morin.png'),
            medium: require('../img/employees/medium/eva-morin.png'),
            mask: require('../img/employees/masks/eva-morin.png'),
        },
        neckCenter: {
            x: 1382 / 3000,
            y: 2075 / 3000,
        },
        mouthCenter: {
            x: 478 / 1000,
            y: 468 / 1000,
        },
    },
    {
        id: 'fares-aldik',
        name: 'Fares Aldik',
        gender: 'male',
        head: {
            original: require('../img/employees/fares-aldik.png'),
            small: require('../img/employees/small/fares-aldik.png'),
            medium: require('../img/employees/medium/fares-aldik.png'),
            mask: require('../img/employees/masks/fares-aldik.png'),
        },
        neckCenter: {
            x: 1144 / 2400,
            y: 1650 / 2400,
        },
        mouthCenter: {
            x: 475 / 1000,
            y: 550 / 1000,
        },
    },
    {
        id: 'francis-devoy',
        name: 'Francis Devoy',
        gender: 'male',
        head: {
            original: require('../img/employees/francis-devoy.png'),
            small: require('../img/employees/small/francis-devoy.png'),
            medium: require('../img/employees/medium/francis-devoy.png'),
            mask: require('../img/employees/masks/francis-devoy.png'),
        },
        neckCenter: {
            x: 1385 / 3000,
            y: 2237 / 3000,
        },
        mouthCenter: {
            x: 462 / 1000,
            y: 581 / 1000,
        },
        voice: require('../audio/vocals/FrancisDevoyKevinParent.mp3'),
    },
    {
        id: 'francois-mouchet',
        name: 'François Mouchet',
        gender: 'male',
        head: {
            original: require('../img/employees/francois-mouchet.png'),
            small: require('../img/employees/small/francois-mouchet.png'),
            medium: require('../img/employees/medium/francois-mouchet.png'),
            mask: require('../img/employees/masks/francois-mouchet.png'),
        },
        neckCenter: {
            x: 1330 / 3000,
            y: 2050 / 3000,
        },
        mouthCenter: {
            x: 446 / 1000,
            y: 513 / 1000,
            deg: 6,
        },
        voice: require('../audio/vocals/Francois_Mouchet.mp3'),
    },
    {
        id: 'frederic-proulx',
        name: 'Frédéric Proulx',
        gender: 'male',
        head: {
            original: require('../img/employees/frederic-proulx.png'),
            small: require('../img/employees/small/frederic-proulx.png'),
            medium: require('../img/employees/medium/frederic-proulx.png'),
            mask: require('../img/employees/masks/frederic-proulx.png'),
        },
        neckCenter: {
            x: 1445 / 3000,
            y: 2050 / 3000,
        },
        mouthCenter: {
            x: 482 / 1000,
            y: 500 / 1000,
        },
    },
    {
        id: 'guillaume-boudreault-monty',
        name: 'Guillaume Boudreault-Monty',
        gender: 'male',
        head: {
            original: require('../img/employees/guillaume-boudreault-monty.png'),
            small: require('../img/employees/small/guillaume-boudreault-monty.png'),
            medium: require('../img/employees/medium/guillaume-boudreault-monty.png'),
            mask: require('../img/employees/masks/guillaume-boudreault-monty.png'),
        },
        neckCenter: {
            x: 1373 / 3156,
            y: 2050 / 3156,
        },
        mouthCenter: {
            x: 422 / 1000,
            y: 554 / 1000,
        },
        voice: require('../audio/vocals/GuillaumeBoudreauMonty.mp3'),
    },
    {
        id: 'gwendolyn-ung',
        name: 'Gwendolyn Ung',
        gender: 'female',
        head: {
            original: require('../img/employees/gwendolyn-ung.png'),
            small: require('../img/employees/small/gwendolyn-ung.png'),
            medium: require('../img/employees/medium/gwendolyn-ung.png'),
            mask: require('../img/employees/masks/gwendolyn-ung.png'),
        },
        neckCenter: {
            x: 1486 / 3000,
            y: 2101 / 3000,
        },
        mouthCenter: {
            x: 478 / 1000,
            y: 504 / 1000,
        },
    },
    {
        id: 'james-forbes',
        name: 'James Forbes',
        gender: 'male',
        head: {
            original: require('../img/employees/james-forbes.png'),
            small: require('../img/employees/small/james-forbes.png'),
            medium: require('../img/employees/medium/james-forbes.png'),
            mask: require('../img/employees/masks/james-forbes.png'),
        },
        neckCenter: {
            x: 1411 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 468 / 1000,
            y: 514 / 1000,
        },
    },
    {
        id: 'jean-christophe-bouchot',
        name: 'Jean-Christophe Bouchot',
        gender: 'male',
        head: {
            original: require('../img/employees/jean-christophe-bouchot.png'),
            small: require('../img/employees/small/jean-christophe-bouchot.png'),
            medium: require('../img/employees/medium/jean-christophe-bouchot.png'),
            mask: require('../img/employees/masks/jean-christophe-bouchot.png'),
        },
        neckCenter: {
            x: 1490 / 3000,
            y: 2050 / 3000,
        },
        mouthCenter: {
            x: 460 / 1000,
            y: 506 / 1000,
        },
        voice: require('../audio/vocals/Jean-ChristopheBouchot.mp3'),
    },
    {
        id: 'jean-christophe-mauss',
        name: 'Jean-Christophe Mauss',
        gender: 'male',
        head: {
            original: require('../img/employees/jean-christophe-mauss.png'),
            small: require('../img/employees/small/jean-christophe-mauss.png'),
            medium: require('../img/employees/medium/jean-christophe-mauss.png'),
            mask: require('../img/employees/masks/jean-christophe-mauss.png'),
        },
        neckCenter: {
            x: 1600 / 3456,
            y: 2452 / 3456,
        },
        mouthCenter: {
            x: 450 / 1000,
            y: 496 / 1000,
        },
    },
    {
        id: 'jean-francois-renaud',
        name: 'Jean-François Renaud',
        gender: 'male',
        head: {
            original: require('../img/employees/jean-francois-renaud.png'),
            small: require('../img/employees/small/jean-francois-renaud.png'),
            medium: require('../img/employees/medium/jean-francois-renaud.png'),
            mask: require('../img/employees/masks/jean-francois-renaud.png'),
        },
        neckCenter: {
            x: 475 / 1000,
            y: 690 / 1000,
        },
        mouthCenter: {
            x: 448 / 1000,
            y: 564 / 1000,
            deg: -3,
        },
        voice: require('../audio/vocals/Jean-FrancoisRenaud.mp3'),
    },
    {
        id: 'jean-michel-dube',
        name: 'Jean-Michel Dubé',
        gender: 'male',
        head: {
            original: require('../img/employees/jean-michel-dube.png'),
            small: require('../img/employees/small/jean-michel-dube.png'),
            medium: require('../img/employees/medium/jean-michel-dube.png'),
            mask: require('../img/employees/masks/jean-michel-dube.png'),
        },
        neckCenter: {
            x: 1321 / 2560,
            y: 1900 / 2560,
        },
        mouthCenter: {
            x: 510 / 1000,
            y: 560 / 1000,
        },
        voice: require('../audio/vocals/Jean-MichelDube.mp3'),
    },
    {
        id: 'jean-philippe-provost',
        name: 'Jean-Philippe Provost',
        gender: 'male',
        head: {
            original: require('../img/employees/jean-philippe-provost.png'),
            small: require('../img/employees/small/jean-philippe-provost.png'),
            medium: require('../img/employees/medium/jean-philippe-provost.png'),
            mask: require('../img/employees/masks/jean-philippe-provost.png'),
        },
        neckCenter: {
            x: 1426 / 3000,
            y: 2200 / 3000,
        },
        mouthCenter: {
            x: 474 / 1000,
            y: 635 / 1000,
        },
    },
    {
        id: 'jeremie-levy',
        name: 'Jérémie Levi',
        gender: 'male',
        head: {
            original: require('../img/employees/jeremie-levy.png'),
            small: require('../img/employees/small/jeremie-levy.png'),
            medium: require('../img/employees/medium/jeremie-levy.png'),
            mask: require('../img/employees/masks/jeremie-levy.png'),
        },
        neckCenter: {
            x: 1427 / 3000,
            y: 2150 / 3000,
        },
        mouthCenter: {
            x: 510 / 1000,
            y: 542 / 1000,
        },
    },
    {
        id: 'jessica-verville',
        name: 'Jessica Verville',
        gender: 'female',
        head: {
            original: require('../img/employees/jessica-verville.png'),
            small: require('../img/employees/small/jessica-verville.png'),
            medium: require('../img/employees/medium/jessica-verville.png'),
            mask: require('../img/employees/masks/jessica-verville.png'),
        },
        neckCenter: {
            x: 1502 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 483 / 1000,
            y: 479 / 1000,
        },
    },
    {
        id: 'jonathan-lemay',
        name: 'Jonathan Lemay',
        gender: 'male',
        head: {
            original: require('../img/employees/jonathan-lemay.png'),
            small: require('../img/employees/small/jonathan-lemay.png'),
            medium: require('../img/employees/medium/jonathan-lemay.png'),
            mask: require('../img/employees/masks/jonathan-lemay.png'),
        },
        neckCenter: {
            x: 1618 / 3000,
            y: 2200 / 3000,
        },
        mouthCenter: {
            x: 552 / 1000,
            y: 592 / 1000,
        },
    },
    {
        id: 'jonathan-perez-lara',
        name: 'Jonathan Perez-Lara',
        gender: 'male',
        head: {
            original: require('../img/employees/jonathan-perez-lara.png'),
            small: require('../img/employees/small/jonathan-perez-lara.png'),
            medium: require('../img/employees/medium/jonathan-perez-lara.png'),
            mask: require('../img/employees/masks/jonathan-perez-lara.png'),
        },
        neckCenter: {
            x: 1642 / 3456,
            y: 2650 / 3456,
        },
        mouthCenter: {
            x: 448 / 1000,
            y: 636 / 1000,
        },
    },
    {
        id: 'julian-naranjo',
        name: 'Julian Naranjo',
        gender: 'male',
        head: {
            original: require('../img/employees/julian-naranjo.png'),
            small: require('../img/employees/small/julian-naranjo.png'),
            medium: require('../img/employees/medium/julian-naranjo.png'),
            mask: require('../img/employees/masks/julian-naranjo.png'),
        },
        neckCenter: {
            x: 1436 / 3000,
            y: 1883 / 3000,
        },
        mouthCenter: {
            x: 461 / 1000,
            y: 480 / 1000,
        },
    },
    {
        id: 'julie-riviere',
        name: 'Julie Rivière',
        gender: 'female',
        head: {
            original: require('../img/employees/julie-riviere.png'),
            small: require('../img/employees/small/julie-riviere.png'),
            medium: require('../img/employees/medium/julie-riviere.png'),
            mask: require('../img/employees/masks/julie-riviere.png'),
        },
        neckCenter: {
            x: 1522 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 500 / 1000,
            y: 505 / 1000,
            deg: 1,
        },
        voice: require('../audio/vocals/JulieRiviere.mp3'),
    },
    {
        id: 'kristel-salesse',
        name: 'Kristel Salesse',
        gender: 'female',
        head: {
            original: require('../img/employees/kristel-salesse.png'),
            small: require('../img/employees/small/kristel-salesse.png'),
            medium: require('../img/employees/medium/kristel-salesse.png'),
            mask: require('../img/employees/masks/kristel-salesse.png'),
        },
        neckCenter: {
            x: 1528 / 3000,
            y: 1850 / 3000,
        },
        mouthCenter: {
            x: 481 / 1000,
            y: 462 / 1000,
        },
    },
    {
        id: 'laurence-pressault',
        name: 'Laurence Pressault',
        gender: 'female',
        head: {
            original: require('../img/employees/laurence-pressault.png'),
            small: require('../img/employees/small/laurence-pressault.png'),
            medium: require('../img/employees/medium/laurence-pressault.png'),
            mask: require('../img/employees/masks/laurence-pressault.png'),
        },
        neckCenter: {
            x: 1563 / 3000,
            y: 2313 / 3000,
        },
        mouthCenter: {
            x: 503 / 1000,
            y: 589 / 1000,
        },
        voice: require('../audio/vocals/LaurencePressault.mp3'),
    },
    {
        id: 'louis-theoret',
        name: 'Louis Théorêt',
        gender: 'male',
        head: {
            original: require('../img/employees/louis-theoret.png'),
            small: require('../img/employees/small/louis-theoret.png'),
            medium: require('../img/employees/medium/louis-theoret.png'),
            mask: require('../img/employees/masks/louis-theoret.png'),
        },
        neckCenter: {
            x: 1325 / 3000,
            y: 2200 / 3000,
        },
        mouthCenter: {
            x: 437 / 1000,
            y: 585 / 1000,
            deg: 4,
        },
    },
    {
        id: 'louis-philippe-mathieu',
        name: 'Louis-Philippe Mathieu',
        gender: 'male',
        head: {
            original: require('../img/employees/louis-philippe-mathieu.png'),
            small: require('../img/employees/small/louis-philippe-mathieu.png'),
            medium: require('../img/employees/medium/louis-philippe-mathieu.png'),
            mask: require('../img/employees/masks/louis-philippe-mathieu.png'),
        },
        neckCenter: {
            x: 1650 / 3456,
            y: 2600 / 3456,
        },
        mouthCenter: {
            x: 474 / 1000,
            y: 652 / 1000,
        },
    },
    {
        id: 'marc-andre-lebail',
        name: 'Marc-André Le Bail',
        gender: 'male',
        head: {
            original: require('../img/employees/marc-andre-lebail.png'),
            small: require('../img/employees/small/marc-andre-lebail.png'),
            medium: require('../img/employees/medium/marc-andre-lebail.png'),
            mask: require('../img/employees/masks/marc-andre-lebail.png'),
        },
        neckCenter: {
            x: 1447 / 3000,
            y: 2150 / 3000,
        },
        mouthCenter: {
            x: 494 / 1000,
            y: 527 / 1000,
        },
        voice: require('../audio/vocals/MarcAndreLeBail.mp3'),
    },
    {
        id: 'marie-nicollet',
        name: 'Marie Nicollet',
        gender: 'female',
        head: {
            original: require('../img/employees/marie-nicollet.png'),
            small: require('../img/employees/small/marie-nicollet.png'),
            medium: require('../img/employees/medium/marie-nicollet.png'),
            mask: require('../img/employees/masks/marie-nicollet.png'),
        },
        neckCenter: {
            x: 1307 / 3000,
            y: 2100 / 3000,
        },
        mouthCenter: {
            x: 492 / 1000,
            y: 607 / 1000,
            deg: 6,
        },
    },
    {
        id: 'marie-camille-yao',
        name: 'Marie-Camille Yao',
        gender: 'female',
        head: {
            original: require('../img/employees/marie-camille-yao.png'),
            small: require('../img/employees/small/marie-camille-yao.png'),
            medium: require('../img/employees/medium/marie-camille-yao.png'),
            mask: require('../img/employees/masks/marie-camille-yao.png'),
        },
        neckCenter: {
            x: 1494 / 3000,
            y: 1880 / 3000,
        },
        mouthCenter: {
            x: 518 / 1000,
            y: 480 / 1000,
            deg: 2,
        },
        voice: require('../audio/vocals/MarieCamilleYao.mp3'),
    },
    {
        id: 'marie-pierre-paradis-claes',
        name: 'Marie-Pierre Paradis-Claes',
        gender: 'female',
        head: {
            original: require('../img/employees/marie-pierre-paradis-claes.png'),
            small: require('../img/employees/small/marie-pierre-paradis-claes.png'),
            medium: require('../img/employees/medium/marie-pierre-paradis-claes.png'),
            mask: require('../img/employees/masks/marie-pierre-paradis-claes.png'),
        },
        neckCenter: {
            x: 1425 / 3116,
            y: 2167 / 3116,
        },
        mouthCenter: {
            x: 465 / 1000,
            y: 470 / 1000,
        },
        voice: require('../audio/vocals/MariePierreParadisClaes.mp3'),
    },
    {
        id: 'marion-dewaele',
        name: 'Marion Dewaele',
        gender: 'female',
        head: {
            original: require('../img/employees/marion-dewaele.png'),
            small: require('../img/employees/small/marion-dewaele.png'),
            medium: require('../img/employees/medium/marion-dewaele.png'),
            mask: require('../img/employees/masks/marion-dewaele.png'),
        },
        neckCenter: {
            x: 1494 / 3308,
            y: 2200 / 3308,
        },
        mouthCenter: {
            x: 461 / 1000,
            y: 548 / 1000,
        },
    },
    {
        id: 'marion-peres',
        name: 'Marion Peres',
        gender: 'female',
        head: {
            original: require('../img/employees/marion-peres.png'),
            small: require('../img/employees/small/marion-peres.png'),
            medium: require('../img/employees/medium/marion-peres.png'),
            mask: require('../img/employees/masks/marion-peres.png'),
        },
        neckCenter: {
            x: 909 / 2000,
            y: 1400 / 2000,
        },
        mouthCenter: {
            x: 427 / 1000,
            y: 453 / 1000,
            deg: 6,
        },
    },
    {
        id: 'martin-champagne',
        name: 'Martin Champagne',
        gender: 'male',
        head: {
            original: require('../img/employees/martin-champagne.png'),
            small: require('../img/employees/small/martin-champagne.png'),
            medium: require('../img/employees/medium/martin-champagne.png'),
            mask: require('../img/employees/masks/martin-champagne.png'),
        },
        neckCenter: {
            x: 1442 / 3000,
            y: 2300 / 3000,
        },
        mouthCenter: {
            x: 443 / 1000,
            y: 642 / 1000,
        },
    },
    {
        id: 'mathieu-zancan',
        name: 'Mathieu Zancan',
        gender: 'male',
        head: {
            original: require('../img/employees/mathieu-zancan.png'),
            small: require('../img/employees/small/mathieu-zancan.png'),
            medium: require('../img/employees/medium/mathieu-zancan.png'),
            mask: require('../img/employees/masks/mathieu-zancan.png'),
        },
        neckCenter: {
            x: 1500 / 3000,
            y: 1900 / 3000,
        },
        mouthCenter: {
            x: 450 / 1000,
            y: 520 / 1000,
        },
    },
    {
        id: 'matthieu-morelle',
        name: 'Matthieu Morelle',
        gender: 'male',
        head: {
            original: require('../img/employees/matthieu-morelle.png'),
            small: require('../img/employees/small/matthieu-morelle.png'),
            medium: require('../img/employees/medium/matthieu-morelle.png'),
            mask: require('../img/employees/masks/matthieu-morelle.png'),
        },
        neckCenter: {
            x: 1448 / 3000,
            y: 2402 / 3000,
        },
        mouthCenter: {
            x: 470 / 1000,
            y: 530 / 1000,
        },
    },
    {
        id: 'maxime-louison',
        name: 'Maxime Louison',
        gender: 'male',
        head: {
            original: require('../img/employees/maxime-louison.png'),
            small: require('../img/employees/small/maxime-louison.png'),
            medium: require('../img/employees/medium/maxime-louison.png'),
            mask: require('../img/employees/masks/maxime-louison.png'),
        },
        neckCenter: {
            x: 1435 / 3000,
            y: 2142 / 3000,
        },
        mouthCenter: {
            x: 482 / 1000,
            y: 522 / 1000,
        },
    },
    {
        id: 'meryem-abou-el-bal',
        name: 'Meryem Abou El Bal',
        gender: 'female',
        head: {
            original: require('../img/employees/meryem-abou-el-bal.png'),
            small: require('../img/employees/small/meryem-abou-el-bal.png'),
            medium: require('../img/employees/medium/meryem-abou-el-bal.png'),
            mask: require('../img/employees/masks/meryem-abou-el-bal.png'),
        },
        neckCenter: {
            x: 1444 / 3000,
            y: 2100 / 3000,
        },
        mouthCenter: {
            x: 475 / 1000,
            y: 540 / 1000,
            deg: -1,
        },
        voice: require('../audio/vocals/MeryemAbouElBal.mp3'),
    },
    {
        id: 'mohammed-mekouar',
        name: 'Mohammed Mekouar',
        gender: 'male',
        head: {
            original: require('../img/employees/mohammed-mekouar.png'),
            small: require('../img/employees/small/mohammed-mekouar.png'),
            medium: require('../img/employees/medium/mohammed-mekouar.png'),
            mask: require('../img/employees/masks/mohammed-mekouar.png'),
        },
        neckCenter: {
            x: 1442 / 3000,
            y: 2200 / 3000,
        },
        mouthCenter: {
            x: 474 / 1000,
            y: 610 / 1000,
        },
    },
    {
        id: 'moulaye-traore',
        name: 'Moulaye Traore',
        gender: 'male',
        head: {
            original: require('../img/employees/moulaye-traore.png'),
            small: require('../img/employees/small/moulaye-traore.png'),
            medium: require('../img/employees/medium/moulaye-traore.png'),
            mask: require('../img/employees/masks/moulaye-traore.png'),
        },
        neckCenter: {
            x: 1478 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 493 / 1000,
            y: 529 / 1000,
        },
    },
    {
        id: 'mylene-paquette',
        name: 'Mylène Paquette',
        gender: 'female',
        head: {
            original: require('../img/employees/mylene-paquette.png'),
            small: require('../img/employees/small/mylene-paquette.png'),
            medium: require('../img/employees/medium/mylene-paquette.png'),
            mask: require('../img/employees/masks/mylene-paquette.png'),
        },
        neckCenter: {
            x: 1389 / 3000,
            y: 2021 / 3000,
        },
        mouthCenter: {
            x: 470 / 1000,
            y: 402 / 1000,
        },
        voice: require('../audio/vocals/MylenePaquette.mp3'),
    },
    {
        id: 'myriam-loranger',
        name: 'Myriam Loranger',
        gender: 'female',
        head: {
            original: require('../img/employees/myriam-loranger.png'),
            small: require('../img/employees/small/myriam-loranger.png'),
            medium: require('../img/employees/medium/myriam-loranger.png'),
            mask: require('../img/employees/masks/myriam-loranger.png'),
        },
        neckCenter: {
            x: 1407 / 3000,
            y: 1900 / 3000,
        },
        mouthCenter: {
            x: 470 / 1000,
            y: 505 / 1000,
            deg: 3,
        },
    },
    {
        id: 'nicolas-scott',
        name: 'Nicolas Scott',
        gender: 'male',
        head: {
            original: require('../img/employees/nicolas-scott.png'),
            small: require('../img/employees/small/nicolas-scott.png'),
            medium: require('../img/employees/medium/nicolas-scott.png'),
            mask: require('../img/employees/masks/nicolas-scott.png'),
        },
        neckCenter: {
            x: 1214 / 3000,
            y: 2050 / 3000,
        },
        mouthCenter: {
            x: 400 / 1000,
            y: 496 / 1000,
        },
    },
    {
        id: 'nicolas-villeneuve',
        name: 'Nicolas Villeneuve',
        gender: 'male',
        head: {
            original: require('../img/employees/nicolas-villeneuve.png'),
            small: require('../img/employees/small/nicolas-villeneuve.png'),
            medium: require('../img/employees/medium/nicolas-villeneuve.png'),
            mask: require('../img/employees/masks/nicolas-villeneuve.png'),
        },
        neckCenter: {
            x: 495 / 1000,
            y: 733 / 1000,
        },
        mouthCenter: {
            x: 486 / 1000,
            y: 546 / 1000,
        },
    },
    {
        id: 'nicolas-duval',
        name: 'Nicolas Duval',
        gender: 'male',
        head: {
            original: require('../img/employees/nicolas-duval.png'),
            small: require('../img/employees/small/nicolas-duval.png'),
            medium: require('../img/employees/medium/nicolas-duval.png'),
            mask: require('../img/employees/masks/nicolas-duval.png'),
        },
        neckCenter: {
            x: 1562 / 3000,
            y: 2300 / 3000,
        },
        mouthCenter: {
            x: 510 / 1000,
            y: 588 / 1000,
        },
    },
    {
        id: 'olivier-houle',
        name: 'Olivier Houle',
        gender: 'male',
        head: {
            original: require('../img/employees/olivier-houle.png'),
            small: require('../img/employees/small/olivier-houle.png'),
            medium: require('../img/employees/medium/olivier-houle.png'),
            mask: require('../img/employees/masks/olivier-houle.png'),
        },
        neckCenter: {
            x: 1568 / 3456,
            y: 2400 / 3456,
        },
        mouthCenter: {
            x: 437 / 1000,
            y: 548 / 1000,
            deg: 2,
        },
    },
    {
        id: 'pascal-philippe-bergeron',
        name: 'Pascal-Philippe Bergeron',
        gender: 'male',
        head: {
            original: require('../img/employees/pascal-philippe-bergeron.png'),
            small: require('../img/employees/small/pascal-philippe-bergeron.png'),
            medium: require('../img/employees/medium/pascal-philippe-bergeron.png'),
            mask: require('../img/employees/masks/pascal-philippe-bergeron.png'),
        },
        neckCenter: {
            x: 1059 / 2452,
            y: 1450 / 2452,
        },
        mouthCenter: {
            x: 410 / 1000,
            y: 465 / 1000,
        },
    },
    {
        id: 'pascale-anne-giguere',
        name: 'Pascale-Anne Giguère',
        gender: 'female',
        head: {
            original: require('../img/employees/pascale-anne-giguere.png'),
            small: require('../img/employees/small/pascale-anne-giguere.png'),
            medium: require('../img/employees/medium/pascale-anne-giguere.png'),
            mask: require('../img/employees/masks/pascale-anne-giguere.png'),
        },
        neckCenter: {
            x: 1535 / 3000,
            y: 2232 / 3000,
        },
        mouthCenter: {
            x: 476 / 1000,
            y: 537 / 1000,
        },
    },
    {
        id: 'philippe-boutin-lizotte',
        name: 'Philippe Boutin-Lizotte',
        gender: 'male',
        head: {
            original: require('../img/employees/philippe-boutin-lizotte.png'),
            small: require('../img/employees/small/philippe-boutin-lizotte.png'),
            medium: require('../img/employees/medium/philippe-boutin-lizotte.png'),
            mask: require('../img/employees/masks/philippe-boutin-lizotte.png'),
        },
        neckCenter: {
            x: 900 / 2000,
            y: 1250 / 2000,
        },
        mouthCenter: {
            x: 400 / 1000,
            y: 428 / 1000,
            deg: 7,
        },
    },
    {
        id: 'pierre-olivier-boucher',
        name: 'Pierre-Olivier Boucher',
        gender: 'male',
        head: {
            original: require('../img/employees/pierre-olivier-boucher.png'),
            small: require('../img/employees/small/pierre-olivier-boucher.png'),
            medium: require('../img/employees/medium/pierre-olivier-boucher.png'),
            mask: require('../img/employees/masks/pierre-olivier-boucher.png'),
        },
        neckCenter: {
            x: 1050 / 2400,
            y: 1650 / 2400,
        },
        mouthCenter: {
            x: 369 / 1000,
            y: 555 / 1000,
            deg: -5,
        },
    },
    {
        id: 'pierrick-viet',
        name: 'Pierrick Viet',
        gender: 'male',
        head: {
            original: require('../img/employees/pierrick-viet.png'),
            small: require('../img/employees/small/pierrick-viet.png'),
            medium: require('../img/employees/medium/pierrick-viet.png'),
            mask: require('../img/employees/masks/pierrick-viet.png'),
        },
        neckCenter: {
            x: 1417 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 467 / 1000,
            y: 527 / 1000,
        },
    },
    {
        id: 'rachel-cote',
        name: 'Rachel Côté',
        gender: 'female',
        head: {
            original: require('../img/employees/rachel-cote.png'),
            small: require('../img/employees/small/rachel-cote.png'),
            medium: require('../img/employees/medium/rachel-cote.png'),
            mask: require('../img/employees/masks/rachel-cote.png'),
        },
        neckCenter: {
            x: 1358 / 2928,
            y: 1721 / 2928,
        },
        mouthCenter: {
            x: 515 / 1000,
            y: 375 / 1000,
        },
        voice: require('../audio/vocals/RachelCote.mp3'),
    },
    {
        id: 'roger-kamena',
        name: 'Roger Kamena',
        gender: 'male',
        head: {
            original: require('../img/employees/roger-kamena.png'),
            small: require('../img/employees/small/roger-kamena.png'),
            medium: require('../img/employees/medium/roger-kamena.png'),
            mask: require('../img/employees/masks/roger-kamena.png'),
        },
        neckCenter: {
            x: 368 / 800,
            y: 600 / 800,
        },
        mouthCenter: {
            x: 357 / 800,
            y: 452 / 800,
            deg: 2,
        },
    },
    {
        id: 'roxanne-synette',
        name: 'Roxanne Synette',
        gender: 'female',
        head: {
            original: require('../img/employees/roxanne-synette.png'),
            small: require('../img/employees/small/roxanne-synette.png'),
            medium: require('../img/employees/medium/roxanne-synette.png'),
            mask: require('../img/employees/masks/roxanne-synette.png'),
        },
        neckCenter: {
            x: 1560 / 3000,
            y: 2159 / 3000,
        },
        mouthCenter: {
            x: 467 / 1000,
            y: 504 / 1000,
        },
    },
    {
        id: 'sami-degachi',
        name: 'Sami Degachi',
        gender: 'male',
        head: {
            original: require('../img/employees/sami-degachi.png'),
            small: require('../img/employees/small/sami-degachi.png'),
            medium: require('../img/employees/medium/sami-degachi.png'),
            mask: require('../img/employees/masks/sami-degachi.png'),
        },
        neckCenter: {
            x: 1630 / 3456,
            y: 2400 / 3456,
        },
        mouthCenter: {
            x: 450 / 1000,
            y: 533 / 1000,
            deg: -1,
        },
    },
    {
        id: 'sandra-ben-yedder',
        name: 'Sandra Ben-Yedder',
        gender: 'female',
        head: {
            original: require('../img/employees/sandra-ben-yedder.png'),
            small: require('../img/employees/small/sandra-ben-yedder.png'),
            medium: require('../img/employees/medium/sandra-ben-yedder.png'),
            mask: require('../img/employees/masks/sandra-ben-yedder.png'),
        },
        neckCenter: {
            x: 1487 / 3000,
            y: 2195 / 3000,
        },
        mouthCenter: {
            x: 464 / 1000,
            y: 550 / 1000,
            deg: 6,
        },
    },
    {
        id: 'sebastien-tremblay',
        name: 'Sébastien Tremblay',
        gender: 'male',
        head: {
            original: require('../img/employees/sebastien-tremblay.png'),
            small: require('../img/employees/small/sebastien-tremblay.png'),
            medium: require('../img/employees/medium/sebastien-tremblay.png'),
            mask: require('../img/employees/masks/sebastien-tremblay.png'),
        },
        neckCenter: {
            x: 1600 / 3456,
            y: 2600 / 3456,
        },
        mouthCenter: {
            x: 470 / 1000,
            y: 599 / 1000,
            deg: 3,
        },
    },
    {
        id: 'sebastien-neveu',
        name: 'Sébastien Neveu',
        gender: 'male',
        head: {
            original: require('../img/employees/sebastien-neveu.png'),
            small: require('../img/employees/small/sebastien-neveu.png'),
            medium: require('../img/employees/medium/sebastien-neveu.png'),
            mask: require('../img/employees/masks/sebastien-neveu.png'),
        },
        neckCenter: {
            x: 1351 / 3000,
            y: 2200 / 3000,
        },
        mouthCenter: {
            x: 470 / 1000,
            y: 547 / 1000,
        },
    },
    {
        id: 'simon-ethier',
        name: 'Simon Éthier',
        gender: 'male',
        head: {
            original: require('../img/employees/simon-ethier.png'),
            small: require('../img/employees/small/simon-ethier.png'),
            medium: require('../img/employees/medium/simon-ethier.png'),
            mask: require('../img/employees/masks/simon-ethier.png'),
        },
        neckCenter: {
            x: 512 / 1000,
            y: 744 / 1000,
        },
        mouthCenter: {
            x: 472 / 1000,
            y: 571 / 1000,
            deg: -4,
        },
        voice: require('../audio/vocals/SimonEthier.mp3'),
    },
    {
        id: 'simon-lahaie',
        name: 'Simon Lahaie',
        gender: 'male',
        head: {
            original: require('../img/employees/simon-lahaie.png'),
            small: require('../img/employees/small/simon-lahaie.png'),
            medium: require('../img/employees/medium/simon-lahaie.png'),
            mask: require('../img/employees/masks/simon-lahaie.png'),
        },
        neckCenter: {
            x: 1476 / 3000,
            y: 2100 / 3000,
        },
        mouthCenter: {
            x: 488 / 1000,
            y: 545 / 1000,
        },
    },
    {
        id: 'simon-lamarche',
        name: 'Simon Lamarche',
        gender: 'male',
        head: {
            original: require('../img/employees/simon-lamarche.png'),
            small: require('../img/employees/small/simon-lamarche.png'),
            medium: require('../img/employees/medium/simon-lamarche.png'),
            mask: require('../img/employees/masks/simon-lamarche.png'),
        },
        neckCenter: {
            x: 480 / 1000,
            y: 720 / 1000,
        },
        mouthCenter: {
            x: 525 / 1000,
            y: 595 / 1000,
        },
    },
    {
        id: 'simon-pierre-scantland',
        name: 'Simon-Pierre Scantland',
        gender: 'male',
        head: {
            original: require('../img/employees/simon-pierre-scantland.png'),
            small: require('../img/employees/small/simon-pierre-scantland.png'),
            medium: require('../img/employees/medium/simon-pierre-scantland.png'),
            mask: require('../img/employees/masks/simon-pierre-scantland.png'),
        },
        neckCenter: {
            x: 1542 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 483 / 1000,
            y: 544 / 1000,
        },
    },
    {
        id: 'sonia-allard',
        name: 'Sonia Allard',
        gender: 'female',
        head: {
            original: require('../img/employees/sonia-allard.png'),
            small: require('../img/employees/small/sonia-allard.png'),
            medium: require('../img/employees/medium/sonia-allard.png'),
            mask: require('../img/employees/masks/sonia-allard.png'),
        },
        neckCenter: {
            x: 1481 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 445 / 1000,
            y: 564 / 1000,
            deg: 4,
        },
    },
    {
        id: 'stephanie-bouchard',
        name: 'Stéphanie Bouchard',
        gender: 'female',
        head: {
            original: require('../img/employees/stephanie-bouchard.png'),
            small: require('../img/employees/small/stephanie-bouchard.png'),
            medium: require('../img/employees/medium/stephanie-bouchard.png'),
            mask: require('../img/employees/masks/stephanie-bouchard.png'),
        },
        neckCenter: {
            x: 1423 / 3000,
            y: 2238 / 3000,
        },
        mouthCenter: {
            x: 498 / 1000,
            y: 512 / 1000,
        },
    },
    {
        id: 'thibault-foyart',
        name: 'Thibault Foyart',
        gender: 'male',
        head: {
            original: require('../img/employees/thibault-foyart.png'),
            small: require('../img/employees/small/thibault-foyart.png'),
            medium: require('../img/employees/medium/thibault-foyart.png'),
            mask: require('../img/employees/masks/thibault-foyart.png'),
        },
        neckCenter: {
            x: 1421 / 3000,
            y: 2000 / 3000,
        },
        mouthCenter: {
            x: 490 / 1000,
            y: 496 / 1000,
        },
    },
    {
        id: 'vinicius-araujo',
        name: 'Vinicius Araujo',
        gender: 'male',
        head: {
            original: require('../img/employees/vinicius-araujo.png'),
            small: require('../img/employees/small/vinicius-araujo.png'),
            medium: require('../img/employees/medium/vinicius-araujo.png'),
            mask: require('../img/employees/masks/vinicius-araujo.png'),
        },
        neckCenter: {
            x: 1417 / 3000,
            y: 2100 / 3000,
        },
        mouthCenter: {
            x: 486 / 1000,
            y: 563 / 1000,
        },
    },
    {
        id: 'yann-kerveant',
        name: 'Yann Kerveant',
        gender: 'male',
        head: {
            original: require('../img/employees/yann-kerveant.png'),
            small: require('../img/employees/small/yann-kerveant.png'),
            medium: require('../img/employees/medium/yann-kerveant.png'),
            mask: require('../img/employees/masks/yann-kerveant.png'),
        },
        neckCenter: {
            x: 1395 / 3000,
            y: 1950 / 3000,
        },
        mouthCenter: {
            x: 475 / 1000,
            y: 518 / 1000,
            deg: 2,
        },
    },
    {
        id: 'yannick-proulx',
        name: 'Yannick Proulx',
        gender: 'male',
        head: {
            original: require('../img/employees/yannick-proulx.png'),
            small: require('../img/employees/small/yannick-proulx.png'),
            medium: require('../img/employees/medium/yannick-proulx.png'),
            mask: require('../img/employees/masks/yannick-proulx.png'),
        },
        neckCenter: {
            x: 1488 / 3000,
            y: 2179 / 3000,
        },
        mouthCenter: {
            x: 488 / 1000,
            y: 550 / 1000,
            deg: -2,
        },
    },
];
