import React from 'react';
// import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import { injectIntl } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';

import * as AppPropTypes from '../lib/PropTypes';
import MainLayout from './layouts/Main';
import HomePage from './pages/Home';
import ChoirPage from './pages/Choir';
import NotFound from './pages/NotFound';

import '../styles/main.global.scss';

const renderLayout = (PageComponent, props = {}) => (
    <MainLayout>
        <PageComponent {...props} />
    </MainLayout>
);

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    intl: AppPropTypes.intl.isRequired,
};

const defaultProps = {};

const App = ({ urlGenerator, intl }) => (
    <Switch>
        <Route
            exact
            path={urlGenerator.route('home.redirect')}
            render={() => (
                <Redirect
                    to={urlGenerator.route('home', {
                        locale: intl.locale,
                    })}
                />
            )}
        />
        <Route exact path={urlGenerator.route('home')} render={() => renderLayout(HomePage)} />
        <Route
            exact
            path={urlGenerator.route('choir')}
            render={({ match }) => renderLayout(ChoirPage, {
                choirId: match.params.choir,
                userId: window.location.hash.replace('#', '') || null,
            })
            }
        />
        <Route path="*" render={() => renderLayout(NotFound)} />
    </Switch>
);

App.propTypes = propTypes;
App.defaultProps = defaultProps;

const WithUrlGeneratorContainer = withUrlGenerator()(App);
const WithIntlContainer = injectIntl(WithUrlGeneratorContainer);
export default WithIntlContainer;
