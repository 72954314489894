import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import rootProps from './data/root';

const renderRoot = () => {
    const rootEl = document.getElementById('root');
    const root = React.createElement(Root, rootProps);
    ReactDOM.render(root, rootEl);
};

if (typeof window.Intl === 'undefined') {
    import('intl')
        .then(import('intl/locale-data/jsonp/fr.js'))
        .then(renderRoot);
} else {
    renderRoot();
}
