/* eslint-disable no-constant-condition */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import * as AppPropTypes from '../../lib/PropTypes';

const propTypes = {
    choir: AppPropTypes.choir,
    loaded: PropTypes.bool,
    started: PropTypes.bool,
    gifs: AppPropTypes.gifs,
    patterns: AppPropTypes.patterns,
};

const defaultProps = {
    choir: null,
    loaded: false,
    started: false,
    gifs: [],
    patterns: [],
};

const ChoirPreload = ({
    choir,
    loaded,
    started,
    gifs,
    patterns,
}) => (
    <Helmet>
        {choir !== null ? choir.characters.map(character => (
            <link rel="preload" href={character.head.mask} as="image" key={`preload-head-mask-${character.id}`} />
        )) : null}
        {choir !== null && loaded ? choir.characters.map(character => (
            <link rel="preload" href={character.head.small} as="image" key={`preload-head-small-${character.id}`} />
        )) : null}
        {choir !== null && loaded && started ? choir.characters.map(character => (
            <link rel="preload" href={character.head.large} as="image" key={`preload-head-large-${character.id}`} />
        )) : null}
        {false && loaded ? gifs.map(gif => (
            <link rel="preload" href={gif.path} as="image" key={`preload-gif-${gif.path}`} />
        )) : null}
        {patterns.map(pattern => (
            <link rel="preload" href={pattern.path} as="image" key={`preload-pattern-${pattern.path}`} />
        ))}
    </Helmet>
);

ChoirPreload.propTypes = propTypes;
ChoirPreload.defaultProps = defaultProps;

export default React.memo(ChoirPreload);
