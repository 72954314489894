/**
 * Constants
 */
export const SET_LYRICS = 'choir@SET_LYRICS';
export const SET_LOADED = 'choir@SET_LOADED';
export const SET_STARTED = 'choir@SET_STARTED';
export const SET_ENDED = 'choir@SET_ENDED';
export const SET_PLAYING = 'choir@SET_PLAYING';
export const SET_TALKING = 'choir@SET_TALKING';
export const SET_DURATION = 'choir@SET_DURATION';
export const SET_TIME = 'choir@SET_TIME';
export const SET_MUTED = 'choir@SET_MUTED';
export const SET_PITCH = 'choir@SET_PITCH';
export const SET_SOLO_CHARACTER = 'choir@SET_SOLO_CHARACTER';
export const SET_EFFECTS = 'choir@SET_EFFECTS';

/**
 * Actions creator
 */
export const setLyrics = payload => ({
    type: SET_LYRICS,
    payload,
});

export const setLoaded = payload => ({
    type: SET_LOADED,
    payload,
});

export const setStarted = payload => ({
    type: SET_STARTED,
    payload,
});

export const setEnded = payload => ({
    type: SET_ENDED,
    payload,
});

export const setPlaying = payload => ({
    type: SET_PLAYING,
    payload,
});

export const setTalking = payload => ({
    type: SET_TALKING,
    payload,
});

export const setDuration = payload => ({
    type: SET_DURATION,
    payload,
});

export const setTime = payload => ({
    type: SET_TIME,
    payload,
});

export const setMuted = payload => ({
    type: SET_MUTED,
    payload,
});

export const setPitch = payload => ({
    type: SET_PITCH,
    payload,
});

export const setSoloCharacter = (id, auto = false) => ({
    type: SET_SOLO_CHARACTER,
    payload: {
        id,
        auto,
    },
});

export const setEffects = payload => ({
    type: SET_EFFECTS,
    payload,
});
