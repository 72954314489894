import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/characters-select.scss';

const messages = defineMessages({
    placeholder: {
        id: 'choir_bar.characters_select_placeholder',
        defaultMessage: 'Tous les employés',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    options: AppPropTypes.options,
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    options: [],
    value: null,
    className: null,
    onChange: null,
};

const CharactersSelect = ({
    intl, options, value, className, onChange,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <select
            className={styles.select}
            value={value || ''}
            placeholder={intl.formatMessage(messages.placeholder)}
            onChange={(e) => {
                if (onChange !== null) {
                    onChange(e.target.value !== '' ? e.target.value : null);
                }
            }}
        >
            <option value="">{intl.formatMessage(messages.placeholder)}</option>
            {options.map(option => (
                <option key={`option-${option.value}`} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
        <span className={styles.arrow} />
    </div>
);

CharactersSelect.propTypes = propTypes;
CharactersSelect.defaultProps = defaultProps;

export default React.memo(injectIntl(CharactersSelect));
