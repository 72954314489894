import React from 'react';
import PropTypes from 'prop-types';
import * as AppPropTypes from '../../lib/PropTypes';

const propTypes = {
    // id: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    mask: PropTypes.string.isRequired,
    isMask: PropTypes.bool,
    mouthCenter: AppPropTypes.position,
    mouthOffset: AppPropTypes.position,
    mouthWidth: PropTypes.number,
    mouthHeight: PropTypes.number,
    className: PropTypes.string,
    mouthClassName: PropTypes.string,
    style: PropTypes.shape({}),
    mouthStyle: PropTypes.shape({}),
};

const defaultProps = {
    isMask: false,
    mouthCenter: {
        x: 0,
        y: 0,
    },
    mouthOffset: {
        x: 0,
        y: 0,
    },
    mouthWidth: 300,
    mouthHeight: 100,
    className: null,
    mouthClassName: null,
    style: null,
    mouthStyle: null,
};

const Head = ({
    image,
    mask,
    size,
    isMask,
    mouthCenter,
    mouthOffset,
    mouthWidth,
    mouthHeight,
    className,
    mouthClassName,
    style,
}) => (
    <div>
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 300 300"
            xmlSpace="preserve"
            className={className}
            style={style}
        >
            <image
                xlinkHref={image}
                x="0"
                y="0"
                width="300"
                height="300"
                opacity={!isMask ? 1 : 0}
            />
            <image
                className="mask"
                xlinkHref={mask}
                x="0"
                y="0"
                width="300"
                height="300"
                opacity={isMask ? 1 : 0}
            />
        </svg>
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={`${mouthWidth}px`}
            height={`${mouthHeight}px`}
            viewBox="0 0 300 100"
            xmlSpace="preserve"
            className={mouthClassName}
            style={{
                left: mouthOffset.x,
                top: mouthOffset.y,
                width: mouthWidth,
                height: mouthHeight,
                // enableBackground: 'new 0 0 300 100',
                transform: `translate(${Math.floor(mouthCenter.x * size)
                    - mouthWidth / 2}px, ${Math.floor(mouthCenter.y * size)
                    - mouthHeight / 2}px) rotate(${mouthCenter.deg
                    || 0}deg) scale(${mouthCenter.scale || 1})`,
            }}
        >
            <path d="M150,0C67.2,0,0,9,0,20c0,44.2,67.2,80,150,80s150-35.8,150-80C300,9,232.8,0,150,0z" />
        </svg>
    </div>
);

Head.propTypes = propTypes;
Head.defaultProps = defaultProps;

export default Head;
