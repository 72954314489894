import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Logo = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="79.45px"
        height="45px"
        viewBox="0 0 79.45 45"
        className={className}
        xmlSpace="preserve"
    >
        <g>
            <g>
                <path
                    fill={color}
                    d="M37.04,3.01C37.86,1.21,39.18,0,40.95,0s1.62,1.05,1.73,1.8s0.49,3.61,0.49,3.61c0.08,0.75,0.08,1.35-1.01,1.43c-1.09,0.08-4.89,0.08-5.49,0c-0.6-0.08-0.94-0.83-0.64-1.47S36.44,4.32,37.04,3.01z"
                />
                <path
                    fill={color}
                    d="M27,2.52c0-0.79,0-1.58,1.13-1.58c1.13,0,5.9,0,6.54,0c0.64,0,1.58,0.23,1.09,1.2s-1.35,3.31-1.84,3.87S33.05,7.03,31.1,7.1c-1.95,0.08-2.93,0-3.31,0C27.42,7.1,27,6.84,27,6.01S27,2.52,27,2.52z"
                />
                <path
                    fill={color}
                    d="M25.57,10.82c0-1.01,0.38-2.18,2.52-2.26c2.14-0.08,19.28-0.83,21.76-0.75c2.48,0.08,4.02,1.01,4.02,2.03s-2.97,0.86-4.85,0.98c-1.88,0.11-17.37,1.59-21.57,1.84C26.21,12.74,25.57,12.74,25.57,10.82z"
                />
                <path
                    fill={color}
                    d="M44.59,2.82c-0.49-1.09-0.9-1.92,0.34-1.88c1.24,0.04,5.86,0,6.43,0c0.56,0,1.2,0.34,1.24,1.2s-0.15,3.65-0.19,3.91s0.11,0.83-0.79,0.85c-0.9,0.02-4.1,0.02-4.44,0s-1.17-0.47-1.69-1.67S44.59,2.82,44.59,2.82z"
                />
                <path
                    fill={color}
                    d="M32.79,15.03c0.08-0.86,0.94-1.58,3.12-1.77c2.18-0.19,7.07-0.68,8.04-0.75c0.98-0.08,1.77-0.04,1.84,1.01s0.19,2.26-0.68,2.44c-0.86,0.19-10.3,1.54-11.09,1.65s-1.47-0.15-1.43-0.75C32.64,16.27,32.72,15.9,32.79,15.03z"
                />
                <path
                    fill={color}
                    d="M27,15.94c-0.03-0.75-0.26-1.95,0.98-2.07c1.24-0.11,2.26-0.19,2.63-0.23c0.38-0.04,1,0.04,0.84,1.15c-0.16,1.11-1.44,10.66-1.51,11.03c-0.08,0.38-0.3,0.75-1.05,0.75c-0.34,0-1.01,0-1.35,0s-0.55-0.23-0.55-0.83C26.98,25.15,27.02,16.53,27,15.94z"
                />
                <path
                    fill={color}
                    d="M47.3,14.09c-0.1-0.48-0.19-1.23,0.75-1.41c0.94-0.17,3.16-0.47,3.57-0.55c0.41-0.08,0.98-0.15,0.98,0.71s-0.16,12.93-0.16,13.16s-0.11,0.6-1.2,0.56c-1.09-0.04-1.62,0-1.77-0.34C49.33,25.9,47.56,15.37,47.3,14.09z"
                />
                <path
                    fill={color}
                    d="M32.08,20.63c0.15-0.82,0.08-1.54,1.17-1.73c1.09-0.19,2.37-0.3,2.67-0.34s0.86,0.11,1.01,1.54c0.15,1.43,0.38,6.36,0.34,6.69s-0.22,0.75-1.37,0.75s-4.38,0.15-4.76-0.49C30.76,26.42,31.7,22.63,32.08,20.63z"
                />
                <path
                    fill={color}
                    d="M37.87,19.06c-0.08-0.45-0.04-0.79,0.79-0.94c0.83-0.15,1.95-0.23,2.18-0.26s0.71-0.19,0.68,0.98c-0.04,1.17-0.15,6.43-0.19,6.8c-0.04,0.38-0.11,0.9-0.75,0.94c-0.64,0.04-1.62,0.08-1.88,0c-0.26-0.08-0.45-0.3-0.49-1.01C38.17,24.84,37.87,19.06,37.87,19.06z"
                />
                <path
                    fill={color}
                    d="M42.26,18.83c-0.04-0.49-0.08-1,0.68-1.18c0.75-0.18,1.88-0.33,2.29-0.36s0.86,0.14,1.09,1.27c0.23,1.13,1.88,8.12,1.88,8.54c0,0.41,0.49,1.09-0.75,1.2c-1.24,0.11-3.01,0.19-3.61,0c-0.6-0.19-0.79-0.19-0.94-1.73C42.75,25.03,42.26,18.83,42.26,18.83z"
                />
            </g>
            <g>
                <path
                    fill={color}
                    d="M10.11,42.16H4.54l-0.99,2.5H0l5.33-12.13h3.98l5.33,12.13h-3.55L10.11,42.16z M7.32,35.15l-1.9,4.81h3.81L7.32,35.15z"
                />
                <path
                    fill={color}
                    d="M15.9,32.53h7.56c4.16,0,5.96,2.41,5.96,6.06c0,3.65-1.87,6.06-5.67,6.06H15.9V32.53z M19.2,42.35h3.67c2.17,0,3.16-1.31,3.16-3.87c0-2.24-0.95-3.64-3.47-3.64H19.2V42.35z"
                />
                <path
                    fill={color}
                    d="M29.52,32.53h3.62l3.52,9.24l3.52-9.24h3.62l-5.39,12.13h-3.5L29.52,32.53z"
                />
                <path fill={color} d="M44.92,32.53h3.3v12.13h-3.3V32.53z" />
                <path
                    fill={color}
                    d="M59.07,36.05c-0.2-1.58-1.72-1.75-3.04-1.75c-1.36,0-2.24,0.61-2.24,1.43c0,0.65,0.54,1,1.39,1.14l4.13,0.66c2.21,0.36,3.89,1.19,3.89,3.4c0,2.26-1.53,4.08-6.3,4.08c-2.96,0-6.8-0.37-6.83-4.16h3.7c0.03,1.66,1.7,1.95,3.13,1.95c1.55,0,2.6-0.46,2.6-1.51c0-0.93-0.75-1.19-2.06-1.39l-3.01-0.46c-2.11-0.32-4.14-0.95-4.14-3.43c0-2.7,2.21-3.81,6.01-3.81c2.5,0,6.2,0.42,6.42,3.86H59.07z"
                />
                <path
                    fill={color}
                    d="M71.91,32.19c6.52,0,7.54,3.87,7.54,6.4c0,2.53-1.02,6.4-7.54,6.4c-6.52,0-7.54-3.87-7.54-6.4C64.37,36.06,65.39,32.19,71.91,32.19z M71.91,42.69c2.99,0,4.04-2.21,4.04-4.09s-1.05-4.09-4.04-4.09c-2.99,0-4.04,2.21-4.04,4.09S68.92,42.69,71.91,42.69z"
                />
            </g>
        </g>
    </svg>
);

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
