import React from 'react';
import PropTypes from 'prop-types';

import * as AppPropTypes from '../../lib/PropTypes';
import ImagePattern from './ImagePattern';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    originalWidth: PropTypes.number,
    originalHeight: PropTypes.number,
    id: PropTypes.string,
    playing: PropTypes.bool,
    animating: PropTypes.bool,
    bodyColor: PropTypes.string,
    legsColor: PropTypes.string,
    skinColor: PropTypes.string,
    gif: AppPropTypes.gif,
    gifRatio: PropTypes.number,
    pattern: AppPropTypes.pattern,
    isMask: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    width: null,
    height: null,
    originalWidth: 235.1,
    originalHeight: 718.58,
    id: null,
    playing: false,
    animating: false,
    bodyColor: '#006600',
    legsColor: '#000',
    skinColor: '#FEC3A1',
    gif: null,
    gifRatio: 0.25,
    pattern: null,
    isMask: false,
    className: null,
};

const Male1 = ({
    width,
    height,
    originalWidth,
    originalHeight,
    id,
    playing,
    animating,
    bodyColor,
    legsColor,
    skinColor,
    gif,
    gifRatio,
    pattern,
    isMask,
    className,
}) => {
    const hasPattern = pattern !== null && !isMask;
    const hasGif = gif !== null && !isMask;
    const midWidth = Math.floor(originalWidth / 2);
    const midHeight = Math.floor(originalHeight / 2);

    let bodyFill = isMask ? '#000' : bodyColor;
    if (hasGif) {
        bodyFill = `url(#gif-${id})`;
    } else if (hasPattern) {
        bodyFill = `url(#pattern-${id})`;
    }
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={`${
                width === null && height !== null
                    ? height * (originalWidth / originalHeight)
                    : width || originalWidth
            }px`}
            height={`${
                height === null && width !== null
                    ? width / (originalWidth / originalHeight)
                    : height || originalHeight
            }px`}
            viewBox="0 0 235.1 718.58"
            xmlSpace="preserve"
            className={className}
        >
            <defs>
                {pattern !== null ? <ImagePattern id={`pattern-${id}`} {...pattern} /> : null}
                {hasGif ? <ImagePattern id={`gif-${id}`} {...gif} scale={gifRatio} /> : null}
            </defs>
            <path
                fill={!isMask ? legsColor : '#000'}
                d="M34.94,709.86c0,0-4.21,5.22,2.34,5.62s38.85,1.6,49.62-2.81c0,0-0.47-3.61,11.7-5.22c12.17-1.6,14.98,1.2,12.64-8.02c0,0,2.44-6.02,0.75-12.84c-1.69-6.82-4.03-10.43-4.03-10.43l-0.47-4.01c0,0,1.76,4.01,5.09-3.21c3.33-7.22,4.74-10.83,4.74-10.83s-6.09-6.42-14.98-6.82c0,0,2.81-5.62-2.81-11.63c0,0-2.34-34.9-4.21-48.14c-1.87-13.24-1.4-24.47-4.21-34.9c-2.81-10.43-0.47-22.07-6.55-27.28c0,0,4.68-7.62,7.49-31.69c2.81-24.07,7.96-39.32,7.96-47.34c0-8.02-0.47-14.44-0.47-14.44s4.21-21.66,3.28-27.68c0,0,3.74,6.02,3.74,12.44s-0.94,45.74,10.77,55.36c0,0-0.47,16.85-3.28,20.06c-2.81,3.21-4.68,10.03,0,16.85c4.68,6.82,12.17,12.84,12.17,12.84s-0.47,15.25,0,21.26s1.4,38.51,3.28,44.93c1.87,6.42,2.81,11.23,2.81,11.23s-0.94,27.68,0,32.5c0,0-4.68,6.42-4.21,12.44c0.47,6.02,1.87,9.63,1.87,9.63l4.68,1.6c0,0-1.4,18.45-2.81,20.06c-1.4,1.6-7.02,11.63-7.96,18.05c-0.94,6.42-6.55,16.16,2.34,18.51s17.79,3.15,29.49,2.35c11.7-0.8,18.72-3.61,19.19-10.03c0.47-6.42,3.28-16.45,3.74-27.28c0.47-10.83,0-23.67,0-23.67s1.4,0.8,3.74-7.62c2.34-8.43,0-13.24,0-13.24s3.02-10.43,1.04-30.09c-1.98-19.66-5.25-43.33-4.32-52.56c0.94-9.23,2.34-28.48-0.94-34.1s-3.28-8.02-3.28-8.02s6.09-5.62,1.87-28.08c-4.21-22.47-1.4-38.92,0.94-49.35c2.34-10.43,6.7-32.1,6.39-46.14c-0.31-14.04,0.34-45.74,0.02-57.37s-4.54-20.06-5-24.07c-0.47-4.01-3.28-5.22-3.28-5.22H33.6c0,0-7.56,18.45-8.97,22.47c-1.4,4.01-6.55,74.62-5.15,96.29c1.4,21.66,4.68,70.21,6.55,79.04c1.87,8.83,13.11,97.09,16.38,110.73c3.28,13.64,14.98,38.51,16.38,41.72c0,0-4.21,1.2-3.28,6.42c0.94,5.22,3.28,10.03,3.28,10.03s-3.74,1.2-3.74,5.62c0,4.41,1.4,3.61-2.34,9.23c-3.74,5.62-8.43,10.03-10.77,10.83C39.62,694.61,30.25,697.42,34.94,709.86z"
            />
            <g>
                <path
                    fill={!isMask ? skinColor : '#000'}
                    d="M22.11,368.69c0,0,2.72,5.98,5.98,6.79c3.26,0.82,3.53-2.72,0.27-7.34l-2.72-2.45c0,0,1.09-4.08-2.45-5.43c0,0,2.17-4.08,1.36-6.52c-0.82-2.45,2.72-3.53,4.08-10.05c0.71-3.41,1.79-7.12,2.23-11.36c-0.78-7.34-7.16-7.05-11.74-8.2c-4.28-1.07-11.05,4.05-12.68,5.35c-0.97,5.45-2.39,11.99-3.89,13.94c-2.72,3.53-3.26,7.88-1.63,14.13c1.63,6.25,0.17,14.67,2.94,17.12c2.77,2.45,8.2,10.05,11.19,9.24c0,0,1.9,4.62,6.79,3.26c0,0,5.71,6.21,7.61,0.25c0,0,4.08-0.38,0-5.88C25.37,376.02,21.02,371.4,22.11,368.69z"
                />
                <path
                    fill={!isMask ? skinColor : '#000'}
                    d="M178.46,320.03c-8.56-2.88-16.71-9.75-16.71-9.75l-2.45,4.48c0,0-0.23,0.06-0.65,0.18c-1.93,3.18-3.75,5.82-4.78,6.48c-2.99,1.9-5.43,5.43-5.71,10.6c-0.27,5.16-3.26,12.23-3.8,16.3s0.82,4.08-1.63,6.79c-2.45,2.72,0.27,4.35,4.62,2.17c4.35-2.17,7.88-5.16,9.24-10.32c0,0-3.26,12.23-5.71,16.84c-2.45,4.62-5.71,10.32-3.26,12.5s6.25-0.27,9.51-5.16c3.26-4.89,6.79-7.06,7.61-11.68c0.82-4.62,4.35-10.87,4.35-10.87s0.82,3.26,0,6.25c-0.82,2.99-5.16,12.23-6.79,14.13c-1.63,1.9-6.79,6.52-5.16,10.6c1.63,4.08,5.43,1.09,7.61-2.17c0,0-0.27,7.34,6.79,0.82c7.06-6.52,10.05-10.32,10.6-11.14c0.54-0.82,3.53-0.54,6.52-2.72c2.99-2.17,4.08-3.8,4.89-8.69c0.82-4.89,4.08-14.67,4.08-19.83c0-2.38,0.58-6.25,1.22-9.95C195.4,324.98,185.44,322.38,178.46,320.03z"
                />
                <path
                    fill={bodyFill}
                    d="M234.97,181.5c-0.82-12.23,3.26-96.99-12.63-124.7c0,0-2.85-21.19-23.64-32.6c0,0-11-9.37-21.19-12.23c0,0-3.26-3.26-6.11-4.48s-6.93-4.48-6.93-4.48c-33.82-8.56-54.2,3.26-63.98,9.37c-4.48,2.45-8.15,8.97-9.37,11.82s-8.15,6.93-11.82,8.97c-3.67,2.04-29.34,15.08-33.82,21.6S32.84,85.32,32.84,95.11c0,0-6.11,38.71-6.11,57.87s0,43.2,0,43.2s-7.74,44.83-8.56,52.16c-0.82,7.34-4.48,15.08,0,22.41s-1.22,12.63-4.08,15.49c-2.85,2.85-6.11,9.37-6.11,11.82s2.85,10.6,2.85,10.6s-4.89,3.26-4.89,8.15s0,12.98,0,12.98s8.15-6.87,13.04-5.64s11.82,0.82,11.82,9.78l2.04-21.6c2.45,2.85,28.93,16.3,39.94,17.12c11,0.82,26.9,0.41,44.01-3.67c17.12-4.08,42.38-11,42.38-11l2.45-4.48c0,0,8.15,6.87,16.71,9.75c8.56,2.88,21.6,6.14,21.6,6.14l4.48-12.63c0,0,5.3-3.67,6.93-10.6c1.63-6.93,7.34-24.45,11.82-33.82c4.48-9.37,7.74-24.45,8.56-35.45C232.52,222.66,235.79,193.73,234.97,181.5z"
                />
                {playing && animating ? (
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from={`0 ${midWidth} ${midHeight}`}
                        to={`2 ${midWidth} ${midHeight}`}
                        dur="0.66s"
                        repeatCount="indefinite"
                    />
                ) : null}
            </g>
        </svg>
    );
};

Male1.propTypes = propTypes;
Male1.defaultProps = defaultProps;

export default React.memo(Male1);
