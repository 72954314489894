import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as AppPropTypes from '../../lib/PropTypes';
import * as bodies from '../bodies';
import Head from '../bodies/Head';

import styles from '../../styles/partials/character.scss';

const propTypes = {
    playing: PropTypes.bool,
    talking: PropTypes.bool,
    animating: PropTypes.bool,
    id: PropTypes.string.isRequired,
    head: AppPropTypes.characterHead.isRequired,
    gender: AppPropTypes.gender, // eslint-disable-line react/no-unused-prop-types
    maxHeight: PropTypes.number,
    headPercent: PropTypes.number,
    bodyPercent: PropTypes.number,
    neckCenter: AppPropTypes.position,
    mouthCenter: AppPropTypes.position,
    bodyIndex: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    bodyColor: PropTypes.string,
    bodyGifs: AppPropTypes.gifs, // eslint-disable-line react/no-unused-prop-types
    bodyPattern: AppPropTypes.pattern,
    skinColor: PropTypes.string,
    isSolo: PropTypes.bool,
    isLarge: PropTypes.bool,
    isMask: PropTypes.bool,
    className: PropTypes.string,
    debug: PropTypes.bool,
};

const defaultProps = {
    playing: false,
    talking: false,
    animating: false,
    gender: 'female',
    maxHeight: null,
    headPercent: 0.3,
    bodyPercent: 0.8,
    neckCenter: {
        x: 0.5,
        y: 0.5,
    },
    mouthCenter: {
        x: 0.5,
        y: 0.5,
    },
    bodyIndex: null,
    bodyColor: undefined,
    bodyGifs: [],
    bodyPattern: null,
    skinColor: undefined,
    isSolo: false,
    isLarge: false,
    isMask: false,
    className: null,
    debug: false,
};

// eslint-disable-next-line
class Character extends PureComponent {
    static getDerivedStateFromProps(
        {
            gender, bodyIndex, isSolo, bodyGifs,
        },
        { gender: prevGender, isSolo: prevIsSolo },
    ) {
        const genderChanged = gender !== prevGender;
        if (genderChanged) {
            const genderBodies = bodies[gender];
            const bodiesCount = genderBodies.length;
            const finalBodyIndex = bodyIndex !== null
                ? bodyIndex % bodiesCount
                : Math.floor(Math.random() * bodiesCount);
            return {
                gender,
                body: genderBodies[finalBodyIndex],
            };
        }

        const isSoloChanged = isSolo !== prevIsSolo;
        if (isSoloChanged) {
            const bodyGifsCount = bodyGifs.length;
            return {
                isSolo,
                bodyGif:
                    isSolo && bodyGifsCount > 0
                        ? bodyGifs[Math.floor(Math.random() * bodyGifsCount)]
                        : null,
            };
        }

        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            isSolo: false, // eslint-disable-line react/no-unused-state
            gender: null, // eslint-disable-line react/no-unused-state
            body: null,
            bodyGif: null,
        };
    }

    /* eslint-disable no-unused-vars */
    render() {
        const {
            maxHeight,
            playing,
            talking,
            animating,
            id,
            head,
            headPercent,
            bodyPercent,
            neckCenter: headNeckCenter,
            mouthCenter,
            bodyColor,
            bodyPattern,
            skinColor,
            isSolo,
            isLarge,
            isMask,
            className,
            debug,
        } = this.props;
        const { body, bodyGif } = this.state;
        const { neckCenter: bodyNeckCenter = { x: 0.5, y: 0 }, component: BodyComponent } = body;
        const headSize = maxHeight !== null ? maxHeight * headPercent : 0;
        const mouthWidth = headSize / 6;
        const mouthHeight = mouthWidth * 0.33;
        const offsetX = -(headNeckCenter.x * headSize);
        const offsetY = -(headNeckCenter.y * headSize);
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.debug]: debug,
                        [className]: className !== null,
                    },
                ])}
                style={{
                    height: maxHeight,
                }}
            >
                {debug ? <div className={styles.id}>{id}</div> : null}
                <div
                    className={styles.bodyContainer}
                    style={{
                        height: maxHeight !== null ? maxHeight * bodyPercent : 0,
                    }}
                >
                    <div
                        className={styles.headContainer}
                        style={{
                            width: headSize,
                            height: headSize,
                            top: `${bodyNeckCenter.y * 100}%`,
                            left: `${bodyNeckCenter.x * 100}%`,
                        }}
                    >
                        <Head
                            id={id}
                            mask={head.mask}
                            image={isLarge ? head.large : head.small}
                            size={headSize}
                            isMask={isMask}
                            className={styles.head}
                            style={{
                                transform: `translate(${offsetX}px, ${offsetY}px)`,
                            }}
                            mouthClassName={classNames({
                                [styles.mouth]: true,
                                [styles.animating]: talking,
                            })}
                            mouthCenter={mouthCenter}
                            mouthWidth={mouthWidth}
                            mouthHeight={mouthHeight}
                            mouthOffset={{
                                x: offsetX,
                                y: offsetY,
                            }}
                        />
                    </div>
                    <BodyComponent
                        id={id}
                        className={styles.body}
                        playing={playing}
                        talking={talking}
                        animating={animating}
                        height={maxHeight * bodyPercent}
                        bodyColor={bodyColor}
                        skinColor={skinColor}
                        isMask={isMask}
                        gif={bodyGif}
                        pattern={bodyPattern}
                    />
                </div>
            </div>
        );
    }
}

Character.propTypes = propTypes;
Character.defaultProps = defaultProps;

export default Character;
